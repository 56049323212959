import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, getDocs, query, where, orderBy, doc, updateDoc, deleteDoc, Timestamp, onSnapshot, writeBatch, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import Sidebar from '../../components/Sidebar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/SharedStyles.css';
import './ADStyles.css';
import WalkieModel3D from '../../components/WalkieModel3D';

const WalkieManagement = () => {
  const { user } = useAuth();
  const { hasAccess, productionAccess, departmentAccess } = useAccessControl();
  const barcodeInputRef = useRef(null);
  
  // State for walkies and crew members
  const [walkies, setWalkies] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // State for walkie form
  const [showWalkieForm, setShowWalkieForm] = useState(false);
  const [currentWalkie, setCurrentWalkie] = useState({
    walkieNumber: '',
    barcode: '',
    channel: '1',
    status: 'Available',
    assignedTo: '',
    department: '',
    notes: '',
    unit: 'Main Unit',
    isReturnRequired: false,
    returnDate: null,
    isDayPlayer: false,
    lastCheckedOut: null,
    lastCheckedIn: null
  });
  
  // Additional state for filtering and viewing
  const [activeTab, setActiveTab] = useState('all');
  const [filterStatus, setFilterStatus] = useState('All');
  const [filterDepartment, setFilterDepartment] = useState('All');
  const [filterUnit, setFilterUnit] = useState('All');
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [selectedWalkieId, setSelectedWalkieId] = useState(null);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const [barcodeInput, setBarcodeInput] = useState('');
  const [batchAssignmentMode, setBatchAssignmentMode] = useState(false);
  const [selectedWalkies, setSelectedWalkies] = useState([]);
  const [walkieHistory, setWalkieHistory] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const [chargers, setChargers] = useState([]);
  
  // State for batch operations
  const [showBatchForm, setShowBatchForm] = useState(false);
  const [batchDepartment, setBatchDepartment] = useState('');
  const [batchUnit, setBatchUnit] = useState('Main Unit');
  const [batchCount, setBatchCount] = useState(1);
  const [batchWalkies, setBatchWalkies] = useState([]);
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
  const [batchScanMode, setBatchScanMode] = useState(false);
  const [batchWalkieNumber, setBatchWalkieNumber] = useState('');
  
  // State for channel assignments
  const [channels, setChannels] = useState([
    { channel: '1', assignedTo: 'Production' },
    { channel: '2', assignedTo: 'Camera' },
    { channel: '3', assignedTo: 'Grip' },
    { channel: '4', assignedTo: 'Electric' },
    { channel: '5', assignedTo: 'Art' },
    { channel: '6', assignedTo: 'Sound' },
    { channel: '7', assignedTo: 'AD' },
    { channel: '8', assignedTo: 'Transportation' },
    { channel: '9', assignedTo: 'Locations' },
    { channel: '10', assignedTo: 'Costumes' },
    { channel: '11', assignedTo: 'Makeup/Hair' },
    { channel: '12', assignedTo: 'Catering' },
    { channel: '13', assignedTo: 'VFX' },
    { channel: '14', assignedTo: 'Stunts' },
    { channel: '15', assignedTo: 'Special Effects' },
    { channel: '16', assignedTo: 'Script' }
  ]);
  
  // State for department list and units
  const [departments, setDepartments] = useState([
    'Production', 
    'Camera', 
    'Grip', 
    'Electric', 
    'Art', 
    'Sound', 
    'AD', 
    'Transportation', 
    'Locations', 
    'Costumes', 
    'Makeup/Hair', 
    'Catering', 
    'VFX', 
    'Stunts', 
    'Special Effects', 
    'Script'
  ]);
  const [units, setUnits] = useState(['Main Unit', 'Second Unit', 'Splinter Unit', 'VFX Unit']);
  
  // State for 3D model
  const [selectedModelWalkie, setSelectedModelWalkie] = useState(null);
  
  // State for barcode registration
  const [showBarcodeRegister, setShowBarcodeRegister] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState('');
  const [walkieNumber, setWalkieNumber] = useState('');
  const [batteryNumber, setBatteryNumber] = useState('');
  const [chargerNumber, setChargerNumber] = useState('');
  const [scanningForRegistration, setScanningForRegistration] = useState(false);
  const [newWalkieSeries, setNewWalkieSeries] = useState('');
  const [registeredWalkies, setRegisteredWalkies] = useState([]);
  const [registeredBatteries, setRegisteredBatteries] = useState([]);
  const [registeredChargers, setRegisteredChargers] = useState([]);
  const [registrationType, setRegistrationType] = useState('walkie'); // walkie, battery, charger
  
  // Fetch walkies and crew members on component mount
  useEffect(() => {
    if (hasAccess && (departmentAccess === 'Assistant Directors' || departmentAccess === 'all')) {
      fetchWalkies();
      fetchCrewMembers();
      fetchBatteries();
      fetchChargers();
      
      // Add event listener for barcode scanner
      window.addEventListener('keydown', handleBarcodeKeyDown);
      
      return () => {
        window.removeEventListener('keydown', handleBarcodeKeyDown);
      };
    }
  }, [hasAccess, departmentAccess, productionAccess]);
  
  // Set departments from channels on mount or when channels change
  useEffect(() => {
    // This effect is now only for updating channels from Firestore, if needed
    // Departments are hardcoded to match the channel assignments
  }, []);
  
  // Fetch walkies from Firebase
  const fetchWalkies = async () => {
    try {
      setLoading(true);
      
      const q = query(
        collection(db, 'walkies'),
        where('production', '==', productionAccess),
        orderBy('walkieNumber', 'asc')
      );
      
      const snapshot = await getDocs(q);
      const walkiesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        lastCheckedOut: doc.data().lastCheckedOut?.toDate(),
        lastCheckedIn: doc.data().lastCheckedIn?.toDate()
      }));
      
      setWalkies(walkiesData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching walkies:', error);
      setError('Failed to load walkies. Please try again.');
      setLoading(false);
    }
  };
  
  // Fetch crew members from Firebase
  const fetchCrewMembers = async () => {
    try {
      const q = query(
        collection(db, 'crewMembers'),
        where('production', '==', productionAccess)
      );
      
      const snapshot = await getDocs(q);
      const crewData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        name: `${doc.data().firstName} ${doc.data().lastName}`
      }));
      
      setCrewMembers(crewData);
    } catch (error) {
      console.error('Error fetching crew members:', error);
    }
  };
  
  // Handle walkie form input changes
  const handleWalkieInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentWalkie({ ...currentWalkie, [name]: value });
  };
  
  // Save a new walkie
  const handleSaveWalkie = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      
      const walkieData = {
        ...currentWalkie,
        production: productionAccess,
        createdBy: user.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        // Convert dates if they exist
        lastCheckedOut: currentWalkie.lastCheckedOut ? Timestamp.fromDate(currentWalkie.lastCheckedOut) : null,
        lastCheckedIn: currentWalkie.lastCheckedIn ? Timestamp.fromDate(currentWalkie.lastCheckedIn) : null
      };
      
      if (currentWalkie.id) {
        // Update existing walkie
        await updateDoc(doc(db, 'walkies', currentWalkie.id), walkieData);
        setSuccess('Walkie updated successfully');
      } else {
        // Create new walkie
        await addDoc(collection(db, 'walkies'), walkieData);
        setSuccess('Walkie added successfully');
      }
      
      // Reset form and fetch updated walkies
      resetWalkieForm();
      fetchWalkies();
      
    } catch (error) {
      console.error('Error saving walkie:', error);
      setError('Failed to save walkie. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Reset walkie form
  const resetWalkieForm = () => {
    setCurrentWalkie({
      walkieNumber: '',
      barcode: '',
      channel: '1',
      status: 'Available',
      assignedTo: '',
      department: '',
      notes: '',
      unit: 'Main Unit',
      isReturnRequired: false,
      returnDate: null,
      isDayPlayer: false,
      lastCheckedOut: null,
      lastCheckedIn: null
    });
    setShowWalkieForm(false);
    
    // Clear success message after 3 seconds
    setTimeout(() => {
      setSuccess(null);
    }, 3000);
  };
  
  // Edit an existing walkie
  const handleEditWalkie = (walkie) => {
    setCurrentWalkie({
      ...walkie,
      // Convert Firestore timestamp to Date if they exist
      lastCheckedOut: walkie.lastCheckedOut || null,
      lastCheckedIn: walkie.lastCheckedIn || null
    });
    setShowWalkieForm(true);
  };
  
  // Delete a walkie
  const handleDeleteWalkie = async (id) => {
    if (window.confirm('Are you sure you want to delete this walkie?')) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, 'walkies', id));
        setSuccess('Walkie deleted successfully');
        fetchWalkies();
      } catch (error) {
        console.error('Error deleting walkie:', error);
        setError('Failed to delete walkie. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Prepare for check-out
  const handlePrepareCheckout = (walkieId) => {
    setSelectedWalkieId(walkieId);
    setShowCheckoutForm(true);
    
    // Reset department in current walkie to empty
    setCurrentWalkie(prev => ({
      ...prev,
      department: '',
    }));
  };
  
  // Check out walkie to crew member
  const handleCheckout = async (e) => {
    e.preventDefault();
    
    if (!selectedWalkieId || !currentWalkie.assignedTo) {
      setError('Please select a crew member to check out the walkie to.');
      return;
    }
    
    if (!currentWalkie.department) {
      setError('Please select a department for this walkie.');
      return;
    }
    
    try {
      setLoading(true);
      
      // Find the crew member for department info
      const crewMember = crewMembers.find(cm => cm.id === currentWalkie.assignedTo);
      
      // Update walkie status
      await updateDoc(doc(db, 'walkies', selectedWalkieId), {
        status: 'Checked Out',
        assignedTo: currentWalkie.assignedTo,
        department: currentWalkie.department,
        lastCheckedOut: Timestamp.now(),
        lastCheckedIn: null,
        updatedAt: Timestamp.now()
      });
      
      setSuccess('Walkie checked out successfully');
      setShowCheckoutForm(false);
      setSelectedWalkieId(null);
      resetWalkieForm();
      fetchWalkies();
      
    } catch (error) {
      console.error('Error checking out walkie:', error);
      setError('Failed to check out walkie. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Check in walkie
  const handleCheckIn = async (walkieId) => {
    try {
      setLoading(true);
      
      // Update walkie status
      await updateDoc(doc(db, 'walkies', walkieId), {
        status: 'Available',
        assignedTo: '',
        department: '',
        lastCheckedIn: Timestamp.now(),
        updatedAt: Timestamp.now()
      });
      
      setSuccess('Walkie checked in successfully');
      fetchWalkies();
      
    } catch (error) {
      console.error('Error checking in walkie:', error);
      setError('Failed to check in walkie. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filter walkies based on status and department
  const filteredWalkies = walkies.filter(walkie => {
    const statusMatch = filterStatus === 'All' || walkie.status === filterStatus;
    const departmentMatch = filterDepartment === 'All' || walkie.department === filterDepartment;
    const unitMatch = filterUnit === 'All' || walkie.unit === filterUnit;
    return statusMatch && departmentMatch && unitMatch;
  });
  
  // Save channel assignments
  const handleSaveChannels = async () => {
    try {
      setLoading(true);
      
      // Save to 'walkieChannels' collection
      const channelRef = doc(db, 'productions', productionAccess);
      await updateDoc(channelRef, {
        walkieChannels: channels
      });
      
      setSuccess('Channel assignments saved successfully');
      
    } catch (error) {
      console.error('Error saving channel assignments:', error);
      setError('Failed to save channel assignments. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle channel assignment changes
  const handleChannelChange = (index, value) => {
    const updatedChannels = [...channels];
    updatedChannels[index].assignedTo = value;
    setChannels(updatedChannels);
  };
  
  // Get walkie status color class
  const getStatusClass = (status) => {
    switch (status) {
      case 'Available':
        return 'prep-row';
      case 'Checked Out':
        return 'shoot-row';
      case 'Maintenance':
        return 'wrap-row';
      default:
        return '';
    }
  };
  
  // Process barcode scan
  const processBarcodeInput = (barcode) => {
    console.log('Barcode scanned:', barcode);
    
    // If we're in batch form and scanning, add to batch
    if (showBatchForm && batchScanMode) {
      addWalkieToBatch(barcode);
      return;
    }
    
    // If we're in barcode registration mode, store the barcode value
    if (showBarcodeRegister && scanningForRegistration) {
      setBarcodeValue(barcode);
      setScanningForRegistration(false);
      return;
    }
    
    // Otherwise, treat as walkie number scan for check-in/out
    const walkie = walkies.find(w => w.walkieNumber === barcode);
    
    if (!walkie) {
      setError(`Walkie #${barcode} not found. You may need to register this walkie first.`);
      return;
    }
    
    if (walkie.status === 'Available') {
      handlePrepareCheckout(walkie.id);
    } else if (walkie.status === 'Checked Out') {
      handleCheckIn(walkie.id);
    }
  };
  
  // Handle barcode keypress for scanning
  const handleBarcodeKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    
    // Get the barcode value from the hidden input
    const barcodeInput = document.getElementById('barcode-input');
    if (barcodeInput && barcodeInput.value) {
      const barcode = barcodeInput.value.trim();
      processBarcodeInput(barcode);
      barcodeInput.value = '';
    }
  };
  
  // Register a new item with barcode
  const registerItem = async (e) => {
    if (e) e.preventDefault();
    
    try {
      setLoading(true);
      
      if (registrationType === 'walkie') {
        if (!walkieNumber || !barcodeValue) {
          setError('Please enter both walkie number and scan a barcode');
          setLoading(false);
          return;
        }
        
        // Check if walkie number already exists
        const q = query(
          collection(db, 'walkies'),
          where('production', '==', productionAccess),
          where('walkieNumber', '==', walkieNumber)
        );
        
        const snapshot = await getDocs(q);
        
        if (!snapshot.empty) {
          setError(`Walkie #${walkieNumber} already exists in the system`);
          setLoading(false);
          return;
        }
        
        // Add the new walkie
        const newWalkie = {
          walkieNumber: walkieNumber,
          barcode: barcodeValue,
          status: 'Available',
          series: newWalkieSeries,
          department: '',
          unit: 'Main Unit',
          production: productionAccess,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          assignedTo: null,
          lastCheckedIn: null,
          lastCheckedOut: null,
        };
        
        const docRef = await addDoc(collection(db, 'walkies'), newWalkie);
        
        // Add to registered walkies list
        setRegisteredWalkies([...registeredWalkies, {
          id: docRef.id,
          ...newWalkie
        }]);
        
        // Clear form fields for next walkie
        setBarcodeValue('');
        setWalkieNumber('');
        
        setSuccess(`Walkie #${walkieNumber} registered successfully with barcode ${barcodeValue}`);
      } 
      else if (registrationType === 'battery') {
        if (!batteryNumber || !barcodeValue) {
          setError('Please enter both battery number and scan a barcode');
          setLoading(false);
          return;
        }
        
        // Check if battery number already exists
        const q = query(
          collection(db, 'batteries'),
          where('production', '==', productionAccess),
          where('batteryNumber', '==', batteryNumber)
        );
        
        const snapshot = await getDocs(q);
        
        if (!snapshot.empty) {
          setError(`Battery #${batteryNumber} already exists in the system`);
          setLoading(false);
          return;
        }
        
        // Add the new battery
        const newBattery = {
          batteryNumber: batteryNumber,
          barcode: barcodeValue,
          status: 'Available',
          department: '',
          production: productionAccess,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          assignedTo: null
        };
        
        const docRef = await addDoc(collection(db, 'batteries'), newBattery);
        
        // Add to registered batteries list
        setRegisteredBatteries([...registeredBatteries, {
          id: docRef.id,
          ...newBattery
        }]);
        
        // Clear form fields for next battery
        setBarcodeValue('');
        setBatteryNumber('');
        
        setSuccess(`Battery #${batteryNumber} registered successfully with barcode ${barcodeValue}`);
      }
      else if (registrationType === 'charger') {
        if (!chargerNumber || !barcodeValue) {
          setError('Please enter both charger number and scan a barcode');
          setLoading(false);
          return;
        }
        
        // Check if charger number already exists
        const q = query(
          collection(db, 'chargers'),
          where('production', '==', productionAccess),
          where('chargerNumber', '==', chargerNumber)
        );
        
        const snapshot = await getDocs(q);
        
        if (!snapshot.empty) {
          setError(`Charger #${chargerNumber} already exists in the system`);
          setLoading(false);
          return;
        }
        
        // Add the new charger
        const newCharger = {
          chargerNumber: chargerNumber,
          barcode: barcodeValue,
          status: 'Available',
          department: '',
          production: productionAccess,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          assignedTo: null
        };
        
        const docRef = await addDoc(collection(db, 'chargers'), newCharger);
        
        // Add to registered chargers list
        setRegisteredChargers([...registeredChargers, {
          id: docRef.id,
          ...newCharger
        }]);
        
        // Clear form fields for next charger
        setBarcodeValue('');
        setChargerNumber('');
        
        setSuccess(`Charger #${chargerNumber} registered successfully with barcode ${barcodeValue}`);
      }
      
    } catch (error) {
      console.error('Error registering item:', error);
      setError('Failed to register item. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Start scanning for barcode registration
  const startBarcodeRegistrationScan = () => {
    setScanningForRegistration(true);
    setBarcodeValue('');
    
    // Focus the hidden input for barcode scanning
    setTimeout(() => {
      const barcodeInput = document.getElementById('barcode-input');
      if (barcodeInput) {
        barcodeInput.focus();
      }
    }, 100);
  };
  
  // Fetch batteries
  const fetchBatteries = async () => {
    try {
      const q = query(
        collection(db, 'batteries'),
        where('production', '==', productionAccess)
      );
      
      const snapshot = await getDocs(q);
      const batteriesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setBatteries(batteriesData);
    } catch (error) {
      console.error('Error fetching batteries:', error);
    }
  };
  
  // Fetch chargers
  const fetchChargers = async () => {
    try {
      const q = query(
        collection(db, 'chargers'),
        where('production', '==', productionAccess)
      );
      
      const snapshot = await getDocs(q);
      const chargersData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setChargers(chargersData);
    } catch (error) {
      console.error('Error fetching chargers:', error);
    }
  };
  
  // Calculate walkies due today
  const getTodayDueWalkies = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return walkies.filter(walkie => {
      if (walkie.isReturnRequired && walkie.returnDate) {
        const returnDate = new Date(walkie.returnDate);
        returnDate.setHours(0, 0, 0, 0);
        return returnDate.getTime() === today.getTime();
      }
      return false;
    });
  };
  
  // Calculate overdue walkies
  const getOverdueWalkies = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return walkies.filter(walkie => {
      if (walkie.isReturnRequired && walkie.returnDate) {
        const returnDate = new Date(walkie.returnDate);
        returnDate.setHours(0, 0, 0, 0);
        return returnDate.getTime() < today.getTime() && walkie.status === 'Checked Out';
      }
      return false;
    });
  };
  
  // Handle batch assignment of walkies to a department
  const handleBatchAssign = async (e) => {
    e.preventDefault();
    
    if (batchWalkies.length === 0) {
      setError('No walkies have been added to the batch.');
      return;
    }
    
    if (!batchDepartment) {
      setError('Please select a department for the batch.');
      return;
    }
    
    try {
      setLoading(true);
      
      // Update each walkie in the batch
      const batch = writeBatch(db);
      
      batchWalkies.forEach(walkieId => {
        const walkieRef = doc(db, 'walkies', walkieId);
        batch.update(walkieRef, {
          department: batchDepartment,
          unit: batchUnit,
          updatedAt: Timestamp.now()
        });
      });
      
      await batch.commit();
      
      setSuccess(`Successfully assigned ${batchWalkies.length} walkies to ${batchDepartment}`);
      setBatchDepartment('');
      setBatchUnit('Main Unit');
      setBatchWalkies([]);
      setCurrentBatchIndex(0);
      setBatchCount(1);
      setBatchScanMode(false);
      setBatchWalkieNumber('');
      setShowBatchForm(false);
      fetchWalkies();
      
    } catch (error) {
      console.error('Error in batch assignment:', error);
      setError('Failed to assign batch. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Add walkie to batch by scanning or manual input
  const addWalkieToBatch = (walkieNumber) => {
    // Find the walkie by number
    const walkie = walkies.find(w => w.walkieNumber === walkieNumber);
    
    if (!walkie) {
      setError(`Walkie #${walkieNumber} not found.`);
      setBatchWalkieNumber('');
      return;
    }
    
    // Check if walkie is already in batch
    if (batchWalkies.includes(walkie.id)) {
      setError(`Walkie #${walkieNumber} is already in the batch.`);
      setBatchWalkieNumber('');
      return;
    }
    
    // Add walkie to batch
    setBatchWalkies([...batchWalkies, walkie.id]);
    setBatchWalkieNumber('');
    setCurrentBatchIndex(currentBatchIndex + 1);
  };
  
  // Handle manual walkie number entry
  const handleWalkieNumberAdd = (e) => {
    e.preventDefault();
    if (batchWalkieNumber) {
      addWalkieToBatch(batchWalkieNumber);
    }
  };
  
  // Handle barcode scanner input for batch
  const handleBatchBarcodeScan = (barcode) => {
    if (showBatchForm && batchScanMode) {
      addWalkieToBatch(barcode);
    }
  };
  
  // Get walkies for active tab
  const getActiveTabWalkies = () => {
    switch (activeTab) {
      case 'due-today':
        return getTodayDueWalkies();
      case 'overdue':
        return getOverdueWalkies();
      case 'history':
        return walkieHistory;
      case 'batteries':
        return batteries;
      case 'all':
      default:
        return filteredWalkies;
    }
  };
  
  // Get tab class
  const getTabClass = (tabName) => {
    return `tab-item ${activeTab === tabName ? 'active-tab' : ''}`;
  };
  
  // Handle selecting walkie for 3D model
  const handleSelectWalkieForModel = (walkie) => {
    setSelectedModelWalkie(walkie);
  };

  return (
    <div className="page-container">
      <Sidebar />
      <div className="content">
        <h1 className="page-title">Walkie Management</h1>
        
        {/* Error and success messages */}
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        
        {/* Barcode scanner interface */}
        <div className="barcode-scanner-container">
          <button
            className="btn-primary barcode-btn"
            onClick={() => {
              setShowBarcodeScanner(true);
              setBarcodeInput('');
              // Focus on input when available
              setTimeout(() => {
                if (barcodeInputRef.current) {
                  barcodeInputRef.current.focus();
                }
              }, 0);
            }}
          >
            <i className="fas fa-barcode"></i> Scan Barcode
          </button>
          
          {showBarcodeScanner && (
            <div className="barcode-modal">
              <div className="barcode-modal-content">
                <h3>Scan Walkie Barcode</h3>
                <p>Use a barcode scanner or type the barcode manually:</p>
                <input
                  ref={barcodeInputRef}
                  type="text"
                  value={barcodeInput}
                  onChange={e => setBarcodeInput(e.target.value)}
                  className="form-control"
                  placeholder="Barcode value..."
                  autoFocus
                />
                <div className="form-buttons">
                  <button
                    className="btn-primary"
                    onClick={processBarcodeInput}
                    disabled={!barcodeInput || loading}
                  >
                    Process Barcode
                  </button>
                  <button
                    className="btn-secondary"
                    onClick={() => {
                      setShowBarcodeScanner(false);
                      setBarcodeInput('');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {/* Main content based on view state */}
        {!showWalkieForm && !showCheckoutForm ? (
          <div className="walkie-management-container">
            {/* Filters and actions */}
            <div className="header-actions">
              <div className="filters">
                <div className="filter-group">
                  <label>Status:</label>
                  <select
                    value={filterStatus}
                    onChange={e => setFilterStatus(e.target.value)}
                    className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  >
                    <option value="All">All Statuses</option>
                    <option value="Available">Available</option>
                    <option value="Checked Out">Checked Out</option>
                    <option value="Maintenance">Maintenance</option>
                  </select>
                </div>
                <div className="filter-group">
                  <label>Department:</label>
                  <select
                    value={filterDepartment}
                    onChange={e => setFilterDepartment(e.target.value)}
                    className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  >
                    <option value="All">All Departments</option>
                    <option value="Production">Production</option>
                    <option value="Camera">Camera</option>
                    <option value="Grip">Grip</option>
                    <option value="Electric">Electric</option>
                    <option value="Art">Art</option>
                    <option value="Sound">Sound</option>
                    <option value="AD">AD</option>
                    <option value="Transportation">Transportation</option>
                    <option value="Locations">Locations</option>
                    <option value="Costumes">Costumes</option>
                    <option value="Makeup/Hair">Makeup/Hair</option>
                    <option value="Catering">Catering</option>
                    <option value="VFX">VFX</option>
                    <option value="Stunts">Stunts</option>
                    <option value="Special Effects">Special Effects</option>
                    <option value="Script">Script</option>
                  </select>
                </div>
                <div className="filter-group">
                  <label>Unit:</label>
                  <select
                    value={filterUnit}
                    onChange={e => setFilterUnit(e.target.value)}
                    className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  >
                    <option value="All">All Units</option>
                    {units.map(unit => (
                      <option key={unit} value={unit}>{unit}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => setShowWalkieForm(true)}
                  className="btn-primary"
                >
                  Add New Walkie
                </button>
                <button 
                  className="btn-primary"
                  onClick={() => {
                    setShowBatchForm(true);
                    setBatchDepartment('');
                    setBatchUnit('Main Unit');
                    setBatchWalkies([]);
                    setCurrentBatchIndex(0);
                    setBatchCount(1);
                  }}
                >
                  Batch Assign Walkies
                </button>
                <button 
                  className="btn-primary"
                  onClick={() => {
                    setShowBarcodeRegister(true);
                    setBarcodeValue('');
                    setWalkieNumber('');
                    setBatteryNumber('');
                    setChargerNumber('');
                    setScanningForRegistration(false);
                    setRegisteredWalkies([]);
                    setRegisteredBatteries([]);
                    setRegisteredChargers([]);
                    setNewWalkieSeries('');
                    setRegistrationType('walkie');
                  }}
                >
                  Register Items
                </button>
              </div>
            </div>
            
            {/* Walkies list */}
            <div className="walkies-list">
              <h2 className="section-title">Walkies</h2>
              
              {loading && walkies.length === 0 ? (
                <p>Loading walkies...</p>
              ) : getActiveTabWalkies().length === 0 ? (
                <p>No walkies found. Add your first one!</p>
              ) : (
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Walkie #</th>
                        <th>Barcode</th>
                        <th>Channel</th>
                        <th>Status</th>
                        <th>Assigned To</th>
                        <th>Department</th>
                        <th>Unit</th>
                        <th>Last Action</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getActiveTabWalkies().map(walkie => (
                        <tr key={walkie.id} className={getStatusClass(walkie.status)}>
                          <td>{walkie.walkieNumber}</td>
                          <td>{walkie.barcode}</td>
                          <td>{walkie.channel}</td>
                          <td>{walkie.status}</td>
                          <td>
                            {walkie.assignedTo 
                              ? crewMembers.find(cm => cm.id === walkie.assignedTo)?.name || 'Unknown'
                              : '-'}
                          </td>
                          <td>{walkie.department || '-'}</td>
                          <td>{walkie.unit}</td>
                          <td>
                            {walkie.status === 'Checked Out' && walkie.lastCheckedOut 
                              ? `Checked out on ${walkie.lastCheckedOut.toLocaleDateString()}`
                              : walkie.lastCheckedIn
                                ? `Checked in on ${walkie.lastCheckedIn.toLocaleDateString()}`
                                : '-'}
                          </td>
                          <td className="actions">
                            <button 
                              className="btn-small"
                              onClick={() => handleEditWalkie(walkie)}
                            >
                              Edit
                            </button>
                            
                            {walkie.status === 'Available' ? (
                              <button 
                                className="btn-small"
                                onClick={() => handlePrepareCheckout(walkie.id)}
                              >
                                Check Out
                              </button>
                            ) : walkie.status === 'Checked Out' ? (
                              <button 
                                className="btn-small"
                                onClick={() => handleCheckIn(walkie.id)}
                              >
                                Check In
                              </button>
                            ) : null}
                            
                            <button 
                              className="btn-small delete"
                              onClick={() => handleDeleteWalkie(walkie.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            
            {/* 3D Walkie Model Display Section */}
            <div className="walkie-3d-model-section">
              <h2 className="section-title">Walkie 3D Model</h2>
              <div className="walkie-3d-container">
                <WalkieModel3D 
                  walkies={walkies}
                  onSelectWalkie={handleSelectWalkieForModel}
                />
                <div className="walkie-3d-info">
                  <p>Interact with the 3D model:</p>
                  <ul>
                    <li>Click and drag to rotate the model</li>
                    <li>Scroll to zoom in/out</li>
                  </ul>
                  {selectedModelWalkie && (
                    <div className="selected-walkie-details">
                      <h3>Selected Walkie #{selectedModelWalkie.walkieNumber}</h3>
                      <p><strong>Status:</strong> {selectedModelWalkie.status}</p>
                      <p><strong>Channel:</strong> {selectedModelWalkie.channel}</p>
                      {selectedModelWalkie.assignedTo && (
                        <p><strong>Assigned To:</strong> {
                          crewMembers.find(c => c.id === selectedModelWalkie.assignedTo)?.name || 'Unknown'
                        }</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            {/* Channel assignments */}
            <div className="channel-assignments">
              <h2 className="section-title">Channel Assignments</h2>
              
              <div className="channels-grid">
                {channels.map((channel, index) => (
                  <div key={index} className="channel-item">
                    <div className="channel-number">{channel.channel}</div>
                    <input
                      type="text"
                      value={channel.assignedTo}
                      onChange={e => handleChannelChange(index, e.target.value)}
                      className="form-control"
                    />
                  </div>
                ))}
              </div>
              
              <button 
                className="btn-primary"
                onClick={handleSaveChannels}
                disabled={loading}
              >
                Save Channel Assignments
              </button>
            </div>
            
            {/* Batch assignment */}
            {showBatchForm && (
              <div className="overlay">
                <div className="batch-form">
                  <div className="header-actions">
                    <h2>Batch Assign Walkies</h2>
                    <button
                      className="btn-secondary"
                      onClick={() => {
                        setShowBatchForm(false);
                        setBatchDepartment('');
                        setBatchUnit('Main Unit');
                        setBatchWalkies([]);
                        setCurrentBatchIndex(0);
                        setBatchCount(1);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  
                  <form onSubmit={handleBatchAssign}>
                    <div className="form-section">
                      <h3>1. Select Assignment Details</h3>
                      <div className="form-row">
                        <div className="form-group">
                          <label>Department: <span className="required">*</span></label>
                          <select
                            value={batchDepartment}
                            onChange={e => setBatchDepartment(e.target.value)}
                            className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                            required
                          >
                            <option value="">-- Select Department --</option>
                            <option value="Production">Production</option>
                            <option value="Camera">Camera</option>
                            <option value="Grip">Grip</option>
                            <option value="Electric">Electric</option>
                            <option value="Art">Art</option>
                            <option value="Sound">Sound</option>
                            <option value="AD">AD</option>
                            <option value="Transportation">Transportation</option>
                            <option value="Locations">Locations</option>
                            <option value="Costumes">Costumes</option>
                            <option value="Makeup/Hair">Makeup/Hair</option>
                            <option value="Catering">Catering</option>
                            <option value="VFX">VFX</option>
                            <option value="Stunts">Stunts</option>
                            <option value="Special Effects">Special Effects</option>
                            <option value="Script">Script</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Unit:</label>
                          <select
                            value={batchUnit}
                            onChange={e => setBatchUnit(e.target.value)}
                            className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                          >
                            {units.map(unit => (
                              <option key={unit} value={unit}>{unit}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    
                    <div className="form-section">
                      <h3>2. Add Walkies ({batchWalkies.length} added)</h3>
                      <div className="form-row">
                        <div className="form-group">
                          <div className="scan-toggle">
                            <button
                              type="button"
                              onClick={() => setBatchScanMode(!batchScanMode)}
                              className={batchScanMode ? "btn-primary" : "btn-secondary"}
                            >
                              {batchScanMode ? "Scanning Mode: ON" : "Enable Scan Mode"}
                            </button>
                            {batchScanMode && (
                              <p className="scan-instructions">
                                Scan walkie barcodes. Walkies will be added automatically.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      
                      <div className="form-row">
                        <div className="form-group flex-grow">
                          <label>Enter Walkie Number Manually:</label>
                          <div className="input-with-button">
                            <input
                              type="text"
                              value={batchWalkieNumber}
                              onChange={e => setBatchWalkieNumber(e.target.value)}
                              className="form-control"
                              placeholder="Enter walkie number..."
                            />
                            <button
                              type="button"
                              onClick={handleWalkieNumberAdd}
                              className="btn-secondary"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      {batchWalkies.length > 0 && (
                        <div className="batch-list">
                          <h4>Walkies to be assigned:</h4>
                          <div className="walkie-chips">
                            {batchWalkies.map(walkieId => {
                              const walkie = walkies.find(w => w.id === walkieId);
                              return (
                                <div key={walkieId} className="walkie-chip">
                                  #{walkie?.walkieNumber || 'Unknown'}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setBatchWalkies(batchWalkies.filter(id => id !== walkieId));
                                    }}
                                    className="remove-button"
                                  >
                                    ×
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    
                    <div className="form-buttons">
                      <button
                        type="submit"
                        className="btn-primary"
                        disabled={batchWalkies.length === 0 || !batchDepartment || loading}
                      >
                        {loading ? 'Assigning...' : `Assign ${batchWalkies.length} Walkies to ${batchDepartment || 'Department'}`}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            
            {/* Barcode Registration Form */}
            {showBarcodeRegister && (
              <div className="overlay">
                <div className="batch-form">
                  <div className="header-actions">
                    <h2>Register Items with Barcodes</h2>
                    <button
                      className="btn-secondary"
                      onClick={() => {
                        setShowBarcodeRegister(false);
                        setBarcodeValue('');
                        setWalkieNumber('');
                        setBatteryNumber('');
                        setChargerNumber('');
                        setScanningForRegistration(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                  
                  <div className="registration-tabs">
                    <button 
                      className={`tab-button ${registrationType === 'walkie' ? 'active' : ''}`}
                      onClick={() => setRegistrationType('walkie')}
                    >
                      Register Walkies
                    </button>
                    <button 
                      className={`tab-button ${registrationType === 'battery' ? 'active' : ''}`}
                      onClick={() => setRegistrationType('battery')}
                    >
                      Register Batteries
                    </button>
                    <button 
                      className={`tab-button ${registrationType === 'charger' ? 'active' : ''}`}
                      onClick={() => setRegistrationType('charger')}
                    >
                      Register Chargers
                    </button>
                  </div>
                  
                  <form onSubmit={registerItem}>
                    {registrationType === 'walkie' && (
                      <>
                        <div className="form-section">
                          <h3>Walkie Default Settings</h3>
                          <div className="form-row">
                            <div className="form-group">
                              <label>Series/Model:</label>
                              <input
                                type="text"
                                value={newWalkieSeries}
                                onChange={e => setNewWalkieSeries(e.target.value)}
                                className="form-control"
                                placeholder="e.g. Motorola XPR 7550"
                              />
                            </div>
                          </div>
                        </div>
                        
                        <div className="form-section">
                          <h3>Walkie Information</h3>
                          <div className="form-row">
                            <div className="form-group">
                              <label>Walkie Number: <span className="required">*</span></label>
                              <input
                                type="text"
                                value={walkieNumber}
                                onChange={e => setWalkieNumber(e.target.value)}
                                className="form-control"
                                placeholder="Enter walkie number"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Barcode Value: <span className="required">*</span></label>
                              <div className="input-with-button">
                                <input
                                  type="text"
                                  value={barcodeValue}
                                  onChange={e => setBarcodeValue(e.target.value)}
                                  className="form-control"
                                  placeholder="Scan or enter barcode"
                                  required
                                  readOnly={scanningForRegistration}
                                />
                                <button
                                  type="button"
                                  onClick={startBarcodeRegistrationScan}
                                  className={scanningForRegistration ? "btn-primary" : "btn-secondary"}
                                  disabled={loading}
                                >
                                  {scanningForRegistration ? "Scanning..." : "Scan Barcode"}
                                </button>
                              </div>
                              {scanningForRegistration && (
                                <div className="scan-instructions">
                                  Ready to scan. Point scanner at barcode...
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        
                        {registeredWalkies.length > 0 && (
                          <div className="registered-items">
                            <h3>Recently Registered Walkies</h3>
                            <div className="walkie-chips">
                              {registeredWalkies.map(walkie => (
                                <div key={walkie.id} className="walkie-chip">
                                  #{walkie.walkieNumber}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    
                    {registrationType === 'battery' && (
                      <>
                        <div className="form-section">
                          <h3>Battery Information</h3>
                          <div className="form-row">
                            <div className="form-group">
                              <label>Battery Number: <span className="required">*</span></label>
                              <input
                                type="text"
                                value={batteryNumber}
                                onChange={e => setBatteryNumber(e.target.value)}
                                className="form-control"
                                placeholder="Enter battery number"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Barcode Value: <span className="required">*</span></label>
                              <div className="input-with-button">
                                <input
                                  type="text"
                                  value={barcodeValue}
                                  onChange={e => setBarcodeValue(e.target.value)}
                                  className="form-control"
                                  placeholder="Scan or enter barcode"
                                  required
                                  readOnly={scanningForRegistration}
                                />
                                <button
                                  type="button"
                                  onClick={startBarcodeRegistrationScan}
                                  className={scanningForRegistration ? "btn-primary" : "btn-secondary"}
                                  disabled={loading}
                                >
                                  {scanningForRegistration ? "Scanning..." : "Scan Barcode"}
                                </button>
                              </div>
                              {scanningForRegistration && (
                                <div className="scan-instructions">
                                  Ready to scan. Point scanner at barcode...
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        
                        {registeredBatteries.length > 0 && (
                          <div className="registered-items">
                            <h3>Recently Registered Batteries</h3>
                            <div className="walkie-chips">
                              {registeredBatteries.map(battery => (
                                <div key={battery.id} className="walkie-chip">
                                  #{battery.batteryNumber}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    
                    {registrationType === 'charger' && (
                      <>
                        <div className="form-section">
                          <h3>Charger Information</h3>
                          <div className="form-row">
                            <div className="form-group">
                              <label>Charger Number: <span className="required">*</span></label>
                              <input
                                type="text"
                                value={chargerNumber}
                                onChange={e => setChargerNumber(e.target.value)}
                                className="form-control"
                                placeholder="Enter charger number"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Barcode Value: <span className="required">*</span></label>
                              <div className="input-with-button">
                                <input
                                  type="text"
                                  value={barcodeValue}
                                  onChange={e => setBarcodeValue(e.target.value)}
                                  className="form-control"
                                  placeholder="Scan or enter barcode"
                                  required
                                  readOnly={scanningForRegistration}
                                />
                                <button
                                  type="button"
                                  onClick={startBarcodeRegistrationScan}
                                  className={scanningForRegistration ? "btn-primary" : "btn-secondary"}
                                  disabled={loading}
                                >
                                  {scanningForRegistration ? "Scanning..." : "Scan Barcode"}
                                </button>
                              </div>
                              {scanningForRegistration && (
                                <div className="scan-instructions">
                                  Ready to scan. Point scanner at barcode...
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        
                        {registeredChargers.length > 0 && (
                          <div className="registered-items">
                            <h3>Recently Registered Chargers</h3>
                            <div className="walkie-chips">
                              {registeredChargers.map(charger => (
                                <div key={charger.id} className="walkie-chip">
                                  #{charger.chargerNumber}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    
                    <div className="form-buttons">
                      <button
                        type="submit"
                        className="btn-primary"
                        disabled={
                          (registrationType === 'walkie' && (!walkieNumber || !barcodeValue)) ||
                          (registrationType === 'battery' && (!batteryNumber || !barcodeValue)) ||
                          (registrationType === 'charger' && (!chargerNumber || !barcodeValue)) ||
                          loading
                        }
                      >
                        {loading ? "Registering..." : `Register ${registrationType === 'walkie' ? 'Walkie' : registrationType === 'battery' ? 'Battery' : 'Charger'}`}
                      </button>
                      <button
                        type="button"
                        className="btn-secondary"
                        onClick={() => {
                          setBarcodeValue('');
                          setWalkieNumber('');
                          setBatteryNumber('');
                          setChargerNumber('');
                          setScanningForRegistration(false);
                        }}
                      >
                        Clear Form
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            
            {/* Tab navigation */}
            <div className="tab-navigation">
              <div className="tabs">
                <button 
                  className={getTabClass('all')}
                  onClick={() => setActiveTab('all')}
                >
                  All Walkies
                </button>
                <button 
                  className={getTabClass('due-today')}
                  onClick={() => setActiveTab('due-today')}
                >
                  Due Today
                </button>
                <button 
                  className={getTabClass('overdue')}
                  onClick={() => setActiveTab('overdue')}
                >
                  Overdue
                </button>
                <button 
                  className={getTabClass('history')}
                  onClick={() => setActiveTab('history')}
                >
                  History
                </button>
                <button 
                  className={getTabClass('batteries')}
                  onClick={() => setActiveTab('batteries')}
                >
                  Batteries
                </button>
              </div>
            </div>
          </div>
        ) : showWalkieForm ? (
          /* Walkie Form */
          <div className="walkie-form">
            <div className="header-actions">
              <h2>{currentWalkie.id ? 'Edit Walkie' : 'Add New Walkie'}</h2>
              <button 
                className="btn-secondary"
                onClick={resetWalkieForm}
              >
                Cancel
              </button>
            </div>
            
            <form onSubmit={handleSaveWalkie}>
              <div className="form-row">
                <div className="form-group">
                  <label>Walkie Number:</label>
                  <input
                    type="text"
                    name="walkieNumber"
                    value={currentWalkie.walkieNumber}
                    onChange={handleWalkieInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Barcode:</label>
                  <input
                    type="text"
                    name="barcode"
                    value={currentWalkie.barcode}
                    onChange={handleWalkieInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Channel:</label>
                  <select
                    name="channel"
                    value={currentWalkie.channel}
                    onChange={handleWalkieInputChange}
                    className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  >
                    {channels.map((ch, index) => (
                      <option key={index} value={ch.channel}>
                        {ch.channel} - {ch.assignedTo}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Status:</label>
                  <select
                    name="status"
                    value={currentWalkie.status}
                    onChange={handleWalkieInputChange}
                    className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  >
                    <option value="Available">Available</option>
                    <option value="Checked Out">Checked Out</option>
                    <option value="Maintenance">Maintenance</option>
                  </select>
                </div>
              </div>
              
              {/* Day player tracking */}
              <div className="form-row">
                <div className="form-group checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      name="isDayPlayer"
                      checked={currentWalkie.isDayPlayer}
                      onChange={e => setCurrentWalkie({
                        ...currentWalkie,
                        isDayPlayer: e.target.checked
                      })}
                    />
                    Day Player
                  </label>
                </div>
                
                <div className="form-group checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      name="isReturnRequired"
                      checked={currentWalkie.isReturnRequired}
                      onChange={e => setCurrentWalkie({
                        ...currentWalkie,
                        isReturnRequired: e.target.checked
                      })}
                    />
                    Return Required
                  </label>
                </div>
                
                {currentWalkie.isReturnRequired && (
                  <div className="form-group">
                    <label>Return Date:</label>
                    <DatePicker
                      selected={currentWalkie.returnDate}
                      onChange={date => setCurrentWalkie({
                        ...currentWalkie,
                        returnDate: date
                      })}
                      className="form-control"
                      dateFormat="MM/dd/yyyy"
                      minDate={new Date()}
                    />
                  </div>
                )}
              </div>
              
              {/* Only show assignment fields if status is Checked Out */}
              {currentWalkie.status === 'Checked Out' && (
                <div className="form-row">
                  <div className="form-group">
                    <label>Assigned To:</label>
                    <select
                      name="assignedTo"
                      value={currentWalkie.assignedTo}
                      onChange={handleWalkieInputChange}
                      className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                    >
                      <option value="">-- Select Crew Member --</option>
                      {crewMembers.map(crew => (
                        <option key={crew.id} value={crew.id}>
                          {crew.name} ({crew.department})
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Department:</label>
                    <select
                      name="department"
                      value={currentWalkie.department}
                      onChange={handleWalkieInputChange}
                      className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                    >
                      <option value="">-- Select Department --</option>
                      <option value="Production">Production</option>
                      <option value="Camera">Camera</option>
                      <option value="Grip">Grip</option>
                      <option value="Electric">Electric</option>
                      <option value="Art">Art</option>
                      <option value="Sound">Sound</option>
                      <option value="AD">AD</option>
                      <option value="Transportation">Transportation</option>
                      <option value="Locations">Locations</option>
                      <option value="Costumes">Costumes</option>
                      <option value="Makeup/Hair">Makeup/Hair</option>
                      <option value="Catering">Catering</option>
                      <option value="VFX">VFX</option>
                      <option value="Stunts">Stunts</option>
                      <option value="Special Effects">Special Effects</option>
                      <option value="Script">Script</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Unit:</label>
                    <select
                      name="unit"
                      value={currentWalkie.unit}
                      onChange={handleWalkieInputChange}
                      className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                    >
                      {units.map(unit => (
                        <option key={unit} value={unit}>{unit}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              
              <div className="form-group">
                <label>Notes:</label>
                <textarea
                  name="notes"
                  value={currentWalkie.notes}
                  onChange={handleWalkieInputChange}
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Saving...' : currentWalkie.id ? 'Update Walkie' : 'Create Walkie'}
                </button>
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={resetWalkieForm}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        ) : (
          /* Checkout Form */
          <div className="checkout-form">
            <div className="header-actions">
              <h2>Check Out Walkie</h2>
              <button 
                className="btn-secondary"
                onClick={() => {
                  setShowCheckoutForm(false);
                  setSelectedWalkieId(null);
                  resetWalkieForm();
                }}
              >
                Cancel
              </button>
            </div>
            
            <form onSubmit={handleCheckout}>
              <div className="form-group">
                <label>Assign To:</label>
                <select
                  name="assignedTo"
                  value={currentWalkie.assignedTo}
                  onChange={handleWalkieInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                >
                  <option value="">-- Select Crew Member --</option>
                  {crewMembers.map(crew => (
                    <option key={crew.id} value={crew.id}>
                      {crew.name} ({crew.department})
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label>Department:</label>
                <select
                  name="department"
                  value={currentWalkie.department}
                  onChange={handleWalkieInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                >
                  <option value="">-- Select Department --</option>
                  <option value="Production">Production</option>
                  <option value="Camera">Camera</option>
                  <option value="Grip">Grip</option>
                  <option value="Electric">Electric</option>
                  <option value="Art">Art</option>
                  <option value="Sound">Sound</option>
                  <option value="AD">AD</option>
                  <option value="Transportation">Transportation</option>
                  <option value="Locations">Locations</option>
                  <option value="Costumes">Costumes</option>
                  <option value="Makeup/Hair">Makeup/Hair</option>
                  <option value="Catering">Catering</option>
                  <option value="VFX">VFX</option>
                  <option value="Stunts">Stunts</option>
                  <option value="Special Effects">Special Effects</option>
                  <option value="Script">Script</option>
                </select>
              </div>
              
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Check Out Walkie'}
                </button>
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={() => {
                    setShowCheckoutForm(false);
                    setSelectedWalkieId(null);
                    resetWalkieForm();
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalkieManagement;
