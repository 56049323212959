import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs, query, where, orderBy, Timestamp, doc, getDoc, updateDoc, getFirestore, serverTimestamp, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import { AuthContext } from '../contexts/AuthContext';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './Reports.css';
import '../styles/SharedStyles.css';
import Toast from '../components/Toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useAccessControl } from '../hooks/useAccessControl';
import { format } from 'date-fns'; // Add format import

// Add these helper functions at the top
const calculateTotalHours = (entry) => {
  if (!entry.call || !entry.wrap) return 0;

  // Convert time strings (HH:MM) to decimal hours
  const getDecimalHours = (timeStr) => {
    if (!timeStr || typeof timeStr !== 'string') return 0;
    const [hours, minutes] = timeStr.split(':');
    return Number(hours) + (Number(minutes) / 60);
  };

  const callHours = getDecimalHours(entry.call);
  const wrapHours = getDecimalHours(entry.wrap);

  // Calculate the difference
  let totalHours = wrapHours - callHours;

  // Handle wrap time going into next day
  if (totalHours < 0) {
    totalHours += 24;
  }

  // Return rounded to 2 decimal places
  return Math.round(totalHours * 100) / 100;
};

function Reports() {
  const { hasAccess, isLoading: accessLoading, error: accessError, productionAccess, departmentAccess } = useAccessControl();
  const [timeEntries, setTimeEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const [departmentMap, setDepartmentMap] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hotSheetDate, setHotSheetDate] = useState(new Date());
  const [vehicleEntries, setVehicleEntries] = useState([]);
  const [toast, setToast] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState('');
  const [currentProduction, setCurrentProduction] = useState('');
  const [activeTab, setActiveTab] = useState('timeReports');
  const [selectedColumns, setSelectedColumns] = useState({
    name: true,
    union: true,
    call: true,
    wrap: true,
    totalHours: true,
    rate: true,
    vendor: true,
    towTag: true,
    trailerTag: true
  });

  const [reports, setReports] = useState([]); // Add missing state
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [vehicleReportDate, setVehicleReportDate] = useState(new Date());
  const [hotSheetData, setHotSheetData] = useState([]);
  const [hotSheetEntries, setHotSheetEntries] = useState([]);

  console.log('Reports page mounted with:', {
    user,
    hasAccess,
    productionAccess,
    departmentAccess,
    selectedDate: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : null
  });

  useEffect(() => {
    console.log('Reports page mounted with:', {
      user,
      hasAccess,
      productionAccess,
      departmentAccess,
      selectedDate: selectedDate ? format(selectedDate, 'yyyy-MM-dd HH:mm:ss') : null
    });

    // Only proceed if user is logged in and access control values are available
    if (hasAccess && user && productionAccess && (departmentAccess || departmentAccess === 'all')) {
      // Set current department and production from access control
      setCurrentDepartment(departmentAccess);
      setCurrentProduction(productionAccess);

      const initializeData = async () => {
        try {
          await Promise.all([
            fetchTimeEntries(selectedDate),
            fetchVehicleEntries(),
            fetchDepartments(),
            fetchHotSheetData()
          ]);
        } catch (error) {
          console.error('Error initializing data:', error);
          setError('Failed to initialize data');
        }
      };

      initializeData();
    }
  }, [hasAccess, user, productionAccess, departmentAccess]);

  // Update current department and production when access changes
  useEffect(() => {
    if (hasAccess && user && productionAccess && (departmentAccess || departmentAccess === 'all')) {
      setCurrentDepartment(departmentAccess);
      setCurrentProduction(productionAccess);
    }
  }, [hasAccess, user, productionAccess, departmentAccess]);

  useEffect(() => {
    if (activeTab === 'vehicleReports' && hasAccess && user && productionAccess && (departmentAccess || departmentAccess === 'all')) {
      console.log('Vehicle tab activated, fetching entries...');
      fetchVehicleEntries();
    }
  }, [activeTab, hasAccess, user, productionAccess, departmentAccess]);

  useEffect(() => {
    if (activeTab === 'hotSheet' && hasAccess && user && productionAccess && (departmentAccess || departmentAccess === 'all')) {
      console.log('Fetching hot sheet data for date:', selectedDate);
      fetchHotSheetData(selectedDate);
    }
  }, [activeTab, selectedDate, hasAccess, user, productionAccess, departmentAccess]);

  const fetchTimeEntries = async (date) => {
    console.log('Fetching time entries for date:', date);

    if (!hasAccess || !user || !date || !productionAccess || (!departmentAccess && departmentAccess !== 'all')) {
      console.log('Early return from fetchTimeEntries: missing required data');
      setTimeEntries([]);
      return;
    }

    try {
      setLoading(true);

      // Update current department and production
      setCurrentDepartment(departmentAccess);
      setCurrentProduction(productionAccess);

      // Set up the date range for the query
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999);

      console.log('Querying time entries between:', {
        start: startOfDay.toISOString(),
        end: endOfDay.toISOString(),
        production: productionAccess,
        department: departmentAccess
      });

      // Query time entries
      let timeEntriesQuery = departmentAccess !== 'all'
        ? query(
            collection(db, 'timeEntries'),
            where('production', '==', productionAccess),
            where('department', '==', departmentAccess),
            where('date', '>=', Timestamp.fromDate(startOfDay)),
            where('date', '<=', Timestamp.fromDate(endOfDay))
          )
        : query(
            collection(db, 'timeEntries'),
            where('production', '==', productionAccess),
            where('date', '>=', Timestamp.fromDate(startOfDay)),
            where('date', '<=', Timestamp.fromDate(endOfDay))
          );

      const querySnapshot = await getDocs(timeEntriesQuery);
      console.log('Found time entries:', querySnapshot.docs.length);

      // Get unique user IDs from time entries
      const userIds = new Set(querySnapshot.docs.map(doc => doc.data().userId));
      
      // Fetch user data for all users
      const userDocs = await Promise.all(
        Array.from(userIds).map(async userId => {
          const userDoc = await getDoc(doc(db, 'users', userId));
          return { id: userId, ...userDoc.data() };
        })
      );

      // Create a map of user data for easy lookup
      const userDataMap = Object.fromEntries(
        userDocs.map(userData => [userData.id, userData])
      );

      // Process time entries
      const entries = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const userData = userDataMap[data.userId] || {};
        
        return {
          id: doc.id,
          name: userData.name || data.name || 'Unknown',
          union: userData.union || data.union || 'N/A',
          callTime: data.startTime || 'N/A',
          wrapTime: data.endTime || 'N/A',
          department: data.department,
          date: data.date?.toDate() || date,
          userId: data.userId,
          rate: userData.rate || data.rate || '0'
        };
      });

      console.log('Processed time entries:', entries.length);
      setTimeEntries(entries);
      setError(null);

    } catch (error) {
      console.error('Error in fetchTimeEntries:', error);
      setError('Failed to fetch time entries');
      setTimeEntries([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    const selectedDateStr = e.target.value; // Format: YYYY-MM-DD
    console.log('Report date selected:', selectedDateStr);

    // Create date object at noon to avoid timezone issues
    const reportDate = new Date(selectedDateStr + 'T12:00:00');
    const workDate = new Date(reportDate);
    workDate.setDate(workDate.getDate() - 1);

    console.log('Date selection:', {
      reportDate: reportDate.toISOString(),
      workDate: workDate.toISOString(),
      reportDateStr: format(reportDate, 'yyyy-MM-dd'),
      workDateStr: format(workDate, 'yyyy-MM-dd')
    });

    setSelectedDate(reportDate);
  };

  useEffect(() => {
    if (selectedDate && hasAccess && user && productionAccess && (departmentAccess || departmentAccess === 'all')) {
      console.log('Selected date changed, fetching time entries for:', selectedDate);
      fetchTimeEntries(selectedDate);
    }
  }, [selectedDate, hasAccess, user, productionAccess, departmentAccess]);

  const fetchReports = async () => {
    if (!hasAccess || !user) {
      setReports([]);
      return;
    }

    try {
      setLoading(true);
      let reportsQuery = query(
        collection(db, 'reports'),
        where('production', '==', productionAccess)
      );

      if (departmentAccess !== 'all') {
        reportsQuery = query(
          collection(db, 'reports'),
          where('production', '==', productionAccess),
          where('department', '==', departmentAccess)
        );
      }

      const querySnapshot = await getDocs(reportsQuery);
      const reportsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log('Fetched reports:', {
        total: reportsData.length,
        production: productionAccess,
        department: departmentAccess
      });

      setReports(reportsData);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setReports([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      const departmentsSnapshot = await getDocs(collection(db, 'departments'));
      const deptMap = {};
      departmentsSnapshot.forEach(doc => {
        deptMap[doc.id] = doc.data().name;
      });
      setDepartmentMap(deptMap);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const calculateTotalHours = (entry) => {
    if (entry.callTime === 'N/A' || entry.wrapTime === 'N/A') return 'N/A';
    const start = parseFloat(entry.callTime);
    const end = parseFloat(entry.wrapTime);
    if (isNaN(start) || isNaN(end)) return 'N/A';
    const diff = end - start;
    return diff.toFixed(2);
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  const handlePreviousDayReport = () => {
    const yesterday = new Date(selectedDate);
    yesterday.setDate(yesterday.getDate() - 1);
    setSelectedDate(yesterday);
  };

  const exportToPDF = () => {
    // Check if any columns are selected
    const anyColumnsSelected = Object.values(selectedColumns).some(value => value);
    if (!anyColumnsSelected) {
      setToast({ message: 'Please select at least one column to export', type: 'error' });
      return;
    }

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });
    const dateStr = selectedDate.toISOString().split('T')[0];
    const departmentStr = departmentMap[currentDepartment] || currentDepartment;
    
    // Add header information
    doc.setFontSize(18);
    doc.text('Time Report', 14, 15);
    doc.setFontSize(12);
    doc.text(`Department: ${departmentStr}`, 14, 25);
    doc.text(`Production: ${currentProduction}`, 14, 32);
    doc.text(`Date: ${selectedDate.toDateString()}`, 14, 39);

    // Filter headers and data based on selected columns
    const headers = columns
      .filter(col => selectedColumns[col.id])
      .map(col => col.label);

    const data = timeEntries.map(entry => {
      return columns
        .filter(col => selectedColumns[col.id])
        .map(col => {
          switch(col.id) {
            case 'name': return entry.name;
            case 'union': return entry.union;
            case 'call': return formatTime(entry.callTime);
            case 'wrap': return formatTime(entry.wrapTime);
            case 'totalHours': return calculateTotalHours(entry);
            case 'rate': return `$${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr`;
            case 'vendor': return entry.vendor || '';
            case 'towTag': return entry.towTag || '';
            case 'trailerTag': return entry.trailerTag || '';
            default: return '';
          }
        });
    });

    doc.autoTable({
      startY: 45,
      head: [headers],
      body: data,
    });
    doc.save(`${departmentStr}_time_report_${dateStr}.pdf`);
  };

  const exportToExcel = () => {
    // Check if any columns are selected
    const anyColumnsSelected = Object.values(selectedColumns).some(value => value);
    if (!anyColumnsSelected) {
      setToast({ message: 'Please select at least one column to export', type: 'error' });
      return;
    }

    const dateStr = selectedDate.toISOString().split('T')[0];
    const headerData = [
      ['Time Report'],
      [`Department: ${departmentMap[currentDepartment] || currentDepartment}`],
      [`Production: ${currentProduction}`],
      [`Date: ${selectedDate.toDateString()}`],
      [], // Empty row for spacing
    ];

    // Filter headers based on selected columns
    const headers = columns
      .filter(col => selectedColumns[col.id])
      .map(col => col.label);

    // Filter data based on selected columns
    const data = timeEntries.map(entry => {
      return columns
        .filter(col => selectedColumns[col.id])
        .map(col => {
          switch(col.id) {
            case 'name': return entry.name;
            case 'union': return entry.union;
            case 'call': return formatTime(entry.callTime);
            case 'wrap': return formatTime(entry.wrapTime);
            case 'totalHours': return calculateTotalHours(entry);
            case 'rate': return `$${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr`;
            case 'vendor': return entry.vendor || '';
            case 'towTag': return entry.towTag || '';
            case 'trailerTag': return entry.trailerTag || '';
            default: return '';
          }
        });
    });

    const ws = XLSX.utils.aoa_to_sheet([
      ...headerData,
      headers,
      ...data
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Time Report");
    XLSX.writeFile(wb, `time_report_${dateStr}.xlsx`);
  };

  const handleExportToGoogleSheets = () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    alert(`Export to Google Sheets for ${dateStr} is not yet implemented. This would typically involve using the Google Sheets API.`);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  const formatTime = (time) => {
    if (time === null || time === undefined) return 'N/A';
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const fetchVehicleEntries = async () => {
    console.log('Starting fetchVehicleEntries with:', {
      production: productionAccess,
      department: departmentAccess,
      hasAccess,
      user: user ? { 
        uid: user.uid, 
        production: user.production,
        department: user.department,
        role: user.role
      } : null
    });

    if (!hasAccess || !user || !productionAccess || (!departmentAccess && departmentAccess !== 'all')) {
      console.log('Early return from fetchVehicleEntries: no access or no user');
      setVehicleEntries([]);
      return;
    }

    try {
      setLoading(true);
      console.log('Building query for vehicles with:', {
        production: user.production,
        department: 'Transportation',
        userProduction: user.production,
        userDepartment: user.department
      });

      // Build query to match the working dashboard query
      const vehiclesQuery = query(
        collection(db, 'vehicles'),
        where('production', '==', user.production),
        where('department', '==', 'Transportation')
      );

      // Fetch vehicles
      console.log('Executing vehicles query...');
      const vehicleSnapshot = await getDocs(vehiclesQuery);
      console.log(`Found ${vehicleSnapshot.size} vehicles in initial query`);

      // Log each vehicle document for debugging
      vehicleSnapshot.forEach(doc => {
        console.log('Vehicle document:', {
          id: doc.id,
          data: doc.data()
        });
      });

      // Fetch all vendors for lookup
      console.log('Fetching vendors...');
      const vendorsSnapshot = await getDocs(collection(db, 'vendors'));
      const vendorsMap = {};
      vendorsSnapshot.forEach(doc => {
        console.log('Loading vendor:', { id: doc.id, data: doc.data() });
        vendorsMap[doc.id] = {
          name: doc.data().name || 'Unknown Vendor',
          address: doc.data().address,
          phone: doc.data().phone,
          email: doc.data().email
        };
      });
      console.log('Loaded vendors map:', vendorsMap);

      // Fetch all users for driver lookup
      console.log('Fetching users...');
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersMap = {};
      usersSnapshot.forEach(doc => {
        const userData = doc.data();
        usersMap[doc.id] = userData.name || userData.firstName + ' ' + userData.lastName || 'Unknown User';
      });
      console.log(`Loaded ${Object.keys(usersMap).length} users`);

      // Process vehicle documents
      const vehicles = [];
      vehicleSnapshot.forEach(doc => {
        const vehicle = doc.data();
        console.log('Processing vehicle:', {
          id: doc.id,
          vehicle,
          vendorId: vehicle.vendorId,
          vendorInfo: vehicle.vendorId ? vendorsMap[vehicle.vendorId] : null
        });

        // Add vendor name with fallback
        const vendorInfo = vehicle.vendorId ? vendorsMap[vehicle.vendorId] : null;
        const vendorName = vendorInfo ? vendorInfo.name : 'Unknown Vendor';
        
        // Add driver name
        const driverName = vehicle.assignedTo ? usersMap[vehicle.assignedTo] || 'Unknown Driver' : 'Unassigned';

        // Convert rental dates
        let rentalStart = null;
        let rentalEnd = null;

        if (vehicle.rentalStart) {
          rentalStart = vehicle.rentalStart instanceof Timestamp ? 
            vehicle.rentalStart.toDate() : 
            new Date(vehicle.rentalStart);
        }

        if (vehicle.rentalEnd) {
          rentalEnd = vehicle.rentalEnd instanceof Timestamp ? 
            vehicle.rentalEnd.toDate() : 
            new Date(vehicle.rentalEnd);
        }

        vehicles.push({
          id: doc.id,
          vendorName: vendorName,
          vendor: vendorName, // Keep for backward compatibility
          vendorInfo: vendorInfo, // Include full vendor info for tooltips/details
          unitNumber: vehicle.unitNumber || 'N/A',
          licensePlate: vehicle.licensePlate || 'N/A',
          driverAssigned: driverName,
          rentalRate: vehicle.rentalRate || 0,
          rentalStartDate: rentalStart,
          rentalEndDate: rentalEnd,
          status: vehicle.status || 'Unknown',
          make: vehicle.make || 'N/A',
          model: vehicle.model || 'N/A',
          year: vehicle.year || 'N/A',
          ...vehicle
        });
      });

      console.log(`Final vehicle count: ${vehicles.length}`, {
        vehicles: vehicles.map(v => ({
          id: v.id,
          unitNumber: v.unitNumber,
          production: v.production,
          department: v.department
        }))
      });
      setVehicleEntries(vehicles);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching vehicle entries:', error);
      setVehicleEntries([]);
      setLoading(false);
    }
  };

  // Add useEffect to fetch vehicle entries when tab changes
  useEffect(() => {
    if (activeTab === 'vehicleReports' && hasAccess && user && productionAccess && (departmentAccess || departmentAccess === 'all')) {
      console.log('Vehicle tab activated, fetching entries...');
      fetchVehicleEntries();
    }
  }, [activeTab, hasAccess, user, productionAccess, departmentAccess]);

  const handleVehicleEntryDateChange = (id, field, value) => {
    // Basic validation for MM/DD/YY format
    if (/^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{2}$/.test(value) || value === '') {
      setVehicleEntries(entries =>
        entries.map(entry =>
          entry.id === id ? { ...entry, [field]: value } : entry
        )
      );
    }
  };

  // Add this before the export functions
  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'union', label: 'Union' },
    { id: 'call', label: 'Call' },
    { id: 'wrap', label: 'Wrap' },
    { id: 'totalHours', label: 'Total Hours' },
    { id: 'rate', label: 'Rate' },
    { id: 'vendor', label: 'Vendor' },
    { id: 'towTag', label: 'Tow/Tag' },
    { id: 'trailerTag', label: 'Trailer/Tag' }
  ];

  const handleColumnToggle = (columnId) => {
    setSelectedColumns(prev => ({
      ...prev,
      [columnId]: !prev[columnId]
    }));
  };

  // Add this function to handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleRentalDateChange = async (vehicleId, field, date) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        [field]: date ? Timestamp.fromDate(date) : null
      });
      
      // Update local state
      setVehicleEntries(entries => 
        entries.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, [field]: date }
            : vehicle
        )
      );
      
      setToast({ 
        message: 'Rental date updated successfully', 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error updating rental date:', error);
      setToast({ 
        message: 'Failed to update rental date', 
        type: 'error' 
      });
    }
  };

  // Update the calculateDailyCost function
  const calculateDailyCost = (vehicle, days) => {
    if (!vehicle.rentalRate) return null;
    const rate = parseFloat(vehicle.rentalRate);
    
    switch (vehicle.rentalPeriod) {
      case 'daily':
        return (rate * days).toFixed(2);
      case 'weekly':
        return (rate * Math.ceil(days / 7)).toFixed(2);
      case 'monthly':
        return (rate * Math.ceil(days / 30)).toFixed(2);
      default:
        return null;
    }
  };

  // Update the getRateDisplay function in the table
  const getRateDisplay = (vehicle) => {
    if (!vehicle.rentalRate) return 'Not set';
    
    const daysCount = daysCounts[vehicle.id] || 1;
    const cost = calculateDailyCost(vehicle, daysCount);
    
    if (vehicle.rentalPeriod === 'daily') {
      return `$${vehicle.rentalRate}/${vehicle.rentalPeriod} (${daysCount} days = $${cost})`;
    }
    
    if (vehicle.rentalPeriod === 'weekly') {
      const weeks = Math.ceil(daysCount / 7);
      return `$${vehicle.rentalRate}/${vehicle.rentalPeriod} (${weeks} weeks = $${cost})`;
    }
    
    if (vehicle.rentalPeriod === 'monthly') {
      const months = Math.ceil(daysCount / 30);
      return `$${vehicle.rentalRate}/${vehicle.rentalPeriod} (${months} months = $${cost})`;
    }
    
    return `$${vehicle.rentalRate}/${vehicle.rentalPeriod}`;
  };

  // Add this state at the top of the component with other state declarations
  const [daysCounts, setDaysCounts] = useState({});

  // Add this handler function
  const handleDaysChange = (vehicleId, days) => {
    setDaysCounts(prev => ({
      ...prev,
      [vehicleId]: parseInt(days)
    }));
  };

  // Add this function to calculate the status based on rental end date
  const getRentalStatus = (rentalEnd) => {
    if (!rentalEnd) return 'not-set';
    
    // Convert to Date object if it's a Firestore Timestamp
    const endDate = rentalEnd instanceof Timestamp ? 
      rentalEnd.toDate() : 
      (rentalEnd instanceof Date ? rentalEnd : new Date(rentalEnd));

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const diffDays = Math.floor((endDate - today) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) return 'overdue';  // Past end date
    if (diffDays === 0) return 'ending';  // End date is today
    if (diffDays <= 5) return 'warning';  // 5 days or less
    return 'good';  // More than 5 days
  };

  useEffect(() => {
    if (activeTab === 'hotSheet' && hasAccess && user && productionAccess && (departmentAccess || departmentAccess === 'all')) {
      console.log('Fetching hot sheet data for date:', selectedDate);
      fetchHotSheetData(selectedDate);
    }
  }, [activeTab, selectedDate, hasAccess, user, productionAccess, departmentAccess]);

  const fetchHotSheetData = async (date) => {
    if (!hasAccess || !user || !date || !productionAccess || (!departmentAccess && departmentAccess !== 'all')) {
      console.log('Early return from fetchHotSheetData: missing required data');
      setHotSheetEntries([]);
      return;
    }

    try {
      setLoading(true);

      // Set up the date range for the query
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999);

      console.log('Querying hot sheet entries between:', {
        start: startOfDay.toISOString(),
        end: endOfDay.toISOString(),
        production: productionAccess,
        department: departmentAccess
      });

      // Query time entries
      let timeEntriesQuery = departmentAccess !== 'all'
        ? query(
            collection(db, 'timeEntries'),
            where('production', '==', productionAccess),
            where('department', '==', departmentAccess),
            where('date', '>=', Timestamp.fromDate(startOfDay)),
            where('date', '<=', Timestamp.fromDate(endOfDay))
          )
        : query(
            collection(db, 'timeEntries'),
            where('production', '==', productionAccess),
            where('date', '>=', Timestamp.fromDate(startOfDay)),
            where('date', '<=', Timestamp.fromDate(endOfDay))
          );

      const [timeEntriesSnapshot, usersSnapshot] = await Promise.all([
        getDocs(timeEntriesQuery),
        getDocs(collection(db, 'users'))
      ]);
      
      const usersMap = Object.fromEntries(
        usersSnapshot.docs.map(doc => [
          doc.id,
          {
            name: doc.data().name,
            union: doc.data().union,
            rate: doc.data().rate
          }
        ])
      );

      const entries = timeEntriesSnapshot.docs.map(doc => {
        const data = doc.data();
        const userData = usersMap[data.userId] || {};
        const rate = parseFloat(userData.rate) || 0;
        const totalHours = data.startTime && data.endTime
          ? (data.endTime - data.startTime + (data.endTime < data.startTime ? 24 : 0))
          : 0;
        
        const { regularPay, timeAndHalfPay, doubleTimePay, tripleTimePay, totalPay } = 
          calculatePay(totalHours, rate);

        return {
          id: doc.id,
          name: userData.name || 'Unknown',
          union: userData.union || 'N/A',
          call: formatTime(data.startTime),
          wrap: formatTime(data.endTime),
          totalHours,
          rate,
          regularPay,
          timeAndHalfPay,
          doubleTimePay,
          tripleTimePay,
          totalPay
        };
      });

      console.log('Processed hot sheet entries:', entries.length);
      setHotSheetEntries(entries);
      setError(null);

    } catch (error) {
      console.error('Error fetching hot sheet data:', error);
      setError('Failed to fetch hot sheet data');
      setHotSheetEntries([]);
    } finally {
      setLoading(false);
    }
  };

  const handleHotSheetDateChange = (e) => {
    const selectedDateStr = e.target.value; // Format: YYYY-MM-DD
    console.log('Hot sheet date selected:', selectedDateStr);

    // Create date object at noon to avoid timezone issues
    const newDate = new Date(selectedDateStr + 'T12:00:00');
    setSelectedDate(newDate);
  };

  const handlePreviousDayHotSheet = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDayHotSheet = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  const exportHotSheetToPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });

    // Add header information
    doc.setFontSize(18);
    doc.text('Hot Sheet', 14, 15);
    doc.setFontSize(12);
    doc.text(`Department: ${departmentMap[user.department] || user.department}`, 14, 25);
    doc.text(`Production: ${user.production}`, 14, 32);
    doc.text(`Date: ${selectedDate.toDateString()}`, 14, 39);

    // Define the table headers and data
    const headers = [
      ['Name', 'Union', 'Call', 'Wrap', 'Total Hours', 'Rate', 'Regular Pay', 'Time and Half Pay', 'Double Time Pay', 'Triple Time Pay', 'Total Pay']
    ];

    const data = hotSheetEntries.map(entry => [
      entry.name,
      entry.union,
      entry.call,
      entry.wrap,
      entry.totalHours.toFixed(2),
      `$${entry.rate.toFixed(2)}`,
      `$${entry.regularPay.toFixed(2)}`,
      `$${entry.timeAndHalfPay.toFixed(2)}`,
      `$${entry.doubleTimePay.toFixed(2)}`,
      `$${entry.tripleTimePay.toFixed(2)}`,
      `$${entry.totalPay.toFixed(2)}`
    ]);

    // Add totals row
    const totals = [
      'Daily Total:',
      '',
      '',
      '',
      '',
      '',
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.regularPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.timeAndHalfPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.doubleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.tripleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.totalPay, 0).toFixed(2)}`
    ];
    data.push(totals);

    doc.autoTable({
      startY: 45,
      head: headers,
      body: data,
    });
    doc.save(`hot_sheet_${selectedDate.toISOString().split('T')[0]}.pdf`);
  };

  const exportHotSheetToExcel = () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    
    // Create header rows
    const headerData = [
      ['Hot Sheet'],
      [`Department: ${departmentMap[user.department] || user.department}`],
      [`Production: ${user.production}`],
      [`Date: ${selectedDate.toDateString()}`],
      [], // Empty row for spacing
      ['Name', 'Union', 'Call', 'Wrap', 'Total Hours', 'Rate', 'Regular Pay', 'Time and Half Pay', 'Double Time Pay', 'Triple Time Pay', 'Total Pay']
    ];

    // Create data rows
    const data = hotSheetEntries.map(entry => [
      entry.name,
      entry.union,
      entry.call,
      entry.wrap,
      entry.totalHours.toFixed(2),
      `$${entry.rate.toFixed(2)}`,
      `$${entry.regularPay.toFixed(2)}`,
      `$${entry.timeAndHalfPay.toFixed(2)}`,
      `$${entry.doubleTimePay.toFixed(2)}`,
      `$${entry.tripleTimePay.toFixed(2)}`,
      `$${entry.totalPay.toFixed(2)}`
    ]);

    // Add totals row
    const totals = [
      'Daily Total:',
      '',
      '',
      '',
      '',
      '',
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.regularPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.timeAndHalfPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.doubleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.tripleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.totalPay, 0).toFixed(2)}`
    ];
    data.push(totals);

    const ws = XLSX.utils.aoa_to_sheet([...headerData, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Hot Sheet");
    XLSX.writeFile(wb, `hot_sheet_${dateStr}.xlsx`);
  };

  const generateHotSheet = async () => {
    console.log('Starting generateHotSheet with:', {
      production: productionAccess,
      department: departmentAccess,
      date: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : null,
      timeEntriesCount: timeEntries.length,
      hasAccess,
      userId: user?.uid
    });

    if (!hasAccess || !productionAccess || (!departmentAccess && departmentAccess !== 'all')) {
      console.log('Early return from generateHotSheet: no access');
      return;
    }

    try {
      setIsGeneratingReport(true);

      // Log current time entries before fetching fresh ones
      console.log('Current time entries before fetch:', timeEntries.map(entry => ({
        id: entry.id,
        userId: entry.userId,
        production: entry.production,
        department: entry.department,
        date: entry.date ? format(entry.date, 'yyyy-MM-dd') : null
      })));

      // Fetch fresh data before generating report
      await fetchTimeEntries(selectedDate);

      // Log time entries after fresh fetch
      console.log('Time entries after fresh fetch:', timeEntries.map(entry => ({
        id: entry.id,
        userId: entry.userId,
        production: entry.production,
        department: entry.department,
        date: entry.date ? format(entry.date, 'yyyy-MM-dd') : null
      })));

      // Get user data for all entries
      const userIds = new Set(timeEntries.map(entry => entry.userId));
      console.log('Fetching user data for:', {
        uniqueUserIds: Array.from(userIds),
        count: userIds.size
      });

      const userDocs = await Promise.all(
        Array.from(userIds).map(async userId => {
          const userDoc = await getDoc(doc(db, 'users', userId));
          console.log('Fetched user data:', {
            userId,
            exists: userDoc.exists(),
            data: userDoc.exists() ? {
              name: userDoc.data().name,
              department: userDoc.data().department,
              production: userDoc.data().production
            } : null
          });
          return userDoc;
        })
      );

      const userDataMap = Object.fromEntries(
        userDocs.map(userDoc => [userDoc.id, userDoc.data()])
      );

      console.log('User data map created:', {
        totalUsers: Object.keys(userDataMap).length,
        userSummary: Object.entries(userDataMap).map(([id, data]) => ({
          id,
          name: data?.name,
          department: data?.department,
          production: data?.production
        }))
      });

      let filteredEntries = timeEntries.filter(entry => {
        const matchesProduction = entry.production === productionAccess;
        const matchesDepartment = departmentAccess === 'all' || entry.department === departmentAccess;
        console.log('Filtering entry:', {
          entryId: entry.id,
          userId: entry.userId,
          entryProduction: entry.production,
          entryDepartment: entry.department,
          expectedProduction: productionAccess,
          expectedDepartment: departmentAccess,
          date: entry.date ? format(entry.date, 'yyyy-MM-dd') : null
        });
        return matchesProduction && matchesDepartment;
      });

      console.log('Filtered entries:', {
        total: filteredEntries.length,
        entries: filteredEntries.map(entry => ({
          id: entry.id,
          userId: entry.userId,
          production: entry.production,
          department: entry.department,
          date: entry.date ? format(entry.date, 'yyyy-MM-dd') : null
        }))
      });

      const hotSheetData = filteredEntries.map(entry => {
        const userData = userDataMap[entry.userId] || {};
        const rowData = {
          Name: userData.name || 'Unknown',
          Department: userData.department || entry.department || 'N/A',
          Date: format(entry.date, 'MM/dd/yyyy'),
          'Start Time': entry.startTime || entry.callTime || 'N/A',
          'End Time': entry.endTime || entry.wrapTime || 'N/A',
          'Total Hours': calculateTotalHours(entry),
          'Rate': userData.rate || 'N/A',
          'Union': userData.union || 'N/A',
          Status: entry.status || 'N/A',
          Production: entry.production || productionAccess
        };

        console.log('Created hot sheet row:', {
          entryId: entry.id,
          userId: entry.userId,
          rowData
        });

        return rowData;
      });

      console.log('Final hot sheet data:', {
        totalRows: hotSheetData.length,
        production: productionAccess,
        department: departmentAccess,
        sampleRows: hotSheetData.slice(0, 2)
      });

      if (hotSheetData.length === 0) {
        console.log('No data found for hot sheet');
        setToast({
          message: 'No data found for the selected date',
          type: 'warning'
        });
        return;
      }

      // Create workbook and add data
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(hotSheetData);

      // Add header with production and department info
      const header = [
        ['Hot Sheet Report'],
        [`Production: ${productionAccess}`],
        [`Department: ${departmentAccess === 'all' ? 'All Departments' : departmentAccess}`],
        [`Date: ${format(selectedDate, 'MM/dd/yyyy')}`],
        [] // Empty row before data
      ];

      // Log header information
      console.log('Adding header to worksheet:', header);

      // Prepend header to worksheet
      XLSX.utils.sheet_add_aoa(ws, header, { origin: 'A1' });

      XLSX.utils.book_append_sheet(wb, ws, 'Hot Sheet');

      const dateStr = format(selectedDate, 'yyyy-MM-dd');
      const fileName = `hot_sheet_${productionAccess}_${departmentAccess === 'all' ? 'all_departments' : departmentAccess}_${dateStr}.xlsx`;

      console.log('Saving file:', {
        fileName,
        rowCount: hotSheetData.length + header.length
      });

      XLSX.writeFile(wb, fileName);
      
      setToast({
        message: 'Hot sheet generated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error generating hot sheet:', error);
      setToast({
        message: 'Failed to generate hot sheet',
        type: 'error'
      });
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const handleCreateReport = async (reportData) => {
    if (!hasAccess) return;

    try {
      setIsGeneratingReport(true);
      const enrichedReportData = {
        ...reportData,
        production: productionAccess,
        department: departmentAccess === 'all' ? reportData.department : departmentAccess,
        createdAt: serverTimestamp(),
        createdBy: user.uid
      };

      const docRef = await addDoc(collection(db, 'reports'), enrichedReportData);
      console.log('Created report:', docRef.id);
      
      // Show success message
      setToast({
        message: 'Report created successfully',
        type: 'success'
      });

      // Refresh data
      await fetchReports();
    } catch (error) {
      console.error('Error creating report:', error);
      setToast({
        message: 'Failed to create report',
        type: 'error'
      });
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const exportVehiclesToPDF = () => {
    if (!hasAccess || !user || vehicleEntries.length === 0) {
      setToast({
        message: 'No vehicle data to export',
        type: 'warning'
      });
      return;
    }

    try {
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
      });

      // Add header information
      doc.setFontSize(18);
      doc.text('Vehicle Report', 14, 15);
      doc.setFontSize(12);
      doc.text(`Production: ${productionAccess}`, 14, 25);
      doc.text(`Department: ${departmentAccess === 'all' ? 'All Departments' : departmentAccess}`, 14, 32);
      doc.text(`Date: ${format(new Date(), 'MM/dd/yyyy')}`, 14, 39);

      // Define the table headers and data
      const headers = [
        ['Unit Number', 'License Plate', 'Status', 'Department', 'Driver', 'Rental Rate', 'Rental Period']
      ];

      const data = vehicleEntries.map(vehicle => [
        vehicle.unitNumber || 'N/A',
        vehicle.licensePlate || 'N/A',
        vehicle.status || 'N/A',
        vehicle.department || 'N/A',
        vehicle.driverAssigned || 'Not Assigned',
        vehicle.rentalRate ? `$${vehicle.rentalRate}` : 'N/A',
        vehicle.rentalPeriod || 'N/A'
      ]);

      doc.autoTable({
        startY: 45,
        head: headers,
        body: data,
      });

      const fileName = `vehicle_report_${productionAccess}_${departmentAccess === 'all' ? 'all_departments' : departmentAccess}_${format(new Date(), 'yyyy-MM-dd')}.pdf`;
      doc.save(fileName);

      setToast({
        message: 'PDF exported successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error exporting to PDF:', error);
      setToast({
        message: 'Failed to export PDF',
        type: 'error'
      });
    }
  };

  const exportVehiclesToExcel = () => {
    if (!hasAccess || !user || vehicleEntries.length === 0) {
      setToast({
        message: 'No vehicle data to export',
        type: 'warning'
      });
      return;
    }

    try {
      // Create header rows
      const headerData = [
        ['Vehicle Report'],
        [`Production: ${productionAccess}`],
        [`Department: ${departmentAccess === 'all' ? 'All Departments' : departmentAccess}`],
        [`Date: ${format(new Date(), 'MM/dd/yyyy')}`],
        [], // Empty row for spacing
        ['Unit Number', 'License Plate', 'Status', 'Department', 'Driver', 'Rental Rate', 'Rental Period']
      ];

      // Create data rows
      const data = vehicleEntries.map(vehicle => [
        vehicle.unitNumber || 'N/A',
        vehicle.licensePlate || 'N/A',
        vehicle.status || 'N/A',
        vehicle.department || 'N/A',
        vehicle.driverAssigned || 'Not Assigned',
        vehicle.rentalRate ? `$${vehicle.rentalRate}` : 'N/A',
        vehicle.rentalPeriod || 'N/A'
      ]);

      const ws = XLSX.utils.aoa_to_sheet([...headerData, ...data]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Vehicle Report");

      const fileName = `vehicle_report_${productionAccess}_${departmentAccess === 'all' ? 'all_departments' : departmentAccess}_${format(new Date(), 'yyyy-MM-dd')}.xlsx`;
      XLSX.writeFile(wb, fileName);

      setToast({
        message: 'Excel file exported successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      setToast({
        message: 'Failed to export Excel file',
        type: 'error'
      });
    }
  };

  // Add missing state variables
  const getWeekDates = (date) => {
    const sunday = new Date(date);
    sunday.setDate(date.getDate() - date.getDay());
    return sunday;
  };

  const formatWeekRange = (startDate) => {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    return `Week of ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  };

  // Add vehicle date navigation handlers
  const handlePreviousWeekVehicle = () => {
    const newDate = new Date(vehicleReportDate);
    newDate.setDate(newDate.getDate() - 7);
    setVehicleReportDate(getWeekDates(newDate));
    fetchVehicleEntries();
  };

  const handleNextWeekVehicle = () => {
    const newDate = new Date(vehicleReportDate);
    newDate.setDate(newDate.getDate() + 7);
    setVehicleReportDate(getWeekDates(newDate));
    fetchVehicleEntries();
  };

  const handleVehicleDateChange = (e) => {
    setVehicleReportDate(new Date(e.target.value));
    fetchVehicleEntries();
  };

  // Add pay calculation helper
  const calculatePay = (hours, rate) => {
    const regularHours = Math.min(8, hours);
    const timeAndHalfHours = hours > 8 ? Math.min(4, hours - 8) : 0;
    const doubleTimeHours = hours > 12 ? Math.min(4, hours - 12) : 0;
    const tripleTimeHours = hours > 16 ? hours - 16 : 0;

    return {
      regularPay: regularHours * rate,
      timeAndHalfPay: timeAndHalfHours * (rate * 1.5),
      doubleTimePay: doubleTimeHours * (rate * 2),
      tripleTimePay: tripleTimeHours * (rate * 3),
      totalPay: (regularHours * rate) +
                (timeAndHalfHours * rate * 1.5) +
                (doubleTimeHours * rate * 2) +
                (tripleTimeHours * rate * 3)
    };
  };

  // Early return if no access
  if (accessLoading) {
    return <div>Loading access permissions...</div>;
  }

  if (accessError) {
    return <div>Error: {accessError}</div>;
  }

  if (!hasAccess) {
    return <div>You don't have permission to view this page.</div>;
  }

  return (
    <div className="reports-container">
      <Sidebar />
      <div className="reports-content">
        <div className="tabs-container">
          <div className="tabs">
            <button 
              className={`tab-button ${activeTab === 'timeReports' ? 'active' : ''}`}
              onClick={() => handleTabChange('timeReports')}
            >
              Time Report
            </button>
            <button 
              className={`tab-button ${activeTab === 'vehicleReports' ? 'active' : ''}`}
              onClick={() => handleTabChange('vehicleReports')}
            >
              Vehicle Report
            </button>
            <button 
              className={`tab-button ${activeTab === 'hotSheet' ? 'active' : ''}`}
              onClick={() => handleTabChange('hotSheet')}
            >
              Hot Sheet
            </button>
          </div>
        </div>

        {/* Time Reports Tab */}
        {activeTab === 'timeReports' && (
          <div className="tab-content">
            <header className="page-header">
              <h1>Time Report</h1>
              <div className="date-navigation">
                <button onClick={handlePreviousDay}>&lt; Previous Day</button>
                <input
                  type="date"
                  value={selectedDate.toISOString().split('T')[0]}
                  onChange={handleDateChange}
                />
                <button onClick={handleNextDay}>Next Day &gt;</button>
              </div>
              <button onClick={handlePreviousDayReport} className="previous-day-report-btn">
                View Previous Day's Report
              </button>
              <p>Viewing entries for: {selectedDate.toDateString()}</p>
              <div className="report-header">
                <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
              </div>
            </header>
            <main>
              <div className="time-report-table">
                <div className="export-buttons">
                  <button onClick={exportToPDF}>Export to PDF</button>
                  <button onClick={exportToExcel}>Export to Excel</button>
                </div>
                <div className="column-selector">
                  <h3>Select Columns to Export:</h3>
                  <div className="column-options">
                    {columns.map(column => (
                      <label key={column.id} className="column-option">
                        <input
                          type="checkbox"
                          checked={selectedColumns[column.id]}
                          onChange={() => handleColumnToggle(column.id)}
                        />
                        {column.label}
                      </label>
                    ))}
                  </div>
                </div>
                {loading ? (
                  <p>Loading time entries...</p>
                ) : error ? (
                  <p className="error-message">{error}</p>
                ) : (
                  <>
                    <div className="report-header">
                      <p><strong>Department:</strong> {departmentMap[currentDepartment] || currentDepartment}</p>
                      <p><strong>Production:</strong> {currentProduction}</p>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Union</th>
                          <th>Call</th>
                          <th>Wrap</th>
                          <th>Total Hours</th>
                          <th>Rate</th>
                          <th>Vendor</th>
                          <th>Tow/Tag</th>
                          <th>Trailer/Tag</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timeEntries.length === 0 ? (
                          <tr>
                            <td colSpan="9" className="no-entries-message">
                              No time entries found for the selected date.
                            </td>
                          </tr>
                        ) : (
                          timeEntries.map(entry => (
                            <tr key={entry.id}>
                              <td>{entry.name}</td>
                              <td>{entry.union}</td>
                              <td>{formatTime(entry.callTime)}</td>
                              <td>{formatTime(entry.wrapTime)}</td>
                              <td>{calculateTotalHours(entry)}</td>
                              <td>${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr</td>
                              <td>{entry.vendor || ''}</td>
                              <td>{entry.towTag}</td>
                              <td>Not assigned</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </main>
          </div>
        )}

        {/* Vehicle Reports Tab */}
        {activeTab === 'vehicleReports' && (
          <div className="tab-content">
            <div className="vehicle-report-section">
              <header className="page-header">
                <h2>Vehicle Reports</h2>
                <div className="date-navigation">
                  <button onClick={handlePreviousWeekVehicle}>&lt; Previous Week</button>
                  <span className="week-display">
                    {formatWeekRange(vehicleReportDate)}
                  </span>
                  <button onClick={handleNextWeekVehicle}>Next Week &gt;</button>
                </div>
                <div className="report-header">
                  <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
                </div>
              </header>
              <div className="export-buttons">
                <button onClick={exportVehiclesToPDF}>Export to PDF</button>
                <button onClick={exportVehiclesToExcel}>Export to Excel</button>
              </div>
              <div className="report-header">
                <p><strong>Department:</strong> {departmentMap[user.department] || user.department}</p>
                <p><strong>Production:</strong> {user.production}</p>
              </div>
              <div className="vehicle-table-container">
                {loading ? (
                  <div className="loading-message">Loading vehicle entries...</div>
                ) : vehicleEntries.length === 0 ? (
                  <div className="no-entries-message">No vehicle entries found.</div>
                ) : (
                  <table className="vehicle-table">
                    <thead>
                      <tr>
                        <th>Vendor</th>
                        <th>Unit Number</th>
                        <th>Tag #</th>
                        <th>Driver Assigned</th>
                        <th>Rental Rate</th>
                        <th>Rental Start</th>
                        <th>Rental End</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicleEntries.map(entry => {
                        const rate = Number(entry.rentalRate) || 0;

                        return (
                          <tr key={entry.id}>
                            <td>{entry.vendorName || entry.vendor || 'Unknown Vendor'}</td>
                            <td>{entry.unitNumber || 'Not Assigned'}</td>
                            <td>{entry.licensePlate || 'Not Assigned'}</td>
                            <td>{entry.driverAssigned}</td>
                            <td>${rate.toFixed(2)}/{entry.rentalPeriod || 'day'}</td>
                            <td>{entry.rentalStartDate ? new Date(entry.rentalStartDate).toLocaleDateString() : 'Not set'}</td>
                            <td>{entry.rentalEndDate ? new Date(entry.rentalEndDate).toLocaleDateString() : 'Not set'}</td>
                            <td>
                              <div className={`status-indicator ${getRentalStatus(entry.rentalEndDate)}`}></div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="4" className="total-label">Weekly Total:</td>
                        <td colSpan="4" className="total-amount">
                          ${vehicleEntries.reduce((total, entry) => total + (Number(entry.rentalRate) || 0), 0).toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Hot Sheet Tab */}
        {activeTab === 'hotSheet' && (
          <div className="tab-content">
            <div className="hot-sheet-section">
              <header className="page-header">
                <h2>Hot Sheet</h2>
                <div className="date-navigation">
                  <button onClick={handlePreviousDayHotSheet}>&lt; Previous Day</button>
                  <input
                    type="date"
                    value={selectedDate.toISOString().split('T')[0]}
                    onChange={handleHotSheetDateChange}
                  />
                  <button onClick={handleNextDayHotSheet}>Next Day &gt;</button>
                </div>
                <p>Viewing hot sheet for: {selectedDate.toDateString()}</p>
                <div className="report-header">
                  <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
                </div>
              </header>
              <div className="export-buttons">
                <button onClick={exportHotSheetToPDF}>Export to PDF</button>
                <button onClick={exportHotSheetToExcel}>Export to Excel</button>
              </div>
              <div className="report-header">
                <p><strong>Department:</strong> {departmentMap[currentDepartment] || currentDepartment}</p>
                <p><strong>Production:</strong> {currentProduction}</p>
              </div>
              <div className="hot-sheet-table-container">
                <table className="hot-sheet-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Union</th>
                      <th>Call</th>
                      <th>Wrap</th>
                      <th>Total Hours</th>
                      <th>Rate</th>
                      <th>Regular Pay</th>
                      <th>Time and Half Pay</th>
                      <th>Double Time Pay</th>
                      <th>Triple Time Pay</th>
                      <th>Total Pay</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotSheetEntries.map(entry => (
                      <tr key={entry.id}>
                        <td>{entry.name}</td>
                        <td>{entry.union}</td>
                        <td>{entry.call}</td>
                        <td>{entry.wrap}</td>
                        <td>{entry.totalHours.toFixed(2)}</td>
                        <td>${(parseFloat(entry.rate) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.regularPay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.timeAndHalfPay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.doubleTimePay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.tripleTimePay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.totalPay) || 0).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="6" className="total-label">Daily Total:</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.regularPay, 0).toFixed(2)}</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.timeAndHalfPay, 0).toFixed(2)}</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.doubleTimePay, 0).toFixed(2)}</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.tripleTimePay, 0).toFixed(2)}</td>
                      <td className="total-amount">${hotSheetEntries.reduce((sum, entry) => sum + entry.totalPay, 0).toFixed(2)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {toast && (
        <Toast 
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
    </div>
  );
}

export default Reports;