import React, { useState } from 'react';
import styled from 'styled-components';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const CrewCallTimesContainer = styled.div`
  margin-bottom: 20px;
`;

const CrewCallTimesTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e2e8f0;
  padding: 8px 10px;
`;

const AddCrewControls = styled.div`
  display: flex;
  gap: 10px;
`;

const CrewSelect = styled.select`
  padding: 4px 10px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  font-size: 14px;
`;

const AddCrewButton = styled.button`
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 14px;
  cursor: pointer;
  
  &:hover {
    background-color: #3182ce;
  }
`;

const DepartmentHeader = styled.div`
  background-color: #edf2f7;
  padding: 10px;
  font-weight: bold;
  border: 1px solid #cbd5e0;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const CrewTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 14px;
`;

const CrewTableHead = styled.thead`
  background-color: #f8fafc;
  
  th {
    padding: 8px 10px;
    text-align: left;
    font-weight: bold;
    border: 1px solid #cbd5e0;
  }
`;

const EmptyCrewMessage = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #f8fafc;
  border: 1px dashed #cbd5e0;
  color: #718096;
  margin-top: 10px;
`;

const WorkflowNote = styled.div`
  margin-top: 30px;
  padding: 10px;
  background-color: #ebf8ff;
  border: 1px solid #bee3f8;
  border-radius: 4px;
  color: #2c5282;
  font-size: 14px;
`;

// Editable cell component
const EditableCell = ({ value, onChange, type = 'text', width = 'auto' }) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  
  const handleClick = () => {
    setEditing(true);
  };
  
  const handleChange = (e) => {
    setCurrentValue(e.target.value);
  };
  
  const handleBlur = () => {
    setEditing(false);
    onChange(currentValue);
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setEditing(false);
      onChange(currentValue);
    }
  };
  
  // Update local state if props change
  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  
  return (
    <td 
      onClick={!editing ? handleClick : undefined}
      style={{ 
        cursor: editing ? 'default' : 'pointer',
        border: '1px solid #cbd5e0', 
        padding: '8px 10px',
        width
      }}
    >
      {editing ? (
        <input
          type={type}
          value={currentValue || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          style={{
            width: '100%',
            border: 'none',
            outline: 'none',
            background: 'transparent',
            fontFamily: 'inherit',
            fontSize: 'inherit'
          }}
        />
      ) : (
        <div style={{ minHeight: '18px' }}>
          {value || <span style={{ color: '#a0aec0', fontStyle: 'italic' }}>Click to edit</span>}
        </div>
      )}
    </td>
  );
};

// Draggable crew row component
const SortableCrewRow = ({ crewMember, departments, updateCrewCallTime, removeCrewMember }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: crewMember.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    position: 'relative',
    zIndex: isDragging ? 999 : 'auto',
  };
  
  const handleUpdate = (field, value) => {
    updateCrewCallTime(crewMember.id, field, value);
  };
  
  // Find department name
  const getDepartmentName = () => {
    const dept = departments.find(d => d.id === crewMember.department);
    return dept ? dept.name : 'Unknown';
  };
  
  return (
    <tr ref={setNodeRef} style={style}>
      <td 
        {...attributes} 
        {...listeners}
        style={{ 
          cursor: 'grab', 
          width: '40px',
          textAlign: 'center',
          backgroundColor: '#f1f5f9',
          border: '1px solid #cbd5e0'
        }}
      >
        ☰
      </td>
      <td style={{ border: '1px solid #cbd5e0', padding: '8px 10px' }}>
        {crewMember.name}
      </td>
      <td style={{ border: '1px solid #cbd5e0', padding: '8px 10px' }}>
        {getDepartmentName()}
      </td>
      <EditableCell
        value={crewMember.role}
        onChange={(value) => handleUpdate('role', value)}
      />
      <EditableCell
        value={crewMember.callTime}
        onChange={(value) => handleUpdate('callTime', value)}
        type="time"
        width="100px"
      />
      <EditableCell
        value={crewMember.location}
        onChange={(value) => handleUpdate('location', value)}
      />
      <EditableCell
        value={crewMember.notes}
        onChange={(value) => handleUpdate('notes', value)}
      />
      <td 
        style={{ 
          width: '60px', 
          textAlign: 'center',
          border: '1px solid #cbd5e0'
        }}
      >
        <button 
          onClick={() => removeCrewMember(crewMember.id)}
          style={{
            background: '#fc8181',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '4px 8px',
            cursor: 'pointer',
            fontSize: '12px'
          }}
        >
          Remove
        </button>
      </td>
    </tr>
  );
};

const VisualCrewCallTimes = ({ 
  crewCallTimes, 
  departments, 
  crewMembers,
  addCrewMember, 
  updateCrewCallTime, 
  removeCrewMember, 
  reorderCrewMembers,
  availableCrewMembers 
}) => {
  const [selectedCrewMember, setSelectedCrewMember] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  
  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = crewCallTimes.findIndex((crew) => crew.id === active.id);
      const newIndex = crewCallTimes.findIndex((crew) => crew.id === over.id);
      
      reorderCrewMembers(oldIndex, newIndex);
    }
  };
  
  const handleAddCrewMember = () => {
    if (selectedCrewMember) {
      addCrewMember(selectedCrewMember);
      setSelectedCrewMember('');
    }
  };
  
  const handleAddDepartment = () => {
    if (selectedDepartment) {
      const departmentCrewMembers = availableCrewMembers
        .filter(crew => crew.department === selectedDepartment)
        .map(crew => crew.id);
      
      departmentCrewMembers.forEach(crewId => {
        addCrewMember(crewId);
      });
      
      setSelectedDepartment('');
    }
  };
  
  // Group crew by department for display
  const crewByDepartment = {};
  crewCallTimes.forEach(crew => {
    const deptId = crew.department;
    if (!crewByDepartment[deptId]) {
      crewByDepartment[deptId] = [];
    }
    crewByDepartment[deptId].push(crew);
  });
  
  // Sort departments for display
  const sortedDepartments = Object.keys(crewByDepartment).sort((a, b) => {
    const deptA = departments.find(d => d.id === a)?.name || '';
    const deptB = departments.find(d => d.id === b)?.name || '';
    return deptA.localeCompare(deptB);
  });
  
  return (
    <CrewCallTimesContainer>
      <CrewCallTimesTitle>
        <span>CREW CALL TIMES</span>
        <AddCrewControls>
          <CrewSelect
            value={selectedCrewMember}
            onChange={(e) => setSelectedCrewMember(e.target.value)}
          >
            <option value="">Select Crew Member</option>
            {availableCrewMembers.map((crew) => (
              <option key={crew.id} value={crew.id}>
                {crew.name}
              </option>
            ))}
          </CrewSelect>
          <AddCrewButton onClick={handleAddCrewMember} disabled={!selectedCrewMember}>
            Add Crew Member
          </AddCrewButton>
          
          <CrewSelect
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">Select Department</option>
            {departments.map((dept) => (
              <option key={dept.id} value={dept.id}>
                {dept.name}
              </option>
            ))}
          </CrewSelect>
          <AddCrewButton onClick={handleAddDepartment} disabled={!selectedDepartment}>
            Add Department
          </AddCrewButton>
        </AddCrewControls>
      </CrewCallTimesTitle>
      
      {crewCallTimes.length > 0 ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={crewCallTimes.map((crew) => crew.id)}
            strategy={verticalListSortingStrategy}
          >
            {sortedDepartments.map((deptId) => (
              <div key={deptId}>
                <DepartmentHeader>
                  {departments.find(d => d.id === deptId)?.name || 'Unknown Department'}
                </DepartmentHeader>
                <CrewTable>
                  <CrewTableHead>
                    <tr>
                      <th style={{ width: '40px' }}></th>
                      <th>NAME</th>
                      <th>DEPARTMENT</th>
                      <th>POSITION</th>
                      <th style={{ width: '100px' }}>CALL TIME</th>
                      <th>LOCATION</th>
                      <th>NOTES</th>
                      <th style={{ width: '60px' }}></th>
                    </tr>
                  </CrewTableHead>
                  <tbody>
                    {crewByDepartment[deptId].map((crew) => (
                      <SortableCrewRow
                        key={crew.id}
                        crewMember={crew}
                        departments={departments}
                        updateCrewCallTime={updateCrewCallTime}
                        removeCrewMember={removeCrewMember}
                      />
                    ))}
                  </tbody>
                </CrewTable>
              </div>
            ))}
          </SortableContext>
        </DndContext>
      ) : (
        <EmptyCrewMessage>
          No crew members added yet. Use the controls above to add crew members or entire departments.
        </EmptyCrewMessage>
      )}
      
      <WorkflowNote>
        <strong>Note:</strong> These call times will automatically flow into the Crew Times page for tracking wrap times, which will then feed into Production Reports. This is part of the connected three-part workflow system.
      </WorkflowNote>
    </CrewCallTimesContainer>
  );
};

export default VisualCrewCallTimes;
