import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useAuth } from './useAuth';

export const useAccessControl = () => {
  const { user } = useAuth();
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productionAccess, setProductionAccess] = useState(null);
  const [departmentAccess, setDepartmentAccess] = useState(null);

  useEffect(() => {
    const validateAccess = async () => {
      setIsLoading(true);
      setError(null);

      if (!user) {
        setHasAccess(false);
        setIsLoading(false);
        return;
      }

      try {
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();

        if (!userData) {
          setError('User data not found');
          setHasAccess(false);
          setIsLoading(false);
          return;
        }

        // Normalize the role for comparison
        const normalizedUserRole = (userData.role || '').toLowerCase();
        const isSupervisor = normalizedUserRole === 'supervisor';
        const isAdmin = normalizedUserRole === 'admin';

        // Log all user data for debugging
        console.log('User data from Firestore:', userData);
        console.log('Department from user data:', userData.department);
        console.log('Is department exactly "Assistant Directors"?', userData.department === 'Assistant Directors');

        // Supervisors and admins should have access to their production's departments
        if (isAdmin || isSupervisor) {
          console.log('User is admin or supervisor, granting access');
          setHasAccess(true);
          setProductionAccess(userData.production);
          setDepartmentAccess(isAdmin ? 'all' : userData.department);
          console.log('Department access set to:', isAdmin ? 'all' : userData.department);
        }

        // Regular users should have access to their department
        else if (userData.department && userData.production) {
          console.log('Regular user with department and production, granting limited access');
          setHasAccess(true);
          setProductionAccess(userData.production);
          setDepartmentAccess(userData.department);
          console.log('Department access set to:', userData.department);
        } else {
          setError('No department or production assigned');
          setHasAccess(false);
        }

      } catch (error) {
        console.error('Access validation error:', error);
        setError(error.message);
        setHasAccess(false);
      }

      setIsLoading(false);
    };

    validateAccess();
  }, [user]);

  const filterByProduction = (data) => {
    if (!data || !productionAccess) return [];
    return data.filter(item => item.production === productionAccess);
  };

  const filterByDepartment = (data) => {
    if (!data || !departmentAccess) return [];
    if (departmentAccess === 'all') return data;
    return data.filter(item => item.department === departmentAccess);
  };

  const applyFilters = (data) => {
    if (!data) return [];
    let filtered = filterByProduction(data);
    filtered = filterByDepartment(filtered);
    return filtered;
  };

  return {
    hasAccess,
    isLoading,
    error,
    productionAccess,
    departmentAccess,
    filterByProduction,
    filterByDepartment,
    applyFilters
  };
};
