import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc, query, where, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import './ProductionStyles.css';
import '../../styles/SharedStyles.css';

const RentedVehicles = () => {
  const [rentedVehicles, setRentedVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentProduction, setCurrentProduction] = useState('');
  const { user } = useAuth();
  const { hasAccessToPage } = useAccessControl();
  const { hasAccess, departmentAccess } = hasAccessToPage('production-department');

  // Form state for adding new rented vehicle
  const [newVehicle, setNewVehicle] = useState({
    vehicle: '',
    vehicleType: '',
    licensePlate: '',
    state: '',
    color: '',
    assignedTo: '',
    purpose: '',
    startDate: '',
    endDate: '',
    dailyRate: '',
    notes: ''
  });

  // Vehicle type options
  const vehicleTypes = [
    'Car',
    'Van',
    'SUV',
    'Truck',
    'Minivan',
    'Cargo Van',
    'Passenger Van',
    'Other'
  ];

  // State abbreviations
  const stateAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
    'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT',
    'VA', 'WA', 'WV', 'WI', 'WY', 'DC'
  ];

  // Check access control
  useEffect(() => {
    if (hasAccess && departmentAccess === 'Production') {
      fetchData();
    } else {
      setError("You don't have permission to access this page");
      setLoading(false);
    }
  }, [hasAccess, departmentAccess]);

  // Fetch rented vehicles
  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Get current production from user
      if (user?.production) {
        setCurrentProduction(user.production);
      }
      
      // Fetch rented vehicles for current production
      const rentedVehiclesRef = collection(db, 'rentedVehicles');
      let rentedVehiclesQuery;
      
      if (user?.production) {
        rentedVehiclesQuery = query(rentedVehiclesRef, where('production', '==', user.production));
      } else {
        rentedVehiclesQuery = rentedVehiclesRef;
      }
      
      const rentedVehiclesSnapshot = await getDocs(rentedVehiclesQuery);
      const vehiclesList = rentedVehiclesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      console.log('Fetched rented vehicles:', vehiclesList);
      setRentedVehicles(vehiclesList);
      
    } catch (err) {
      console.error('Error fetching rented vehicles data:', err);
      setError('Failed to load rented vehicles data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle adding a new rented vehicle
  const handleAddVehicle = async (e) => {
    e.preventDefault();
    
    if (!user?.production) {
      setToast({
        message: 'You must be assigned to a production to add rented vehicles',
        type: 'error'
      });
      return;
    }
    
    try {
      const vehicleData = {
        ...newVehicle,
        production: user.production,
        department: 'Production', // Set department to Production
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        status: 'active'
      };
      
      await addDoc(collection(db, 'rentedVehicles'), vehicleData);
      
      // Reset form
      setNewVehicle({
        vehicle: '',
        vehicleType: '',
        licensePlate: '',
        state: '',
        color: '',
        assignedTo: '',
        purpose: '',
        startDate: '',
        endDate: '',
        dailyRate: '',
        notes: ''
      });
      
      setToast({
        message: 'Rented vehicle added successfully',
        type: 'success'
      });
      
      // Refresh data
      fetchData();
      
    } catch (err) {
      console.error('Error adding rented vehicle:', err);
      setToast({
        message: 'Failed to add rented vehicle',
        type: 'error'
      });
    }
  };

  // Handle deleting a rented vehicle
  const handleDeleteVehicle = async (vehicleId) => {
    if (window.confirm('Are you sure you want to delete this rented vehicle?')) {
      try {
        await deleteDoc(doc(db, 'rentedVehicles', vehicleId));
        
        setToast({
          message: 'Rented vehicle deleted successfully',
          type: 'success'
        });
        
        // Refresh data
        fetchData();
        
      } catch (err) {
        console.error('Error deleting rented vehicle:', err);
        setToast({
          message: 'Failed to delete rented vehicle',
          type: 'error'
        });
      }
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Filter rented vehicles by search term
  const filteredVehicles = rentedVehicles.filter(vehicle => {
    return searchTerm === '' || 
      vehicle.vehicle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.assignedTo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.purpose?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.licensePlate?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Calculate rental duration and cost
  const calculateRentalInfo = (vehicle) => {
    if (!vehicle.startDate || !vehicle.endDate || !vehicle.dailyRate) return { days: 'N/A', cost: 'N/A' };
    
    const start = new Date(vehicle.startDate);
    const end = new Date(vehicle.endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // Include start and end day
    
    const cost = diffDays * parseFloat(vehicle.dailyRate);
    
    return {
      days: diffDays,
      cost: cost.toFixed(2)
    };
  };

  if (loading) return <div className="loading">Loading rented vehicles...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="production-page rented-vehicles-page">
      <h1>Rented Vehicles</h1>
      
      {toast && (
        <div className={`toast toast-${toast.type}`}>
          {toast.message}
          <button onClick={() => setToast(null)}>×</button>
        </div>
      )}
      
      <div className="page-content">
        <div className="filters-container">
          <div className="filter-group">
            <label htmlFor="searchFilter">Search:</label>
            <input 
              id="searchFilter" 
              type="text" 
              placeholder="Search rented vehicles..." 
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
            />
          </div>
        </div>
        
        <div className="add-vehicle-section">
          <h2>Add New Rented Vehicle</h2>
          <form onSubmit={handleAddVehicle} className="vehicle-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="vehicle">Vehicle (Make/Model)</label>
                <input 
                  type="text" 
                  id="vehicle" 
                  name="vehicle" 
                  value={newVehicle.vehicle} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="vehicleType">Vehicle Type</label>
                <select 
                  id="vehicleType" 
                  name="vehicleType" 
                  value={newVehicle.vehicleType} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                >
                  <option value="">Select Type</option>
                  {vehicleTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="licensePlate">License Plate</label>
                <input 
                  type="text" 
                  id="licensePlate" 
                  name="licensePlate" 
                  value={newVehicle.licensePlate} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select 
                  id="state" 
                  name="state" 
                  value={newVehicle.state} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                >
                  <option value="">Select State</option>
                  {stateAbbreviations.map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label htmlFor="color">Color</label>
                <input 
                  type="text" 
                  id="color" 
                  name="color" 
                  value={newVehicle.color} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="assignedTo">Assigned To</label>
                <input 
                  type="text" 
                  id="assignedTo" 
                  name="assignedTo" 
                  value={newVehicle.assignedTo} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="purpose">Purpose</label>
                <input 
                  type="text" 
                  id="purpose" 
                  name="purpose" 
                  value={newVehicle.purpose} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <input 
                  type="date" 
                  id="startDate" 
                  name="startDate" 
                  value={newVehicle.startDate} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <input 
                  type="date" 
                  id="endDate" 
                  name="endDate" 
                  value={newVehicle.endDate} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="dailyRate">Daily Rate ($)</label>
                <input 
                  type="number" 
                  id="dailyRate" 
                  name="dailyRate" 
                  value={newVehicle.dailyRate} 
                  onChange={handleInputChange}
                  step="0.01"
                  min="0"
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group full-width">
                <label htmlFor="notes">Notes</label>
                <textarea 
                  id="notes" 
                  name="notes" 
                  value={newVehicle.notes} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  rows="3"
                />
              </div>
            </div>
            
            <div className="form-actions">
              <button type="submit" className="btn-primary">
                Add Rented Vehicle
              </button>
            </div>
          </form>
        </div>
        
        <div className="vehicles-list-section">
          <h2>Current Rented Vehicles{currentProduction ? ` - ${currentProduction}` : ''}</h2>
          
          {filteredVehicles.length === 0 ? (
            <div className="no-data">
              <p>No rented vehicles found.</p>
            </div>
          ) : (
            <div className="vehicles-table-container">
              <table className="vehicles-table">
                <thead>
                  <tr>
                    <th>Vehicle</th>
                    <th>License Plate</th>
                    <th>Assigned To</th>
                    <th>Rental Period</th>
                    <th>Cost</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredVehicles.map(vehicle => {
                    const { days, cost } = calculateRentalInfo(vehicle);
                    return (
                      <tr key={vehicle.id}>
                        <td>
                          <div className="vehicle-info">
                            <div className="vehicle-name">{vehicle.vehicle}</div>
                            <div className="vehicle-details">
                              {vehicle.vehicleType}{vehicle.color ? `, ${vehicle.color}` : ''}
                            </div>
                          </div>
                        </td>
                        <td>
                          {vehicle.licensePlate}
                          {vehicle.state && <span> ({vehicle.state})</span>}
                        </td>
                        <td>
                          <div>{vehicle.assignedTo}</div>
                          <div className="rental-purpose">{vehicle.purpose}</div>
                        </td>
                        <td>
                          <div>Start: {vehicle.startDate}</div>
                          <div>End: {vehicle.endDate}</div>
                          <div>Duration: {days} days</div>
                        </td>
                        <td>
                          <div>${vehicle.dailyRate}/day</div>
                          <div className="rental-total">Total: ${cost}</div>
                        </td>
                        <td>
                          <button 
                            onClick={() => handleDeleteVehicle(vehicle.id)}
                            className="btn-danger btn-small"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RentedVehicles;
