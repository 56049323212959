import React, { useState } from 'react';
import styled from 'styled-components';

const Header = styled.div`
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  margin-bottom: 20px;
`;

const ProductionTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
`;

const CallsheetTitle = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
`;

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 15px;
`;

const HeaderSection = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  background-color: ${props => props.primary ? '#f0f6ff' : '#fff'};
`;

const SectionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
  padding-bottom: 3px;
`;

const EditableField = styled.div`
  margin-bottom: 5px;
  
  &:hover {
    background-color: ${props => props.editing ? 'transparent' : '#f9f9f9'};
  }
`;

const FieldLabel = styled.span`
  font-size: 11px;
  color: #666;
  display: block;
`;

const FieldValue = styled.div`
  min-height: 20px;
  padding: 2px;
  border: ${props => props.editing ? '1px solid #4299e1' : '1px solid transparent'};
  
  &:hover {
    cursor: pointer;
  }
`;

const EditableInput = styled.input`
  width: 100%;
  padding: 2px;
  border: 1px solid #4299e1;
  outline: none;
  font-size: inherit;
  font-family: inherit;
`;

const VisualCallsheetHeader = ({ headerData, updateHeaderField }) => {
  const [editing, setEditing] = useState(null);
  
  const handleFieldClick = (field) => {
    setEditing(field);
  };
  
  const handleInputChange = (e, field) => {
    updateHeaderField(field, e.target.value);
  };
  
  const handleInputBlur = () => {
    setEditing(null);
  };
  
  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      setEditing(null);
    }
  };
  
  // Component for an editable field
  const EditableValue = ({ field, label, value, type = 'text' }) => (
    <EditableField editing={editing === field}>
      <FieldLabel>{label}</FieldLabel>
      {editing === field ? (
        <EditableInput
          type={type}
          value={value || ''}
          onChange={(e) => handleInputChange(e, field)}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          autoFocus
        />
      ) : (
        <FieldValue 
          editing={editing === field}
          onClick={() => handleFieldClick(field)}
        >
          {value || 'Click to edit'}
        </FieldValue>
      )}
    </EditableField>
  );
  
  return (
    <Header>
      <ProductionTitle>
        {editing === 'production' ? (
          <EditableInput
            type="text"
            value={headerData.production || ''}
            onChange={(e) => handleInputChange(e, 'production')}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            autoFocus
            placeholder="Enter Production Name"
            style={{ fontSize: '24px', textAlign: 'center', fontWeight: 'bold' }}
          />
        ) : (
          <div onClick={() => handleFieldClick('production')}>
            {headerData.production || 'PRODUCTION TITLE'}
          </div>
        )}
      </ProductionTitle>
      
      <CallsheetTitle>
        CALL SHEET
        {editing === 'day' ? (
          <span style={{ marginLeft: '10px' }}>
            <EditableInput
              type="text"
              value={headerData.day || ''}
              onChange={(e) => handleInputChange(e, 'day')}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              autoFocus
              style={{ width: '30px', textAlign: 'center' }}
            />
          </span>
        ) : (
          <span style={{ marginLeft: '10px' }} onClick={() => handleFieldClick('day')}>
            DAY {headerData.day || '#'}
          </span>
        )} 
        {' | '}
        {editing === 'date' ? (
          <EditableInput
            type="date"
            value={headerData.date || ''}
            onChange={(e) => handleInputChange(e, 'date')}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            autoFocus
            style={{ width: '150px' }}
          />
        ) : (
          <span onClick={() => handleFieldClick('date')}>
            {headerData.date ? new Date(headerData.date).toLocaleDateString() : 'DATE'}
          </span>
        )}
        {' | '}
        {editing === 'version' ? (
          <span>
            VERSION <EditableInput
              type="text"
              value={headerData.version || ''}
              onChange={(e) => handleInputChange(e, 'version')}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              autoFocus
              style={{ width: '30px', textAlign: 'center' }}
            />
          </span>
        ) : (
          <span onClick={() => handleFieldClick('version')}>
            VERSION {headerData.version || '#'}
          </span>
        )}
      </CallsheetTitle>
      
      <HeaderGrid>
        <HeaderSection primary>
          <SectionTitle>CREW CALL</SectionTitle>
          <EditableValue 
            field="mainCallTime" 
            label="MAIN UNIT CALL" 
            value={headerData.mainCallTime} 
            type="time" 
          />
          <EditableValue 
            field="breakfast" 
            label="BREAKFAST" 
            value={headerData.breakfast} 
            type="time" 
          />
          <EditableValue 
            field="lunch" 
            label="LUNCH" 
            value={headerData.lunch} 
            type="time" 
          />
        </HeaderSection>
        
        <HeaderSection>
          <SectionTitle>KEY PERSONNEL</SectionTitle>
          <EditableValue 
            field="director" 
            label="DIRECTOR" 
            value={headerData.director} 
          />
          <EditableValue 
            field="firstAD" 
            label="1ST AD" 
            value={headerData.firstAD} 
          />
          <EditableValue 
            field="secondAD" 
            label="2ND AD" 
            value={headerData.secondAD} 
          />
          <EditableValue 
            field="dop" 
            label="DOP" 
            value={headerData.dop} 
          />
        </HeaderSection>
        
        <HeaderSection>
          <SectionTitle>PRODUCERS</SectionTitle>
          <EditableValue 
            field="producer" 
            label="PRODUCER" 
            value={headerData.producer} 
          />
          <EditableValue 
            field="productionManager" 
            label="PRODUCTION MANAGER" 
            value={headerData.productionManager} 
          />
        </HeaderSection>
        
        <HeaderSection>
          <SectionTitle>TIMING & LOCATION</SectionTitle>
          <EditableValue 
            field="sunriseTime" 
            label="SUNRISE" 
            value={headerData.sunriseTime} 
            type="time" 
          />
          <EditableValue 
            field="sunsetTime" 
            label="SUNSET" 
            value={headerData.sunsetTime} 
            type="time" 
          />
          <EditableValue 
            field="crewParking" 
            label="CREW PARKING" 
            value={headerData.crewParking} 
          />
        </HeaderSection>
      </HeaderGrid>
      
      <HeaderGrid>
        <HeaderSection style={{ gridColumn: '1 / span 2' }}>
          <SectionTitle>SAFETY</SectionTitle>
          <EditableValue 
            field="nearestHospital" 
            label="NEAREST HOSPITAL" 
            value={headerData.nearestHospital} 
          />
          <EditableValue 
            field="safetyNotes" 
            label="SAFETY NOTES" 
            value={headerData.safetyNotes} 
          />
        </HeaderSection>
        
        <HeaderSection style={{ gridColumn: '3 / span 2' }}>
          <SectionTitle>WEATHER</SectionTitle>
          <EditableValue 
            field="weatherInfo" 
            label="FORECAST" 
            value={headerData.weatherInfo} 
          />
        </HeaderSection>
      </HeaderGrid>
    </Header>
  );
};

export default VisualCallsheetHeader;
