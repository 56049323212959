/**
 * departmentRoles.js
 * 
 * This file defines the role structure for different departments in film/TV production.
 * Each department has a list of common roles that can be assigned to crew members.
 * This configuration can be expanded as needed for new departments or roles.
 */

const departmentRoles = {
  'Assistant Directors': [
    '1st Assistant Director',
    '2nd Assistant Director',
    '2nd 2nd Assistant Director',
    'Key Production Assistant',
    'Set Production Assistant',
    'Office Production Assistant',
    'VFX Production Assistant',
    'Trainee Assistant Director'
  ],
  'Transportation': [
    'Transportation Coordinator',
    'Transportation Captain',
    'Driver',
    'Picture Vehicle Coordinator',
    'Picture Vehicle Wrangler',
    'Transportation Production Assistant'
  ],
  'Camera': [
    'Director of Photography',
    'Camera Operator',
    '1st Assistant Camera',
    '2nd Assistant Camera',
    'Digital Imaging Technician',
    'Steadicam Operator',
    'Camera Production Assistant',
    'Video Assist Operator'
  ],
  'G&E': [
    'Gaffer',
    'Best Boy Electric',
    'Electrician',
    'Key Grip',
    'Best Boy Grip',
    'Dolly Grip',
    'Grip',
    'G&E Production Assistant'
  ],
  'Art': [
    'Production Designer',
    'Art Director',
    'Set Decorator',
    'Property Master',
    'Set Dresser',
    'Art Department Coordinator',
    'Graphic Designer',
    'Art Department Production Assistant'
  ],
  'Sound': [
    'Production Sound Mixer',
    'Boom Operator',
    'Sound Utility',
    'Sound Designer',
    'Re-recording Mixer',
    'Foley Artist',
    'Sound Assistant'
  ],
  'Production': [
    'Line Producer',
    'Production Manager',
    'Production Coordinator',
    'Production Secretary',
    'Script Supervisor',
    'Production Accountant',
    'Office Production Assistant'
  ],
  'Locations': [
    'Location Manager',
    'Assistant Location Manager',
    'Location Scout',
    'Location Production Assistant'
  ],
  'Costumes': [
    'Costume Designer',
    'Assistant Costume Designer',
    'Costume Supervisor',
    'Set Costumer',
    'Costume Production Assistant'
  ],
  'Makeup/Hair': [
    'Makeup Department Head',
    'Key Makeup Artist',
    'Makeup Artist',
    'Hair Department Head',
    'Key Hair Stylist',
    'Hair Stylist',
    'Makeup/Hair Production Assistant'
  ],
  'Catering': [
    'Catering Manager',
    'Chef',
    'Assistant Chef',
    'Craft Service',
    'Craft Service Assistant'
  ],
  'VFX': [
    'VFX Supervisor',
    'VFX Producer',
    'Compositor',
    'CG Artist',
    'Rotoscope Artist',
    'VFX Coordinator',
    'VFX Production Assistant'
  ],
  'Stunts': [
    'Stunt Coordinator',
    'Stunt Performer',
    'Fight Choreographer',
    'Stunt Rigger',
    'Stunt Driver'
  ],
  'Special Effects': [
    'Special Effects Supervisor',
    'Special Effects Coordinator',
    'Special Effects Technician',
    'Pyrotechnician',
    'SFX Production Assistant'
  ],
  'Script': [
    'Writer',
    'Script Coordinator',
    'Script Supervisor',
    'Writers Assistant',
    'Script Production Assistant'
  ]
};

export default departmentRoles;
