import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Optional: for custom styles
import logo from '../Assets/Slate-Logo.png'; // Corrected the import path
import heroImage from '../Assets/landingpage/hero image.jpg'; // Updated import path for hero image
import vehicleTrackingImage from '../Assets/landingpage/AI-truck.jpg';
import crewSchedulingImage from '../Assets/landingpage/AI-people.jpg';
import resourceManagementImage from '../Assets/landingpage/AI-camera.jpg';
import WaitlistPopup from '../components/WaitlistPopup';

function LandingPage() {
    const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);

    const handleWaitlistSubmit = (data) => {
        // Here you would typically send this data to your backend
        console.log('Waitlist submission:', data);
        // TODO: Implement backend call to save waitlist data
        setIsWaitlistOpen(false);
        alert('Thank you for joining our waitlist!');
    };

    return (
      <div className="landing-container">
        <header className="landing-header">
          <nav className="top-navbar">
            <div className="logo-title-container">
              <img src={logo} alt="Slate Fusion Logo" className="logo-image" />
              <h1>SlateFusion</h1>
            </div>
            <div className="nav-links">
              <a href="#features" className="nav-link">Features</a>
              <a href="#about" className="nav-link">About</a>
              <Link to="/signup" className="nav-button">Sign Up</Link>
              <Link to="/login" className="nav-button login">Login</Link>
            </div>
          </nav>
        </header>
        <main className="landing-main">
          <section className="hero">
            <div className="hero-content">
              <div className="hero-text">
                <h2>Streamline Your Production Management</h2>
                <p>SlateFusion: Your all-in-one solution for efficient film and TV production management</p>
                <button onClick={() => setIsWaitlistOpen(true)} className="cta-button">Get Started Today</button>
              </div>
              <div className="hero-image">
                <img src={heroImage} alt="Production Management" />
              </div>
            </div>
          </section>
          <h2 className="section-title centered-title">Streamline Your Production with Ease</h2>
          <p className="section-subtitle">Experience seamless production management with our innovative app, designed to streamline workflows and boost team collaboration</p>
          <section className="streamline-section">
            <div className="section-background">
              <div className="feature-tiles">
                <FeatureTile
                  image={vehicleTrackingImage}
                  title="Vehicle Tracking"
                  description="Stay informed with real-time location updates and status reports for your drivers, ensuring smooth logistics and timely arrivals on set."
                />
                <FeatureTile
                  image={resourceManagementImage}
                  title="Resource Management"
                  description="Optimize your production assets with our intuitive system, effortlessly managing equipment, props, and materials to maximize efficiency and minimize costs."
                />
                <FeatureTile
                  image={crewSchedulingImage}
                  title="Crew Scheduling"
                  description="Streamline your workforce management with our advanced scheduling tools, aligning talent availability with production needs for seamless operations."
                />
              </div>
            </div>
          </section>
          <h3 className="section-title centered-title">Key Features</h3>
          <section id="features" className="features">
            <div className="section-background">
              <div className="feature-grid">
                <div className="feature-item">
                  <i className="fas fa-chart-line"></i>
                  <h4>Real-time Updates</h4>
                  <p>Monitor your production's progress and status in real-time</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-users"></i>
                  <h4>Crew Management</h4>
                  <p>Easily manage crew schedules and performance</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-cogs"></i>
                  <h4>Resource Optimization</h4>
                  <p>Optimize resource allocation for efficiency and cost-saving</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-chart-pie"></i>
                  <h4>Reporting & Analytics</h4>
                  <p>Gain insights with comprehensive reporting tools</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-clock"></i>
                  <h4>Time Reporting</h4>
                  <p>Accurate time logs for every project task and milestone</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-truck"></i>
                  <h4>Logistics Management</h4>
                  <p>Optimize your logistics for flawless production runs</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-car"></i>
                  <h4>Vehicle Management</h4>
                  <p>Seamlessly coordinate crew assignments with ease</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-bell"></i>
                  <h4>Timely Alerts</h4>
                  <p>Receive timely alerts for important project milestones</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-lock"></i>
                  <h4>Secure Encryption</h4>
                  <p>Protects your data with top-notch encryption methods</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-wifi"></i>
                  <h4>Seamless Offline</h4>
                  <p>Manage your tasks and projects even when offline</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-share-alt"></i>
                  <h4>Share Efficiently</h4>
                  <p>Easily share project files and updates with your team</p>
                </div>
                <div className="feature-item">
                  <i className="fas fa-comments"></i>
                  <h4>Secure Communication</h4>
                  <p>Efficient and encrypted messaging for seamless team collaboration</p>
                </div>
              </div>
            </div>
          </section>
          <h3 className="section-title centered-title">About SlateFusion</h3>
          <section id="about" className="about">
            <div className="section-background">
              <p>SlateFusion is designed to revolutionize the way film and TV productions are managed. Our platform brings together all aspects of production management, from crew scheduling to resource allocation, in one intuitive interface.</p>
            </div>
          </section>
        </main>
        <footer className="landing-footer">
          <div className="footer-content">
            <div className="footer-section footer-logo-section">
              <img src={logo} alt="Slate Fusion Logo" className="footer-logo" />
              <h2 className="footer-title">SlateFusion</h2>
            </div>
            <div className="footer-columns">
              <div className="footer-column">
                <div className="footer-section">
                  <h4>Company</h4>
                  <ul>
                    <li><Link to="/under-construction">About</Link></li>
                    <li><Link to="/under-construction">Jobs</Link></li>
                  </ul>
                </div>
                <div className="footer-section">
                  <h4>Support</h4>
                  <ul>
                    <li><Link to="/under-construction">FAQs</Link></li>
                    <li><Link to="/under-construction">Contact us</Link></li>
                  </ul>
                </div>
              </div>
              <div className="footer-column">
                <div className="footer-section">
                  <h4>Quick Links</h4>
                  <ul>
                    <li><a href="#features">Features</a></li>
                    <li><a href="#about">About</a></li>
                    <li><Link to="/login">Login</Link></li>
                    <li><Link to="/signup">Sign Up</Link></li>
                    <li><a href="#" onClick={(e) => {
                      e.preventDefault();
                      setIsWaitlistOpen(true);
                    }}>Get Started</a></li>
                  </ul>
                </div>
                <div className="footer-section">
                  <h4>Contact Us</h4>
                  <p>Email: info@slatefusion.com</p>
                  <p>Phone: (123) 456-7890</p>
                </div>
              </div>
            </div>
            <div className="footer-section follow-us-section">
              <div className="follow-us-content">
                <h4>Follow Us</h4>
                <div className="social-icons">
                  <a href="#" className="social-icon" aria-label="Facebook">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" className="social-icon" aria-label="X (Twitter)">
                    <i className="fab fa-x-twitter"></i>
                  </a>
                  <a href="#" className="social-icon" aria-label="Instagram">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-legal">
              <span>Legal</span>
              <Link to="/terms">Terms</Link>
              <Link to="/privacy">Privacy</Link>
            </div>
            <p>&copy; 2024 SlateFusion. All rights reserved.</p>
          </div>
        </footer>
        <WaitlistPopup
          isOpen={isWaitlistOpen}
          onClose={() => setIsWaitlistOpen(false)}
          onSubmit={handleWaitlistSubmit}
        />
      </div>
    );
}

function FeatureTile({ image, title, description }) {
    return (
      <div className="feature-tile">
        <img src={image} alt={title} className="feature-image" />
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    );
}

export default LandingPage;
