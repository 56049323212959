import React, { useState, useRef } from 'react';
import './EditProfilePopup.css';

function EditProfilePopup({ profile, onSave, onCancel }) {
  const [editedProfile, setEditedProfile] = useState({
    ...profile,
    firstName: profile.name ? profile.name.split(' ')[0] : '',
    lastName: profile.name ? profile.name.split(' ').slice(1).join(' ') : '',
    address: profile.address || '',
    state: profile.state || '',
    zipcode: profile.zipcode || '',
    birthDate: profile.birthDate || '',
    lastFiveSSN: profile.lastFiveSSN || '',
    driversLicense: profile.driversLicense || '',
    driversLicenseNumber: profile.driversLicenseNumber || '',
    emergencyContacts: profile.emergencyContacts || [{ name: '', phone: '', email: '', address: '' }],
    vehicles: profile.vehicles.map(vehicle => ({
      ...vehicle,
      purchaseOrders: vehicle.purchaseOrders || ['']
    })),
    position: profile.position || '',
    assignedVehicle: profile.assignedVehicle || ''
  });
  const [previewImage, setPreviewImage] = useState(profile.photoURL || 'default-avatar.png');
  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const digits = value.replace(/\D/g, '');
    let formattedPhone = '';
    if (digits.length <= 3) {
      formattedPhone = `(${digits}`;
    } else if (digits.length <= 6) {
      formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
    setEditedProfile(prev => ({ ...prev, phone: formattedPhone }));
  };

  const handleEmergencyContactChange = (index, field, value) => {
    const updatedContacts = editedProfile.emergencyContacts.map((contact, i) => {
      if (i === index) {
        if (field === 'phone') {
          const digits = value.replace(/\D/g, '');
          let formattedPhone = '';
          if (digits.length <= 3) {
            formattedPhone = `(${digits}`;
          } else if (digits.length <= 6) {
            formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
          } else {
            formattedPhone = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
          }
          return { ...contact, [field]: formattedPhone };
        }
        return { ...contact, [field]: value };
      }
      return contact;
    });
    setEditedProfile(prev => ({ ...prev, emergencyContacts: updatedContacts }));
  };

  const addEmergencyContact = () => {
    setEditedProfile(prev => ({
      ...prev,
      emergencyContacts: [...prev.emergencyContacts, { name: '', phone: '', email: '', address: '' }]
    }));
  };

  const removeEmergencyContact = (index) => {
    setEditedProfile(prev => ({
      ...prev,
      emergencyContacts: prev.emergencyContacts.filter((_, i) => i !== index)
    }));
  };

  const handleSSNChange = (e) => {
    const { value } = e.target;
    // Only allow up to 5 digits
    const ssnValue = value.replace(/\D/g, '').slice(0, 5);
    setEditedProfile(prev => ({ ...prev, lastFiveSSN: ssnValue }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setEditedProfile(prev => ({ ...prev, newPhotoFile: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVehicleChange = (index, field, value) => {
    const updatedVehicles = editedProfile.vehicles.map((vehicle, i) => {
      if (i === index) {
        return { ...vehicle, [field]: value };
      }
      return vehicle;
    });
    setEditedProfile(prev => ({ ...prev, vehicles: updatedVehicles }));
  };

  const addVehicle = () => {
    setEditedProfile(prev => ({
      ...prev,
      vehicles: [...prev.vehicles, { make: '', color: '', plateNumber: '', plateState: '' }]
    }));
  };

  const removeVehicle = (index) => {
    setEditedProfile(prev => ({
      ...prev,
      vehicles: prev.vehicles.filter((_, i) => i !== index)
    }));
  };

  const addPurchaseOrder = (vehicleIndex) => {
    setEditedProfile(prev => {
      const updatedVehicles = [...prev.vehicles];
      updatedVehicles[vehicleIndex].purchaseOrders.push(''); // Add an empty purchase order
      return { ...prev, vehicles: updatedVehicles };
    });
  };

  const removePurchaseOrder = (vehicleIndex, orderIndex) => {
    setEditedProfile(prev => {
      const updatedVehicles = [...prev.vehicles];
      updatedVehicles[vehicleIndex].purchaseOrders.splice(orderIndex, 1); // Remove the specified purchase order
      return { ...prev, vehicles: updatedVehicles };
    });
  };

  const handlePurchaseOrderChange = (vehicleIndex, orderIndex, value) => {
    setEditedProfile(prev => {
      const updatedVehicles = [...prev.vehicles];
      updatedVehicles[vehicleIndex].purchaseOrders[orderIndex] = value; // Update the purchase order
      return { ...prev, vehicles: updatedVehicles };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      if (!editedProfile.firstName || !editedProfile.lastName) {
        throw new Error('First name and last name are required.');
      }

      const updatedProfile = {
        ...editedProfile,
        name: `${editedProfile.firstName} ${editedProfile.lastName}`.trim(),
      };

      console.log("Submitting updated profile:", updatedProfile);
      await onSave(updatedProfile);
      console.log("Profile saved successfully");
    } catch (err) {
      console.error('Error saving profile:', err);
      setError(err.message || 'An error occurred while saving the profile. Please try again.');
    }
  };

  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  return (
    <div className="edit-profile-popup">
      <div className="edit-profile-popup-content">
        <h2>Edit Profile</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="profile-photo-info-group">
            <div className="profile-photo-editor">
              <img 
                src={previewImage} 
                alt="Profile" 
                className="profile-photo-preview" 
                onClick={handleImageClick}
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: 'none' }}
              />
              <p>Click to change photo</p>
            </div>
            <div className="personal-info-group">
              <div className="name-birth-ssn-group">
                <div className="form-group">
                  <label htmlFor="firstName">First Name:</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={editedProfile.firstName || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="birthDate">Birth Date:</label>
                  <input
                    type="date"
                    id="birthDate"
                    name="birthDate"
                    value={editedProfile.birthDate || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name:</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={editedProfile.lastName || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastFiveSSN">Last 5 of SSN:</label>
                  <input
                    type="text"
                    id="lastFiveSSN"
                    name="lastFiveSSN"
                    value={editedProfile.lastFiveSSN || ''}
                    onChange={handleSSNChange}
                    maxLength="5"
                    placeholder="Last 5 digits"
                  />
                </div>
                <div className="drivers-license-group">
                  <div className="form-group">
                    <label htmlFor="driversLicense">Driver's License:</label>
                    <select
                      id="driversLicense"
                      name="driversLicense"
                      value={editedProfile.driversLicense || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select License Type</option>
                      <option value="Class C">Class C</option>
                      <option value="Class B">Class B</option>
                      <option value="Class A">Class A</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="driversLicenseNumber">License Number:</label>
                    <input
                      type="text"
                      id="driversLicenseNumber"
                      name="driversLicenseNumber"
                      value={editedProfile.driversLicenseNumber || ''}
                      onChange={handleChange}
                      placeholder="Enter license number"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="licenseState">State:</label>
                    <select
                      id="licenseState"
                      name="licenseState"
                      value={editedProfile.licenseState || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select State</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="contact-group">
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editedProfile.email || ''}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone:</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={editedProfile.phone || ''}
                    onChange={handlePhoneChange}
                    placeholder="(___) ___-____"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="position">Position:</label>
            <input
              type="text"
              id="position"
              name="position"
              value={editedProfile.position}
              onChange={handleChange}
              placeholder="Enter your position"
            />
          </div>
          <div className="form-group">
            <label htmlFor="assignedVehicle">Assigned Vehicle:</label>
            <input
              type="text"
              id="assignedVehicle"
              name="assignedVehicle"
              value={editedProfile.assignedVehicle}
              onChange={handleChange}
              placeholder="Enter assigned vehicle"
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              name="address"
              value={editedProfile.address || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={editedProfile.city || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group address-group">
            <div>
              <label htmlFor="state">State:</label>
              <select
                id="state"
                name="state"
                value={editedProfile.state || ''}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div>
              <label htmlFor="zipcode">Zipcode:</label>
              <input
                type="text"
                id="zipcode"
                name="zipcode"
                value={editedProfile.zipcode || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="emergency-contacts">
            <h3>Emergency Contacts</h3>
            {editedProfile.emergencyContacts.map((contact, index) => (
              <div key={index} className="emergency-contact">
                <input
                  type="text"
                  placeholder="Name"
                  value={contact.name}
                  onChange={(e) => handleEmergencyContactChange(index, 'name', e.target.value)}
                />
                <input
                  type="tel"
                  placeholder="(___) ___-____"
                  value={contact.phone}
                  onChange={(e) => handleEmergencyContactChange(index, 'phone', e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={contact.email}
                  onChange={(e) => handleEmergencyContactChange(index, 'email', e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={contact.address}
                  onChange={(e) => handleEmergencyContactChange(index, 'address', e.target.value)}
                />
                <button type="button" onClick={() => removeEmergencyContact(index)}>Remove</button>
              </div>
            ))}
            <button type="button" onClick={addEmergencyContact}>Add Emergency Contact</button>
          </div>
          <div className="vehicle-info">
            <h3>Vehicle Information</h3>
            {editedProfile.vehicles.map((vehicle, index) => (
              <div key={index} className="vehicle-fields-group">
                <div className="form-group">
                  <label htmlFor={`vehicleMake-${index}`}>Make:</label>
                  <input
                    type="text"
                    id={`vehicleMake-${index}`}
                    value={vehicle.make}
                    onChange={(e) => handleVehicleChange(index, 'make', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`vehicleColor-${index}`}>Color:</label>
                  <input
                    type="text"
                    id={`vehicleColor-${index}`}
                    value={vehicle.color}
                    onChange={(e) => handleVehicleChange(index, 'color', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`vehiclePlateNumber-${index}`}>Plate Number:</label>
                  <input
                    type="text"
                    id={`vehiclePlateNumber-${index}`}
                    value={vehicle.plateNumber}
                    onChange={(e) => handleVehicleChange(index, 'plateNumber', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`vehiclePlateState-${index}`}>Plate State:</label>
                  <select
                    id={`vehiclePlateState-${index}`}
                    value={vehicle.plateState}
                    onChange={(e) => handleVehicleChange(index, 'plateState', e.target.value)}
                  >
                    <option value="">Select State</option>
                    {states.map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Purchase Orders:</label>
                  {vehicle.purchaseOrders.map((order, orderIndex) => (
                    <div key={orderIndex} className="purchase-order-group">
                      <input
                        type="text"
                        placeholder="Enter Purchase Order Number"
                        value={order}
                        onChange={(e) => handlePurchaseOrderChange(index, orderIndex, e.target.value)}
                      />
                      <button type="button" onClick={() => removePurchaseOrder(index, orderIndex)}>Remove</button>
                    </div>
                  ))}
                  <button type="button" onClick={() => addPurchaseOrder(index)}>Add Purchase Order</button>
                </div>
                {index > 0 && (
                  <button type="button" onClick={() => removeVehicle(index)} className="remove-vehicle-button">
                    Remove Vehicle
                  </button>
                )}
              </div>
            ))}
            <button type="button" onClick={addVehicle} className="add-vehicle-button">
              Add Another Vehicle
            </button>
          </div>
          <div className="button-group">
            <button type="submit" className="save-button">Save</button>
            <button type="button" onClick={onCancel} className="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditProfilePopup;