import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, where, orderBy, doc, updateDoc, deleteDoc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import Sidebar from '../../components/Sidebar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/SharedStyles.css';
import './ADStyles.css';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';

const CallSheets = () => {
  const { user } = useAuth();
  const { hasAccess, productionAccess, departmentAccess } = useAccessControl();
  
  // State for call sheet form
  const [callDate, setCallDate] = useState(new Date());
  const [shootingDate, setShootingDate] = useState(new Date());
  const [generalCallTime, setGeneralCallTime] = useState('');
  const [firstShotTime, setFirstShotTime] = useState('');
  const [schedule, setSchedule] = useState([{ sceneNumber: '', setLocation: '', description: '', pages: '', cast: '' }]);
  const [notes, setNotes] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  
  // State for crew call times
  const [crewCallTimes, setCrewCallTimes] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  
  // State for storing and displaying call sheets
  const [callSheets, setCallSheets] = useState([]);
  const [selectedCallSheet, setSelectedCallSheet] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  
  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // Fetch crew members and existing call sheets on component mount
  useEffect(() => {
    if (hasAccess && departmentAccess === 'Assistant Directors') {
      fetchCrewMembers();
      fetchCallSheets();
    }
  }, [hasAccess, departmentAccess, productionAccess]);
  
  // Fetch crew members from the database
  const fetchCrewMembers = async () => {
    try {
      setLoading(true);
      
      const q = query(
        collection(db, 'crewMembers'),
        where('production', '==', productionAccess)
      );
      
      const snapshot = await getDocs(q);
      const crewData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setCrewMembers(crewData);
      
      // Initialize crew call times
      const initialCrewCallTimes = crewData.map(crew => ({
        crewId: crew.id,
        name: `${crew.firstName} ${crew.lastName}`,
        department: crew.department,
        position: crew.position,
        callTime: generalCallTime
      }));
      
      setCrewCallTimes(initialCrewCallTimes);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching crew members:', error);
      setError('Failed to load crew members. Please try again.');
      setLoading(false);
    }
  };
  
  // Fetch existing call sheets
  const fetchCallSheets = async () => {
    try {
      setLoading(true);
      
      const q = query(
        collection(db, 'callSheets'),
        where('production', '==', productionAccess),
        orderBy('shootingDate', 'desc')
      );
      
      const snapshot = await getDocs(q);
      const callSheetData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        callDate: doc.data().callDate?.toDate(),
        shootingDate: doc.data().shootingDate?.toDate()
      }));
      
      setCallSheets(callSheetData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching call sheets:', error);
      setError('Failed to load call sheets. Please try again.');
      setLoading(false);
    }
  };
  
  // Handle adding a new scene to the schedule
  const handleAddScene = () => {
    setSchedule([...schedule, { sceneNumber: '', setLocation: '', description: '', pages: '', cast: '' }]);
  };
  
  // Handle removing a scene from the schedule
  const handleRemoveScene = (index) => {
    const updatedSchedule = [...schedule];
    updatedSchedule.splice(index, 1);
    setSchedule(updatedSchedule);
  };
  
  // Handle changes to scene inputs
  const handleSceneChange = (index, field, value) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[index][field] = value;
    setSchedule(updatedSchedule);
  };
  
  // Handle changes to crew call times
  const handleCrewCallTimeChange = (crewId, time) => {
    const updatedCallTimes = crewCallTimes.map(crew => 
      crew.crewId === crewId ? { ...crew, callTime: time } : crew
    );
    setCrewCallTimes(updatedCallTimes);
  };
  
  // Handle updating the general call time (applies to all crew by default)
  const handleGeneralCallTimeChange = (time) => {
    setGeneralCallTime(time);
    
    // Update all crew call times
    const updatedCallTimes = crewCallTimes.map(crew => ({
      ...crew,
      callTime: time
    }));
    
    setCrewCallTimes(updatedCallTimes);
  };
  
  // Create or update a call sheet
  const handleSaveCallSheet = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      
      const callSheetData = {
        production: productionAccess,
        callDate: Timestamp.fromDate(callDate),
        shootingDate: Timestamp.fromDate(shootingDate),
        generalCallTime,
        firstShotTime,
        schedule,
        crewCallTimes,
        notes,
        createdBy: user.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      };
      
      if (isEditMode && selectedCallSheet) {
        // Update existing call sheet
        await updateDoc(doc(db, 'callSheets', selectedCallSheet.id), callSheetData);
        setSuccess('Call sheet updated successfully');
      } else {
        // Create new call sheet
        await addDoc(collection(db, 'callSheets'), callSheetData);
        setSuccess('Call sheet created successfully');
      }
      
      // Reset form and fetch updated call sheets
      resetForm();
      fetchCallSheets();
      
    } catch (error) {
      console.error('Error saving call sheet:', error);
      setError('Failed to save call sheet. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Reset the form
  const resetForm = () => {
    setCallDate(new Date());
    setShootingDate(new Date());
    setGeneralCallTime('');
    setFirstShotTime('');
    setSchedule([{ sceneNumber: '', setLocation: '', description: '', pages: '', cast: '' }]);
    setNotes('');
    setIsCreating(false);
    setIsEditMode(false);
    setSelectedCallSheet(null);
    setError(null);
    
    // Clear success message after 3 seconds
    setTimeout(() => {
      setSuccess(null);
    }, 3000);
  };
  
  // Load a call sheet for editing
  const handleEditCallSheet = (callSheet) => {
    setCallDate(callSheet.callDate);
    setShootingDate(callSheet.shootingDate);
    setGeneralCallTime(callSheet.generalCallTime);
    setFirstShotTime(callSheet.firstShotTime);
    setSchedule(callSheet.schedule);
    setNotes(callSheet.notes);
    setCrewCallTimes(callSheet.crewCallTimes);
    setSelectedCallSheet(callSheet);
    setIsEditMode(true);
    setIsCreating(true);
  };
  
  // Delete a call sheet
  const handleDeleteCallSheet = async (id) => {
    if (window.confirm('Are you sure you want to delete this call sheet?')) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, 'callSheets', id));
        setSuccess('Call sheet deleted successfully');
        fetchCallSheets();
      } catch (error) {
        console.error('Error deleting call sheet:', error);
        setError('Failed to delete call sheet. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Export call sheet as PDF
  const exportToPDF = (callSheet) => {
    const doc = new jsPDF();
    
    // Add title
    doc.setFontSize(18);
    doc.text(`Call Sheet - ${format(callSheet.shootingDate, 'MMMM d, yyyy')}`, 14, 22);
    
    // Add production details
    doc.setFontSize(12);
    doc.text(`Production: ${productionAccess}`, 14, 32);
    doc.text(`Call Date: ${format(callSheet.callDate, 'MMMM d, yyyy')}`, 14, 42);
    doc.text(`General Call Time: ${callSheet.generalCallTime}`, 14, 52);
    doc.text(`First Shot: ${callSheet.firstShotTime}`, 14, 62);
    
    // Add schedule
    doc.setFontSize(14);
    doc.text('Shooting Schedule', 14, 82);
    
    const scheduleData = callSheet.schedule.map(scene => [
      scene.sceneNumber,
      scene.setLocation,
      scene.description,
      scene.pages,
      scene.cast
    ]);
    
    doc.autoTable({
      startY: 85,
      head: [['Scene #', 'Set/Location', 'Description', 'Pages', 'Cast']],
      body: scheduleData,
      theme: 'grid',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [66, 153, 225] }
    });
    
    // Add crew call times
    const crewTableY = doc.autoTable.previous.finalY + 15;
    doc.setFontSize(14);
    doc.text('Crew Call Times', 14, crewTableY);
    
    const crewData = callSheet.crewCallTimes.map(crew => [
      crew.name,
      crew.department,
      crew.position,
      crew.callTime
    ]);
    
    doc.autoTable({
      startY: crewTableY + 3,
      head: [['Name', 'Department', 'Position', 'Call Time']],
      body: crewData,
      theme: 'grid',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [66, 153, 225] }
    });
    
    // Add notes if any
    if (callSheet.notes) {
      const notesY = doc.autoTable.previous.finalY + 15;
      doc.setFontSize(14);
      doc.text('Notes', 14, notesY);
      doc.setFontSize(12);
      doc.text(callSheet.notes, 14, notesY + 10);
    }
    
    // Save PDF
    const fileName = `call_sheet_${productionAccess}_${format(callSheet.shootingDate, 'yyyy-MM-dd')}.pdf`;
    doc.save(fileName);
  };
  
  // Export call sheet as Excel
  const exportToExcel = (callSheet) => {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    
    // Format schedule data
    const scheduleData = callSheet.schedule.map(scene => ({
      'Scene #': scene.sceneNumber,
      'Set/Location': scene.setLocation,
      'Description': scene.description,
      'Pages': scene.pages,
      'Cast': scene.cast
    }));
    
    // Format crew call time data
    const crewData = callSheet.crewCallTimes.map(crew => ({
      'Name': crew.name,
      'Department': crew.department,
      'Position': crew.position,
      'Call Time': crew.callTime
    }));
    
    // Create header info sheet
    const headerData = [
      { 'Call Sheet Details': 'Values' },
      { 'Call Sheet Details': `Production: ${productionAccess}` },
      { 'Call Sheet Details': `Call Date: ${format(callSheet.callDate, 'MMMM d, yyyy')}` },
      { 'Call Sheet Details': `Shooting Date: ${format(callSheet.shootingDate, 'MMMM d, yyyy')}` },
      { 'Call Sheet Details': `General Call Time: ${callSheet.generalCallTime}` },
      { 'Call Sheet Details': `First Shot: ${callSheet.firstShotTime}` },
      { 'Call Sheet Details': '' },
      { 'Call Sheet Details': 'Notes:' },
      { 'Call Sheet Details': callSheet.notes || 'No notes' }
    ];
    
    // Add sheets to workbook
    const wsHeader = XLSX.utils.json_to_sheet(headerData);
    const wsSchedule = XLSX.utils.json_to_sheet(scheduleData);
    const wsCrew = XLSX.utils.json_to_sheet(crewData);
    
    XLSX.utils.book_append_sheet(wb, wsHeader, 'Call Sheet Info');
    XLSX.utils.book_append_sheet(wb, wsSchedule, 'Shooting Schedule');
    XLSX.utils.book_append_sheet(wb, wsCrew, 'Crew Call Times');
    
    // Save Excel file
    const fileName = `call_sheet_${productionAccess}_${format(callSheet.shootingDate, 'yyyy-MM-dd')}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };
  
  return (
    <div className="page-container">
      <Sidebar />
      <div className="content">
        <div className="content-wrapper">
          <div className="page-header">
            <h1>Call Sheets</h1>
            <div style={{ 
              padding: '15px', 
              backgroundColor: '#e9f7ef', 
              borderRadius: '8px', 
              marginBottom: '20px', 
              border: '1px solid #2ecc71',
              maxWidth: '600px'
            }}>
              <h3 style={{ color: '#27ae60', marginTop: 0 }}>Try Our New Visual Callsheet Builder!</h3>
              <p>We've developed an enhanced callsheet builder with drag-and-drop functionality, inline editing, and professional PDF export.</p>
              <Link 
                to="/ad/callsheet-builder" 
                style={{ 
                  display: 'inline-block',
                  padding: '10px 20px',
                  backgroundColor: '#2ecc71',
                  color: 'white',
                  textDecoration: 'none',
                  borderRadius: '4px',
                  fontWeight: 'bold'
                }}
              >
                Go to Visual Callsheet Builder →
              </Link>
            </div>
          </div>
          {/* Error and success messages */}
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          
          {!isCreating ? (
            <div className="call-sheets-list">
              <div className="header-actions">
                <h2>Call Sheets</h2>
                <button 
                  className="btn-primary"
                  onClick={() => setIsCreating(true)}
                >
                  Create New Call Sheet
                </button>
              </div>
              
              {loading ? (
                <p>Loading call sheets...</p>
              ) : callSheets.length === 0 ? (
                <p>No call sheets found. Create your first one!</p>
              ) : (
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Shooting Date</th>
                        <th>Call Date</th>
                        <th>General Call</th>
                        <th>First Shot</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {callSheets.map(callSheet => (
                        <tr key={callSheet.id}>
                          <td>{format(callSheet.shootingDate, 'MMM d, yyyy')}</td>
                          <td>{format(callSheet.callDate, 'MMM d, yyyy')}</td>
                          <td>{callSheet.generalCallTime}</td>
                          <td>{callSheet.firstShotTime}</td>
                          <td className="actions">
                            <button 
                              className="btn-small"
                              onClick={() => handleEditCallSheet(callSheet)}
                            >
                              Edit
                            </button>
                            <button 
                              className="btn-small"
                              onClick={() => exportToPDF(callSheet)}
                            >
                              PDF
                            </button>
                            <button 
                              className="btn-small"
                              onClick={() => exportToExcel(callSheet)}
                            >
                              Excel
                            </button>
                            <button 
                              className="btn-small delete"
                              onClick={() => handleDeleteCallSheet(callSheet.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ) : (
            <div className="call-sheet-form">
              <div className="header-actions">
                <h2>{isEditMode ? 'Edit Call Sheet' : 'Create New Call Sheet'}</h2>
                <button 
                  className="btn-secondary"
                  onClick={resetForm}
                >
                  Cancel
                </button>
              </div>
              
              <form onSubmit={handleSaveCallSheet}>
                <div className="form-row">
                  <div className="form-group">
                    <label>Call Date:</label>
                    <DatePicker
                      selected={callDate}
                      onChange={date => setCallDate(date)}
                      className="form-control"
                      dateFormat="MMMM d, yyyy"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Shooting Date:</label>
                    <DatePicker
                      selected={shootingDate}
                      onChange={date => setShootingDate(date)}
                      className="form-control"
                      dateFormat="MMMM d, yyyy"
                      required
                    />
                  </div>
                </div>
                
                <div className="form-row">
                  <div className="form-group">
                    <label>General Call Time:</label>
                    <input
                      type="time"
                      value={generalCallTime}
                      onChange={e => handleGeneralCallTimeChange(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>First Shot:</label>
                    <input
                      type="time"
                      value={firstShotTime}
                      onChange={e => setFirstShotTime(e.target.value)}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                
                <h3 className="section-title">Shooting Schedule</h3>
                
                {schedule.map((scene, index) => (
                  <div key={index} className="schedule-item">
                    <div className="form-row wrap-row">
                      <div className="form-group small">
                        <label>Scene #:</label>
                        <input
                          type="text"
                          value={scene.sceneNumber}
                          onChange={e => handleSceneChange(index, 'sceneNumber', e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group medium">
                        <label>Set/Location:</label>
                        <input
                          type="text"
                          value={scene.setLocation}
                          onChange={e => handleSceneChange(index, 'setLocation', e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group large">
                        <label>Description:</label>
                        <input
                          type="text"
                          value={scene.description}
                          onChange={e => handleSceneChange(index, 'description', e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group small">
                        <label>Pages:</label>
                        <input
                          type="text"
                          value={scene.pages}
                          onChange={e => handleSceneChange(index, 'pages', e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group medium">
                        <label>Cast:</label>
                        <input
                          type="text"
                          value={scene.cast}
                          onChange={e => handleSceneChange(index, 'cast', e.target.value)}
                          className="form-control"
                        />
                      </div>
                      {schedule.length > 1 && (
                        <button
                          type="button"
                          className="btn-icon delete"
                          onClick={() => handleRemoveScene(index)}
                        >
                          ✕
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                
                <div className="form-buttons">
                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={handleAddScene}
                  >
                    Add Scene
                  </button>
                </div>
                
                <h3 className="section-title">Crew Call Times</h3>
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Position</th>
                        <th>Call Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {crewCallTimes.map((crew, index) => (
                        <tr key={index}>
                          <td>{crew.name}</td>
                          <td>{crew.department}</td>
                          <td>{crew.position}</td>
                          <td>
                            <input
                              type="time"
                              value={crew.callTime}
                              onChange={e => handleCrewCallTimeChange(crew.crewId, e.target.value)}
                              className="form-control time-input"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
                <div className="form-group">
                  <label>Notes:</label>
                  <textarea
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                    className="form-control"
                    rows="4"
                  ></textarea>
                </div>
                
                <div className="form-buttons">
                  <button
                    type="submit"
                    className="btn-primary"
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : isEditMode ? 'Update Call Sheet' : 'Create Call Sheet'}
                  </button>
                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={resetForm}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallSheets;
