import React, { useState } from 'react';
import styled from 'styled-components';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SceneScheduleContainer = styled.div`
  margin-bottom: 20px;
`;

const SceneScheduleTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e2e8f0;
  padding: 8px 10px;
`;

const AddSceneButton = styled.button`
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 14px;
  cursor: pointer;
  
  &:hover {
    background-color: #3182ce;
  }
`;

const SceneTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 14px;
`;

const SceneTableHead = styled.thead`
  background-color: #f8fafc;
  
  th {
    padding: 8px 10px;
    text-align: left;
    font-weight: bold;
    border: 1px solid #cbd5e0;
  }
`;

const EmptySceneMessage = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #f8fafc;
  border: 1px dashed #cbd5e0;
  color: #718096;
  margin-top: 10px;
`;

// Editable cell component
const EditableCell = ({ value, onChange, type = 'text', width = 'auto' }) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  
  const handleClick = () => {
    setEditing(true);
  };
  
  const handleChange = (e) => {
    setCurrentValue(e.target.value);
  };
  
  const handleBlur = () => {
    setEditing(false);
    onChange(currentValue);
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setEditing(false);
      onChange(currentValue);
    }
  };
  
  // Update local state if props change
  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  
  return (
    <td 
      onClick={!editing ? handleClick : undefined}
      style={{ 
        cursor: editing ? 'default' : 'pointer',
        border: '1px solid #cbd5e0', 
        padding: '8px 10px',
        width
      }}
    >
      {editing ? (
        <input
          type={type}
          value={currentValue || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          style={{
            width: '100%',
            border: 'none',
            outline: 'none',
            background: 'transparent',
            fontFamily: 'inherit',
            fontSize: 'inherit'
          }}
        />
      ) : (
        <div style={{ minHeight: '18px' }}>
          {value || <span style={{ color: '#a0aec0', fontStyle: 'italic' }}>Click to edit</span>}
        </div>
      )}
    </td>
  );
};

// Draggable scene row component
const SortableSceneRow = ({ scene, updateScene, removeScene }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: scene.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    position: 'relative',
    zIndex: isDragging ? 999 : 'auto',
  };
  
  const handleUpdate = (field, value) => {
    updateScene(scene.id, field, value);
  };
  
  return (
    <tr ref={setNodeRef} style={style}>
      <td 
        {...attributes} 
        {...listeners}
        style={{ 
          cursor: 'grab', 
          width: '40px',
          textAlign: 'center',
          backgroundColor: '#f1f5f9',
          border: '1px solid #cbd5e0'
        }}
      >
        ☰
      </td>
      <EditableCell
        value={scene.sceneNumber}
        onChange={(value) => handleUpdate('sceneNumber', value)}
        width="80px"
      />
      <EditableCell
        value={scene.setLocation}
        onChange={(value) => handleUpdate('setLocation', value)}
      />
      <EditableCell
        value={scene.description}
        onChange={(value) => handleUpdate('description', value)}
      />
      <EditableCell
        value={scene.cast}
        onChange={(value) => handleUpdate('cast', value)}
      />
      <EditableCell
        value={scene.pages}
        onChange={(value) => handleUpdate('pages', value)}
        width="80px"
      />
      <EditableCell
        value={scene.startTime}
        onChange={(value) => handleUpdate('startTime', value)}
        type="time"
        width="100px"
      />
      <EditableCell
        value={scene.notes}
        onChange={(value) => handleUpdate('notes', value)}
      />
      <td 
        style={{ 
          width: '60px', 
          textAlign: 'center',
          border: '1px solid #cbd5e0'
        }}
      >
        <button 
          onClick={() => removeScene(scene.id)}
          style={{
            background: '#fc8181',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '4px 8px',
            cursor: 'pointer',
            fontSize: '12px'
          }}
        >
          Remove
        </button>
      </td>
    </tr>
  );
};

const VisualSceneSchedule = ({ scenes, addScene, updateScene, removeScene, reorderScenes }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  
  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      const oldIndex = scenes.findIndex((scene) => scene.id === active.id);
      const newIndex = scenes.findIndex((scene) => scene.id === over.id);
      
      reorderScenes(oldIndex, newIndex);
    }
  };
  
  return (
    <SceneScheduleContainer>
      <SceneScheduleTitle>
        <span>SCENE SCHEDULE</span>
        <AddSceneButton onClick={addScene}>
          + Add Scene
        </AddSceneButton>
      </SceneScheduleTitle>
      
      {scenes.length > 0 ? (
        <SceneTable>
          <SceneTableHead>
            <tr>
              <th style={{ width: '40px' }}></th>
              <th style={{ width: '80px' }}>SCENE #</th>
              <th>SET / LOCATION</th>
              <th>DESCRIPTION</th>
              <th>CAST</th>
              <th style={{ width: '80px' }}>PAGES</th>
              <th style={{ width: '100px' }}>START TIME</th>
              <th>NOTES</th>
              <th style={{ width: '60px' }}></th>
            </tr>
          </SceneTableHead>
          <tbody>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={scenes.map((scene) => scene.id)}
                strategy={verticalListSortingStrategy}
              >
                {scenes.map((scene) => (
                  <SortableSceneRow
                    key={scene.id}
                    scene={scene}
                    updateScene={updateScene}
                    removeScene={removeScene}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </tbody>
        </SceneTable>
      ) : (
        <EmptySceneMessage>
          No scenes added yet. Click "Add Scene" to start building your schedule.
        </EmptySceneMessage>
      )}
    </SceneScheduleContainer>
  );
};

export default VisualSceneSchedule;
