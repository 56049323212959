import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../hooks/useAuth';
import departmentRoles from '../utils/departmentRoles';
import '../styles/SharedStyles.css';

/**
 * RoleManager component for assigning and managing production-specific roles for crew members.
 * This component allows for assigning different roles to crew members for specific productions,
 * enabling a single user to have different roles across different productions.
 */
function RoleManager({ crewMember, onRoleUpdated }) {
  const userId = crewMember.id;
  const userName = crewMember.name;
  const currentDepartment = crewMember.department;
  const currentRole = crewMember.role;
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [productions, setProductions] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState(currentDepartment || '');
  const [selectedRole, setSelectedRole] = useState(currentRole || '');
  const [userRoles, setUserRoles] = useState([]);
  const { user } = useAuth();

  // Fetch the productions when component mounts
  useEffect(() => {
    fetchProductions();
    fetchUserRoles();
  }, [userId]);

  // Fetch available productions
  const fetchProductions = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'productions'));
      const productionList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name
      }));
      setProductions(productionList);
    } catch (error) {
      console.error('Error fetching productions:', error);
      setError('Failed to fetch productions. Please try again.');
    }
  };

  // Fetch the user's current roles across productions
  const fetchUserRoles = async () => {
    setLoading(true);
    try {
      const rolesRef = collection(db, 'productionRoles');
      const q = query(rolesRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      
      const roles = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setUserRoles(roles);
    } catch (error) {
      console.error('Error fetching user roles:', error);
      setError('Failed to fetch user roles. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle production selection
  const handleProductionChange = (e) => {
    setSelectedProduction(e.target.value);
    
    // Check if user already has a role in this production
    const existingRole = userRoles.find(role => role.productionId === e.target.value);
    if (existingRole) {
      setSelectedDepartment(existingRole.department || '');
      setSelectedRole(existingRole.role || '');
    } else {
      setSelectedDepartment(currentDepartment || '');
      setSelectedRole(currentRole || '');
    }
  };

  // Handle department selection
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setSelectedRole(''); // Reset role when department changes
  };

  // Handle role selection
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  // Assign role to user
  const assignRole = async (e) => {
    e.preventDefault();
    
    if (!selectedProduction || !selectedDepartment || !selectedRole) {
      setError('Please select a production, department, and role.');
      return;
    }
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Check if user already has a role in this production
      const existingRole = userRoles.find(role => role.productionId === selectedProduction);
      
      if (existingRole) {
        // Update existing role
        await updateDoc(doc(db, 'productionRoles', existingRole.id), {
          department: selectedDepartment,
          role: selectedRole,
          updatedAt: serverTimestamp(),
          updatedBy: user.uid
        });
        
        // Add to history
        await addDoc(collection(db, 'roleHistory'), {
          userId,
          userName,
          productionId: selectedProduction,
          productionName: productions.find(p => p.id === selectedProduction)?.name || 'Unknown Production',
          previousDepartment: existingRole.department,
          previousRole: existingRole.role,
          newDepartment: selectedDepartment,
          newRole: selectedRole,
          changedAt: serverTimestamp(),
          changedBy: user.uid
        });
        
        setSuccess(`Updated role for ${userName} in this production.`);
      } else {
        // Create new role
        await addDoc(collection(db, 'productionRoles'), {
          userId,
          userName,
          productionId: selectedProduction,
          productionName: productions.find(p => p.id === selectedProduction)?.name || 'Unknown Production',
          department: selectedDepartment,
          role: selectedRole,
          startDate: serverTimestamp(),
          createdAt: serverTimestamp(),
          createdBy: user.uid
        });
        
        setSuccess(`Assigned new role for ${userName} in this production.`);
      }
      
      // Refresh user roles
      fetchUserRoles();
      
      // Call the callback function if provided
      if (onRoleUpdated) {
        onRoleUpdated(userId, {
          department: selectedDepartment,
          role: selectedRole,
          production: selectedProduction
        });
      }
    } catch (error) {
      console.error('Error assigning role:', error);
      setError('Failed to assign role. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Get departments array from the departmentRoles object
  const departmentsList = Object.keys(departmentRoles);
  
  // Get roles for the selected department
  const rolesForDepartment = selectedDepartment ? departmentRoles[selectedDepartment] || [] : [];

  return (
    <div className="role-manager">
      <h3 className="section-title">Role Assignment for {userName}</h3>
      
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      
      <form onSubmit={assignRole}>
        <div className="form-group">
          <label>Production:</label>
          <select
            value={selectedProduction}
            onChange={handleProductionChange}
            className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
            required
          >
            <option value="">Select Production</option>
            {productions.map(production => (
              <option key={production.id} value={production.id}>
                {production.name}
              </option>
            ))}
          </select>
        </div>
        
        <div className="form-group">
          <label>Department:</label>
          <select
            value={selectedDepartment}
            onChange={handleDepartmentChange}
            className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
            required
          >
            <option value="">Select Department</option>
            {departmentsList.map(dept => (
              <option key={dept} value={dept}>
                {dept}
              </option>
            ))}
          </select>
        </div>
        
        {selectedDepartment && (
          <div className="form-group">
            <label>Role:</label>
            <select
              value={selectedRole}
              onChange={handleRoleChange}
              className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
              required
            >
              <option value="">Select Role</option>
              {rolesForDepartment.map(role => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
        )}
        
        <button
          type="submit"
          className="btn-primary"
          disabled={loading || !selectedProduction || !selectedDepartment || !selectedRole}
        >
          {loading ? 'Assigning...' : 'Assign Role'}
        </button>
      </form>
      
      {userRoles.length > 0 && (
        <div className="user-roles-section">
          <h4>Current Roles</h4>
          <ul className="role-list">
            {userRoles.map(role => (
              <li key={role.id} className="role-item">
                <strong>{role.productionName || 'Unknown Production'}</strong>
                <span>{role.department} - {role.role}</span>
                <span className="role-date">Assigned: {role.startDate ? new Date(role.startDate.toDate()).toLocaleDateString() : 'Unknown'}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default RoleManager;
