import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export default function WalkieTalkieModel() {
  // Create the component to mount the Three.js scene
  return (
    <div id="walkie-container" style={{ width: '100%', height: '400px', position: 'relative' }}>
      <canvas id="walkie-canvas" style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

// This function initializes and returns the 3D walkie model
export function initWalkieModel(batteryStatus = 'Full', channel = 6, modelUrl = null) {
  // Set up the scene, camera, and renderer
  const container = document.getElementById('walkie-container');
  const canvas = document.getElementById('walkie-canvas');
  
  if (!container || !canvas) {
    console.error('Required DOM elements not found');
    return () => {};
  }
  
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xf0f8ff); // Light blue background matching user preference
  
  const camera = new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 0.1, 1000);
  camera.position.set(0, 0, 15);
  
  const renderer = new THREE.WebGLRenderer({ 
    canvas: canvas,
    antialias: true,
    alpha: true
  });
  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  
  // Add ambient light
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
  scene.add(ambientLight);
  
  // Add directional lights for better shadows and highlights
  const frontLight = new THREE.DirectionalLight(0xffffff, 0.8);
  frontLight.position.set(5, 5, 5);
  frontLight.castShadow = true;
  scene.add(frontLight);
  
  const backLight = new THREE.DirectionalLight(0xffffff, 0.3);
  backLight.position.set(-5, 5, -5);
  scene.add(backLight);
  
  const topLight = new THREE.DirectionalLight(0xffffff, 0.5);
  topLight.position.set(0, 10, 0);
  scene.add(topLight);
  
  // Create materials
  const blackPlasticMaterial = new THREE.MeshPhongMaterial({
    color: 0x151515,
    shininess: 30,
    specular: 0x333333
  });
  
  const darkGrayMaterial = new THREE.MeshPhongMaterial({
    color: 0x222222,
    shininess: 20,
    specular: 0x555555
  });
  
  const buttonMaterial = new THREE.MeshPhongMaterial({
    color: 0x111111,
    shininess: 50,
    specular: 0x777777
  });
  
  // Create the walkie body
  const walkieGroup = new THREE.Group();
  
  // If a model URL is provided, load that model instead of creating one
  if (modelUrl) {
    // Create a GLTF loader
    const loader = new GLTFLoader();
    
    // Load the model
    loader.load(
      modelUrl,
      function (gltf) {
        // Successfully loaded the model
        const model = gltf.scene;
        
        // Scale and position the model appropriately
        model.scale.set(5, 5, 5);
        model.rotation.y = Math.PI / 4;
        
        // Add the model to the scene
        walkieGroup.add(model);
        scene.add(walkieGroup);
      },
      function (xhr) {
        // Progress callback
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      },
      function (error) {
        // Error callback
        console.error('An error happened loading the model:', error);
        
        // Fallback to built-in model if loading fails
        const builtInModel = createWalkieModel(blackPlasticMaterial, darkGrayMaterial, buttonMaterial, batteryStatus, channel);
        scene.add(builtInModel);
      }
    );
  } else {
    // Use built-in model
    const builtInModel = createWalkieModel(blackPlasticMaterial, darkGrayMaterial, buttonMaterial, batteryStatus, channel);
    scene.add(builtInModel);
  }
  
  // Set up OrbitControls
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.1;
  controls.rotateSpeed = 0.5;
  controls.enableZoom = true;
  controls.minDistance = 10;
  controls.maxDistance = 30;
  
  // Auto-rotation
  let isRotating = true;
  window.toggleWalkieRotation = () => {
    isRotating = !isRotating;
    return isRotating;
  };
  
  // Handle window resize
  const handleResize = () => {
    if (!container) return;
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(container.clientWidth, container.clientHeight);
  };
  
  window.addEventListener('resize', handleResize);
  
  // Animation loop
  let animationId;
  function animate() {
    animationId = requestAnimationFrame(animate);
    
    if (isRotating) {
      walkieGroup.rotation.y += 0.01;
    }
    
    controls.update();
    renderer.render(scene, camera);
  }
  
  animate();
  
  // Battery status update function
  window.updateWalkieBatteryStatus = (status) => {
    if (walkieGroup) {
      scene.remove(walkieGroup);
      const newWalkieModel = createWalkieModel(blackPlasticMaterial, darkGrayMaterial, buttonMaterial, status, channel);
      scene.add(newWalkieModel);
      walkieGroup = newWalkieModel;
    }
  };
  
  // Channel update function
  window.updateWalkieChannel = (newChannel) => {
    if (walkieGroup) {
      scene.remove(walkieGroup);
      const newWalkieModel = createWalkieModel(blackPlasticMaterial, darkGrayMaterial, buttonMaterial, batteryStatus, newChannel);
      scene.add(newWalkieModel);
      walkieGroup = newWalkieModel;
    }
  };
  
  // Return cleanup function
  return () => {
    window.removeEventListener('resize', handleResize);
    controls.dispose();
    
    if (animationId) {
      cancelAnimationFrame(animationId);
    }
    
    // Dispose geometries and materials
    scene.traverse((object) => {
      if (object instanceof THREE.Mesh) {
        if (object.geometry) {
          object.geometry.dispose();
        }
        
        if (object.material) {
          // Handle material arrays
          if (Array.isArray(object.material)) {
            object.material.forEach(material => {
              if (material && typeof material.dispose === 'function') {
                // Clean up material properties before disposal
                if (material.map) material.map.dispose();
                if (material.bumpMap) material.bumpMap.dispose();
                if (material.normalMap) material.normalMap.dispose();
                if (material.specularMap) material.specularMap.dispose();
                if (material.envMap) material.envMap.dispose();
                material.dispose();
              }
            });
          } 
          // Handle single materials
          else {
            const material = object.material;
            if (material && typeof material.dispose === 'function') {
              // Clean up material properties before disposal
              if (material.map) material.map.dispose();
              if (material.bumpMap) material.bumpMap.dispose();
              if (material.normalMap) material.normalMap.dispose();
              if (material.specularMap) material.specularMap.dispose();
              if (material.envMap) material.envMap.dispose();
              material.dispose();
            }
          }
        }
      }
    });
    
    renderer.dispose();
    
    // Delete custom global functions
    delete window.toggleWalkieRotation;
    delete window.updateWalkieBatteryStatus;
    delete window.updateWalkieChannel;
  };
}

// Function to create the walkie model based on the images provided
function createWalkieModel(mainMaterial, detailMaterial, buttonMaterial, batteryStatus, channel) {
  const walkieGroup = new THREE.Group();
  
  // Create a sleek, minimalist walkie model matching the provided images
  
  // Main body - simple black rectangular body with proper proportions
  const bodyGeometry = new THREE.BoxGeometry(2.0, 7, 0.7, 1, 1, 1);
  const bodyMaterial = new THREE.MeshStandardMaterial({ 
    color: 0x0a0a0a, 
    roughness: 0.8,
    metalness: 0.1
  });
  const body = new THREE.Mesh(bodyGeometry, bodyMaterial);
  body.position.set(0, 0, 0);
  body.castShadow = true;
  body.receiveShadow = true;
  walkieGroup.add(body);
  
  // Small bump on top for antenna connection
  const connectorGeometry = new THREE.BoxGeometry(0.4, 0.2, 0.4);
  const connector = new THREE.Mesh(connectorGeometry, bodyMaterial);
  connector.position.set(-0.2, 3.5, 0);
  walkieGroup.add(connector);
  
  // Antenna - thin and straight at the top (slightly off-center as in image)
  const antennaGeometry = new THREE.CylinderGeometry(0.05, 0.05, 3.5, 8, 1);
  const antennaMaterial = new THREE.MeshStandardMaterial({ color: 0x000000 });
  const antenna = new THREE.Mesh(antennaGeometry, antennaMaterial);
  antenna.position.set(-0.2, 5.3, 0);
  antenna.castShadow = true;
  walkieGroup.add(antenna);
  
  // Antenna cap
  const antennaCapGeometry = new THREE.CylinderGeometry(0.1, 0.1, 0.15, 8, 1);
  const antennaCapMaterial = new THREE.MeshStandardMaterial({ color: 0x222222 });
  const antennaCap = new THREE.Mesh(antennaCapGeometry, antennaCapMaterial);
  antennaCap.position.set(-0.2, 3.6, 0);
  walkieGroup.add(antennaCap);
  
  // Display screen - green indicator at the top as shown in the image
  const displayGeometry = new THREE.PlaneGeometry(0.6, 0.2);
  
  // Create screen material based on battery status
  const screenMaterial = new THREE.MeshBasicMaterial({
    color: getBatteryColor(batteryStatus)
  });
  
  const display = new THREE.Mesh(displayGeometry, screenMaterial);
  display.position.set(0, 2.4, 0.36);
  walkieGroup.add(display);
  
  // Speaker grille - horizontal gray bar
  const grilleGeometry = new THREE.PlaneGeometry(1.2, 0.3);
  const grilleMaterial = new THREE.MeshStandardMaterial({ 
    color: 0x5a5a5a,
    roughness: 0.8
  });
  const grille = new THREE.Mesh(grilleGeometry, grilleMaterial);
  grille.position.set(0, -1.0, 0.36);
  walkieGroup.add(grille);
  
  // Side button/indentation
  const sideButtonGeometry = new THREE.BoxGeometry(0.1, 0.7, 0.2);
  const sideButtonMaterial = new THREE.MeshStandardMaterial({ color: 0x222222 });
  const sideButton = new THREE.Mesh(sideButtonGeometry, sideButtonMaterial);
  sideButton.position.set(1.0, 1.0, 0);
  walkieGroup.add(sideButton);
  
  // Rotate the entire model to match the image
  walkieGroup.rotation.y = Math.PI / 4;
  
  return walkieGroup;
}

// Helper function to get battery color based on status
function getBatteryColor(status) {
  switch (status) {
    case 'Full': return 0x00ff00; // Green
    case 'Medium': return 0x88ff00; // Yellow-green
    case 'Low': return 0xffcc00; // Orange
    case 'Empty': return 0xff0000; // Red
    default: return 0x00ff00; // Default green
  }
}

// Function to create display content on the screen
function createDisplayContent(screen, color, batteryStatus, channel) {
  // For this minimalist model, we'll just use the color of the display to indicate battery status
  // No need for detailed text or graphics
}
