import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import { Link } from 'react-router-dom';
import './ProductionStyles.css';
import '../../styles/SharedStyles.css';

const ProductionManagement = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productionData, setProductionData] = useState({});
  const { user } = useAuth();
  const { hasAccessToPage } = useAccessControl();
  const { hasAccess, departmentAccess } = hasAccessToPage('production-department');

  useEffect(() => {
    if (hasAccess && departmentAccess === 'Production') {
      fetchProductionData();
    } else {
      setError("You don't have permission to access this page");
      setLoading(false);
    }
  }, [hasAccess, departmentAccess]);

  const fetchProductionData = async () => {
    try {
      setLoading(true);
      
      if (!user?.production) {
        setError("You are not assigned to a production");
        setLoading(false);
        return;
      }
      
      // Fetch production information
      // Here we would fetch detailed information about the current production
      // For now, we'll just set up the structure

      // Example data structure:
      const productionInfo = {
        title: user.production,
        rentedVehicles: [],
        locations: [],
        schedule: {},
        budget: {}
      };
      
      // Fetch rented vehicles
      const rentedVehiclesRef = collection(db, 'rentedVehicles');
      const rentedVehiclesQuery = query(
        rentedVehiclesRef, 
        where('production', '==', user.production),
        where('department', '==', 'Production')
      );
      
      const rentedVehiclesSnapshot = await getDocs(rentedVehiclesQuery);
      productionInfo.rentedVehicles = rentedVehiclesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setProductionData(productionInfo);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching production data:', err);
      setError('Failed to load production data');
      setLoading(false);
    }
  };

  if (loading) return <div className="loading">Loading production management...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="production-page">
      <h1>Production Management</h1>
      
      <div className="page-content">
        <div className="dashboard-section">
          <h2>Production Dashboard</h2>
          <p>Managing: {productionData.title}</p>
          
          <div className="quick-stats">
            <div className="stat-card">
              <h3>Rented Vehicles</h3>
              <div className="stat-value">{productionData.rentedVehicles.length}</div>
              <Link to="/production/rented-vehicles" className="stat-link">Manage Rentals</Link>
            </div>
            
            <div className="stat-card">
              <h3>Locations</h3>
              <div className="stat-value">{productionData.locations.length}</div>
              <Link to="/production/locations" className="stat-link">View Locations</Link>
            </div>
            
            <div className="stat-card">
              <h3>Budget</h3>
              <div className="stat-value">Coming Soon</div>
              <Link to="/production/budget" className="stat-link">Budget Overview</Link>
            </div>
            
            <div className="stat-card">
              <h3>Schedule</h3>
              <div className="stat-value">Coming Soon</div>
              <Link to="/production/schedule" className="stat-link">View Schedule</Link>
            </div>
          </div>
        </div>
        
        <div className="info-section">
          <h2>Production Department Features</h2>
          <div className="features-grid">
            <div className="feature-card">
              <h3>Rented Vehicles</h3>
              <p>Manage all vehicles rented for the production. Track rental periods, costs, and assignees.</p>
              <Link to="/production/rented-vehicles" className="feature-link">Manage Rentals</Link>
            </div>
            
            <div className="feature-card">
              <h3>Locations Management</h3>
              <p>Track all production locations, including addresses, contacts, and scheduling.</p>
              <Link to="/production/locations" className="feature-link">Manage Locations</Link>
            </div>
            
            <div className="feature-card">
              <h3>Production Budget</h3>
              <p>Track and manage the production budget across departments.</p>
              <Link to="/production/budget" className="feature-link">View Budget</Link>
            </div>
            
            <div className="feature-card">
              <h3>Production Schedule</h3>
              <p>Manage the production schedule, call sheets, and daily reports.</p>
              <Link to="/production/schedule" className="feature-link">Manage Schedule</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionManagement;
