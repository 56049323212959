import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, where, orderBy, doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import Sidebar from '../../components/Sidebar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/SharedStyles.css';
import './ADStyles.css';
import { format } from 'date-fns';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const SceneScheduling = () => {
  const { user } = useAuth();
  const { hasAccess, productionAccess, departmentAccess } = useAccessControl();
  
  // States for scene schedule management
  const [scenes, setScenes] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // States for current schedule being edited
  const [isCreating, setIsCreating] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [scheduleName, setScheduleName] = useState('');
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [scheduledScenes, setScheduledScenes] = useState([]);
  const [unscheduledScenes, setUnscheduledScenes] = useState([]);
  
  // States for scene form
  const [showSceneForm, setShowSceneForm] = useState(false);
  const [currentScene, setCurrentScene] = useState({
    sceneNumber: '',
    location: '',
    setting: 'INT', // INT or EXT
    timeOfDay: 'DAY', // DAY or NIGHT
    description: '',
    pages: '',
    cast: '',
    props: '',
    notes: '',
    status: 'Not Scheduled'
  });
  
  // Fetch scenes and schedules on component mount
  useEffect(() => {
    if (hasAccess && departmentAccess === 'Assistant Directors') {
      fetchScenes();
      fetchSchedules();
    }
  }, [hasAccess, departmentAccess, productionAccess]);
  
  // Fetch scenes from Firebase
  const fetchScenes = async () => {
    try {
      setLoading(true);
      
      const q = query(
        collection(db, 'scenes'),
        where('production', '==', productionAccess),
        orderBy('sceneNumber', 'asc')
      );
      
      const snapshot = await getDocs(q);
      const scenesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setScenes(scenesData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching scenes:', error);
      setError('Failed to load scenes. Please try again.');
      setLoading(false);
    }
  };
  
  // Fetch schedules from Firebase
  const fetchSchedules = async () => {
    try {
      setLoading(true);
      
      const q = query(
        collection(db, 'sceneSchedules'),
        where('production', '==', productionAccess),
        orderBy('date', 'desc')
      );
      
      const snapshot = await getDocs(q);
      const schedulesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate()
      }));
      
      setSchedules(schedulesData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching schedules:', error);
      setError('Failed to load schedules. Please try again.');
      setLoading(false);
    }
  };
  
  // Handle scene form input changes
  const handleSceneInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentScene({ ...currentScene, [name]: value });
  };
  
  // Save a new scene
  const handleSaveScene = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      
      const sceneData = {
        ...currentScene,
        production: productionAccess,
        createdBy: user.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      };
      
      if (currentScene.id) {
        // Update existing scene
        await updateDoc(doc(db, 'scenes', currentScene.id), sceneData);
        setSuccess('Scene updated successfully');
      } else {
        // Create new scene
        await addDoc(collection(db, 'scenes'), sceneData);
        setSuccess('Scene created successfully');
      }
      
      // Reset form and fetch updated scenes
      setCurrentScene({
        sceneNumber: '',
        location: '',
        setting: 'INT',
        timeOfDay: 'DAY',
        description: '',
        pages: '',
        cast: '',
        props: '',
        notes: '',
        status: 'Not Scheduled'
      });
      setShowSceneForm(false);
      fetchScenes();
      
    } catch (error) {
      console.error('Error saving scene:', error);
      setError('Failed to save scene. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Edit an existing scene
  const handleEditScene = (scene) => {
    setCurrentScene(scene);
    setShowSceneForm(true);
  };
  
  // Delete a scene
  const handleDeleteScene = async (id) => {
    if (window.confirm('Are you sure you want to delete this scene?')) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, 'scenes', id));
        setSuccess('Scene deleted successfully');
        fetchScenes();
      } catch (error) {
        console.error('Error deleting scene:', error);
        setError('Failed to delete scene. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Start creating a new schedule
  const startCreateSchedule = () => {
    setIsCreating(true);
    setIsEditMode(false);
    setScheduleName('');
    setScheduleDate(new Date());
    
    // Initialize unscheduled scenes from the scenes list
    setUnscheduledScenes([...scenes]);
    setScheduledScenes([]);
  };
  
  // Edit an existing schedule
  const handleEditSchedule = (schedule) => {
    setIsCreating(true);
    setIsEditMode(true);
    setSelectedSchedule(schedule);
    setScheduleName(schedule.name);
    setScheduleDate(schedule.date);
    
    // Find which scenes are in this schedule
    const scheduledSceneIds = schedule.scenes.map(scene => scene.id);
    
    // Split scenes into scheduled and unscheduled
    const scheduled = [];
    const unscheduled = [];
    
    scenes.forEach(scene => {
      const scheduledScene = schedule.scenes.find(s => s.id === scene.id);
      
      if (scheduledScene) {
        scheduled.push(scheduledScene);
      } else {
        unscheduled.push(scene);
      }
    });
    
    setScheduledScenes(scheduled);
    setUnscheduledScenes(unscheduled);
  };
  
  // Save the schedule to Firebase
  const handleSaveSchedule = async (e) => {
    e.preventDefault();
    
    if (!scheduleName.trim()) {
      setError('Please enter a schedule name');
      return;
    }
    
    try {
      setLoading(true);
      
      const scheduleData = {
        name: scheduleName,
        date: Timestamp.fromDate(scheduleDate),
        production: productionAccess,
        scenes: scheduledScenes,
        createdBy: user.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      };
      
      if (isEditMode && selectedSchedule) {
        // Update existing schedule
        await updateDoc(doc(db, 'sceneSchedules', selectedSchedule.id), scheduleData);
        setSuccess('Schedule updated successfully');
      } else {
        // Create new schedule
        await addDoc(collection(db, 'sceneSchedules'), scheduleData);
        setSuccess('Schedule created successfully');
      }
      
      // Reset form and fetch updated schedules
      resetScheduleForm();
      fetchSchedules();
      
      // Update scene statuses
      await updateSceneStatuses();
      
    } catch (error) {
      console.error('Error saving schedule:', error);
      setError('Failed to save schedule. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Update the statuses of scenes based on schedules
  const updateSceneStatuses = async () => {
    try {
      // For each scene that was scheduled, update its status
      for (const scene of scheduledScenes) {
        await updateDoc(doc(db, 'scenes', scene.id), {
          status: 'Scheduled',
          updatedAt: Timestamp.now()
        });
      }
      
      // Fetch updated scenes
      fetchScenes();
    } catch (error) {
      console.error('Error updating scene statuses:', error);
    }
  };
  
  // Reset the schedule form
  const resetScheduleForm = () => {
    setIsCreating(false);
    setIsEditMode(false);
    setSelectedSchedule(null);
    setScheduleName('');
    setScheduleDate(new Date());
    setScheduledScenes([]);
    setUnscheduledScenes([]);
    setError(null);
    
    // Clear success message after 3 seconds
    setTimeout(() => {
      setSuccess(null);
    }, 3000);
  };
  
  // Delete a schedule
  const handleDeleteSchedule = async (id) => {
    if (window.confirm('Are you sure you want to delete this schedule?')) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, 'sceneSchedules', id));
        setSuccess('Schedule deleted successfully');
        fetchSchedules();
      } catch (error) {
        console.error('Error deleting schedule:', error);
        setError('Failed to delete schedule. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Handle drag and drop
  const handleDragEnd = (result) => {
    const { source, destination } = result;
    
    // Dropped outside a droppable area
    if (!destination) return;
    
    // Moving within the same list
    if (source.droppableId === destination.droppableId) {
      
      const list = source.droppableId === 'scheduled' 
        ? [...scheduledScenes] 
        : [...unscheduledScenes];
      
      const [movedItem] = list.splice(source.index, 1);
      list.splice(destination.index, 0, movedItem);
      
      if (source.droppableId === 'scheduled') {
        setScheduledScenes(list);
      } else {
        setUnscheduledScenes(list);
      }
      
    } else {
      // Moving from one list to another
      const sourceList = source.droppableId === 'scheduled' 
        ? [...scheduledScenes] 
        : [...unscheduledScenes];
      
      const destList = destination.droppableId === 'scheduled' 
        ? [...scheduledScenes] 
        : [...unscheduledScenes];
      
      const [movedItem] = sourceList.splice(source.index, 1);
      destList.splice(destination.index, 0, movedItem);
      
      if (source.droppableId === 'scheduled') {
        setScheduledScenes(sourceList);
        setUnscheduledScenes(destList);
      } else {
        setScheduledScenes(destList);
        setUnscheduledScenes(sourceList);
      }
    }
  };
  
  return (
    <div className="page-container">
      <Sidebar />
      <div className="content">
        <h1 className="page-title">Scene Scheduling</h1>
        
        {/* Error and success messages */}
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        
        {!isCreating && !showSceneForm ? (
          <div className="scenes-schedules-container">
            {/* Scenes Management */}
            <div className="scenes-list">
              <div className="header-actions">
                <h2>Scenes</h2>
                <button 
                  className="btn-primary"
                  onClick={() => setShowSceneForm(true)}
                >
                  Add New Scene
                </button>
              </div>
              
              {loading && scenes.length === 0 ? (
                <p>Loading scenes...</p>
              ) : scenes.length === 0 ? (
                <p>No scenes found. Create your first scene!</p>
              ) : (
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Scene #</th>
                        <th>Setting</th>
                        <th>Location</th>
                        <th>Description</th>
                        <th>Pages</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scenes.map(scene => (
                        <tr key={scene.id} className={scene.status === 'Not Scheduled' ? 'prep-row' : 'shoot-row'}>
                          <td>{scene.sceneNumber}</td>
                          <td>{scene.setting} / {scene.timeOfDay}</td>
                          <td>{scene.location}</td>
                          <td>{scene.description}</td>
                          <td>{scene.pages}</td>
                          <td>{scene.status}</td>
                          <td className="actions">
                            <button 
                              className="btn-small"
                              onClick={() => handleEditScene(scene)}
                            >
                              Edit
                            </button>
                            <button 
                              className="btn-small delete"
                              onClick={() => handleDeleteScene(scene.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            
            {/* Schedules Management */}
            <div className="schedules-list">
              <div className="header-actions">
                <h2>Schedules</h2>
                <button 
                  className="btn-primary"
                  onClick={startCreateSchedule}
                >
                  Create New Schedule
                </button>
              </div>
              
              {loading && schedules.length === 0 ? (
                <p>Loading schedules...</p>
              ) : schedules.length === 0 ? (
                <p>No schedules found. Create your first schedule!</p>
              ) : (
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Scenes</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedules.map(schedule => (
                        <tr key={schedule.id}>
                          <td>{schedule.name}</td>
                          <td>{format(schedule.date, 'MMM d, yyyy')}</td>
                          <td>{schedule.scenes.length} scenes</td>
                          <td className="actions">
                            <button 
                              className="btn-small"
                              onClick={() => handleEditSchedule(schedule)}
                            >
                              Edit
                            </button>
                            <button 
                              className="btn-small delete"
                              onClick={() => handleDeleteSchedule(schedule.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        ) : showSceneForm ? (
          /* Scene Form */
          <div className="scene-form">
            <div className="header-actions">
              <h2>{currentScene.id ? 'Edit Scene' : 'Add New Scene'}</h2>
              <button 
                className="btn-secondary"
                onClick={() => {
                  setShowSceneForm(false);
                  setCurrentScene({
                    sceneNumber: '',
                    location: '',
                    setting: 'INT',
                    timeOfDay: 'DAY',
                    description: '',
                    pages: '',
                    cast: '',
                    props: '',
                    notes: '',
                    status: 'Not Scheduled'
                  });
                }}
              >
                Cancel
              </button>
            </div>
            
            <form onSubmit={handleSaveScene}>
              <div className="form-row">
                <div className="form-group small">
                  <label>Scene Number:</label>
                  <input
                    type="text"
                    name="sceneNumber"
                    value={currentScene.sceneNumber}
                    onChange={handleSceneInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group medium">
                  <label>Location:</label>
                  <input
                    type="text"
                    name="location"
                    value={currentScene.location}
                    onChange={handleSceneInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group small">
                  <label>Setting:</label>
                  <select
                    name="setting"
                    value={currentScene.setting}
                    onChange={handleSceneInputChange}
                    className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  >
                    <option value="INT">INT</option>
                    <option value="EXT">EXT</option>
                    <option value="INT/EXT">INT/EXT</option>
                  </select>
                </div>
                <div className="form-group small">
                  <label>Time of Day:</label>
                  <select
                    name="timeOfDay"
                    value={currentScene.timeOfDay}
                    onChange={handleSceneInputChange}
                    className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  >
                    <option value="DAY">DAY</option>
                    <option value="NIGHT">NIGHT</option>
                    <option value="DAWN">DAWN</option>
                    <option value="DUSK">DUSK</option>
                  </select>
                </div>
              </div>
              
              <div className="form-group">
                <label>Description:</label>
                <textarea
                  name="description"
                  value={currentScene.description}
                  onChange={handleSceneInputChange}
                  className="form-control"
                  rows="2"
                  required
                ></textarea>
              </div>
              
              <div className="form-row">
                <div className="form-group small">
                  <label>Pages:</label>
                  <input
                    type="text"
                    name="pages"
                    value={currentScene.pages}
                    onChange={handleSceneInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Cast:</label>
                  <input
                    type="text"
                    name="cast"
                    value={currentScene.cast}
                    onChange={handleSceneInputChange}
                    className="form-control"
                    placeholder="Characters in scene"
                  />
                </div>
              </div>
              
              <div className="form-group">
                <label>Props:</label>
                <textarea
                  name="props"
                  value={currentScene.props}
                  onChange={handleSceneInputChange}
                  className="form-control"
                  rows="2"
                  placeholder="Props needed for this scene"
                ></textarea>
              </div>
              
              <div className="form-group">
                <label>Notes:</label>
                <textarea
                  name="notes"
                  value={currentScene.notes}
                  onChange={handleSceneInputChange}
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Saving...' : currentScene.id ? 'Update Scene' : 'Create Scene'}
                </button>
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={() => {
                    setShowSceneForm(false);
                    setCurrentScene({
                      sceneNumber: '',
                      location: '',
                      setting: 'INT',
                      timeOfDay: 'DAY',
                      description: '',
                      pages: '',
                      cast: '',
                      props: '',
                      notes: '',
                      status: 'Not Scheduled'
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        ) : (
          /* Schedule Creation/Editing Form */
          <div className="schedule-form">
            <div className="header-actions">
              <h2>{isEditMode ? 'Edit Schedule' : 'Create New Schedule'}</h2>
              <button 
                className="btn-secondary"
                onClick={resetScheduleForm}
              >
                Cancel
              </button>
            </div>
            
            <form onSubmit={handleSaveSchedule}>
              <div className="form-row">
                <div className="form-group">
                  <label>Schedule Name:</label>
                  <input
                    type="text"
                    value={scheduleName}
                    onChange={e => setScheduleName(e.target.value)}
                    className="form-control"
                    placeholder="e.g. Day 1 Schedule"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Date:</label>
                  <DatePicker
                    selected={scheduleDate}
                    onChange={date => setScheduleDate(date)}
                    className="form-control"
                    dateFormat="MMMM d, yyyy"
                    required
                  />
                </div>
              </div>
              
              <div className="drag-drop-container">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <div className="scene-columns">
                    {/* Unscheduled Scenes */}
                    <div className="scene-column">
                      <h3 className="column-title">Unscheduled Scenes</h3>
                      <Droppable droppableId="unscheduled">
                        {(provided) => (
                          <div
                            className="droppable-area unscheduled-area"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {unscheduledScenes.length === 0 ? (
                              <p className="empty-message">No unscheduled scenes</p>
                            ) : (
                              unscheduledScenes.map((scene, index) => (
                                <Draggable key={scene.id} draggableId={scene.id} index={index}>
                                  {(provided) => (
                                    <div
                                      className="draggable-item prep-row"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="scene-card">
                                        <div className="scene-number">{scene.sceneNumber}</div>
                                        <div className="scene-details">
                                          <div className="scene-location">
                                            {scene.setting} / {scene.timeOfDay} - {scene.location}
                                          </div>
                                          <div className="scene-description">{scene.description}</div>
                                          <div className="scene-meta">
                                            {scene.pages} pages | {scene.cast || 'No cast specified'}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                    
                    {/* Scheduled Scenes */}
                    <div className="scene-column">
                      <h3 className="column-title">Scheduled Scenes</h3>
                      <Droppable droppableId="scheduled">
                        {(provided) => (
                          <div
                            className="droppable-area scheduled-area"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {scheduledScenes.length === 0 ? (
                              <p className="empty-message">Drag scenes here to schedule them</p>
                            ) : (
                              scheduledScenes.map((scene, index) => (
                                <Draggable key={scene.id} draggableId={scene.id} index={index}>
                                  {(provided) => (
                                    <div
                                      className="draggable-item shoot-row"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="scene-card">
                                        <div className="scene-number">{scene.sceneNumber}</div>
                                        <div className="scene-details">
                                          <div className="scene-location">
                                            {scene.setting} / {scene.timeOfDay} - {scene.location}
                                          </div>
                                          <div className="scene-description">{scene.description}</div>
                                          <div className="scene-meta">
                                            {scene.pages} pages | {scene.cast || 'No cast specified'}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                </DragDropContext>
              </div>
              
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Saving...' : isEditMode ? 'Update Schedule' : 'Create Schedule'}
                </button>
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={resetScheduleForm}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default SceneScheduling;
