import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { initWalkieModel } from './WalkieTalkieModel';
import './WalkieModel3D.css';

const WalkieModel3D = ({ batteryStatus = 'Full', walkies = [], onSelectWalkie }) => {
  const containerRef = useRef(null);
  const cleanupRef = useRef(null);
  const [selectedWalkie, setSelectedWalkie] = useState(null);
  const [isRotating, setIsRotating] = useState(true);
  
  // Function to handle walkie selection
  const handleSelectWalkie = (walkie) => {
    setSelectedWalkie(walkie);
    if (onSelectWalkie) {
      onSelectWalkie(walkie);
    }
  };

  // Use the batteryStatus from the selected walkie if available
  const displayBatteryStatus = selectedWalkie ? selectedWalkie.batteryStatus : batteryStatus;

  useEffect(() => {
    // Clean up previous model if it exists
    if (cleanupRef.current) {
      cleanupRef.current();
      cleanupRef.current = null;
    }

    // Wait for the next render cycle to ensure the DOM elements are ready
    const timer = setTimeout(() => {
      // Initialize new model with the current battery status
      const cleanup = initWalkieModel(displayBatteryStatus);
      cleanupRef.current = cleanup;

      // Set up rotation toggle function for UI interaction
      if (window.toggleWalkieRotation) {
        const originalToggle = window.toggleWalkieRotation;
        window.toggleWalkieRotation = () => {
          const isRotating = originalToggle();
          setIsRotating(isRotating);
          return isRotating;
        };
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [displayBatteryStatus]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current();
      }
    };
  }, []);

  return (
    <div className="walkie-model-container">
      <div id="walkie-container" style={{ width: '100%', height: '100%', position: 'relative' }}>
        <canvas id="walkie-canvas" style={{ width: '100%', height: '100%' }} />
      </div>
      
      <div className="walkie-model-controls">
        <button 
          onClick={() => window.toggleWalkieRotation && window.toggleWalkieRotation()}
          className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
        >
          {isRotating ? 'Pause Rotation' : 'Resume Rotation'}
        </button>
        
        {walkies.length > 0 && (
          <select 
            className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
            value={selectedWalkie ? selectedWalkie.id : ''}
            onChange={(e) => {
              const selected = walkies.find(w => w.id === e.target.value);
              handleSelectWalkie(selected || null);
            }}
          >
            <option value="">Select a walkie-talkie</option>
            {walkies.map(walkie => (
              <option key={walkie.id} value={walkie.id}>
                Walkie #{walkie.walkieNumber} - {walkie.status}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default WalkieModel3D;
