import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc, query, where, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import './ADStyles.css';
import '../../styles/SharedStyles.css';

const ProductionRentals = () => {
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentProduction, setCurrentProduction] = useState('');
  const { user } = useAuth();
  const { hasAccessToPage } = useAccessControl();
  const { hasAccess, departmentAccess } = hasAccessToPage('ad-department');

  // Form state for adding new rental
  const [newRental, setNewRental] = useState({
    vehicle: '',
    vehicleType: '',
    licensePlate: '',
    state: '',
    color: '',
    department: '',
    assignedTo: '',
    purpose: '',
    startDate: '',
    endDate: '',
    dailyRate: '',
    notes: ''
  });

  // Vehicle type options
  const vehicleTypes = [
    'Car',
    'Van',
    'SUV',
    'Truck',
    'Minivan',
    'Cargo Van',
    'Passenger Van',
    'Other'
  ];

  // State abbreviations
  const stateAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA',
    'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT',
    'VA', 'WA', 'WV', 'WI', 'WY', 'DC'
  ];

  // Check access control
  useEffect(() => {
    if (hasAccess && departmentAccess === 'Assistant Directors') {
      fetchData();
    } else {
      setError("You don't have permission to access this page");
      setLoading(false);
    }
  }, [hasAccess, departmentAccess]);

  // Fetch production rentals and departments
  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Get current production from user
      if (user?.production) {
        setCurrentProduction(user.production);
      }
      
      // Fetch departments
      const deptsRef = collection(db, 'departments');
      const deptsSnapshot = await getDocs(deptsRef);
      const departmentsList = deptsSnapshot.docs.map(doc => doc.data().name);
      setDepartments(['All Departments', ...departmentsList]);
      
      // Fetch production rentals for current production
      const rentalsRef = collection(db, 'productionRentals');
      let rentalsQuery;
      
      if (user?.production) {
        rentalsQuery = query(rentalsRef, where('production', '==', user.production));
      } else {
        rentalsQuery = rentalsRef;
      }
      
      const rentalsSnapshot = await getDocs(rentalsQuery);
      const rentalsList = rentalsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      console.log('Fetched rentals:', rentalsList);
      setRentals(rentalsList);
      
    } catch (err) {
      console.error('Error fetching rentals data:', err);
      setError('Failed to load rentals data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle adding a new rental
  const handleAddRental = async (e) => {
    e.preventDefault();
    
    if (!user?.production) {
      setToast({
        message: 'You must be assigned to a production to add rentals',
        type: 'error'
      });
      return;
    }
    
    try {
      const rentalData = {
        ...newRental,
        production: user.production,
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        status: 'active'
      };
      
      await addDoc(collection(db, 'productionRentals'), rentalData);
      
      // Reset form
      setNewRental({
        vehicle: '',
        vehicleType: '',
        licensePlate: '',
        state: '',
        color: '',
        department: '',
        assignedTo: '',
        purpose: '',
        startDate: '',
        endDate: '',
        dailyRate: '',
        notes: ''
      });
      
      setToast({
        message: 'Rental added successfully',
        type: 'success'
      });
      
      // Refresh data
      fetchData();
      
    } catch (err) {
      console.error('Error adding rental:', err);
      setToast({
        message: 'Failed to add rental',
        type: 'error'
      });
    }
  };

  // Handle deleting a rental
  const handleDeleteRental = async (rentalId) => {
    if (window.confirm('Are you sure you want to delete this rental?')) {
      try {
        await deleteDoc(doc(db, 'productionRentals', rentalId));
        
        setToast({
          message: 'Rental deleted successfully',
          type: 'success'
        });
        
        // Refresh data
        fetchData();
        
      } catch (err) {
        console.error('Error deleting rental:', err);
        setToast({
          message: 'Failed to delete rental',
          type: 'error'
        });
      }
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRental(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Filter rentals by department and search term
  const filteredRentals = rentals.filter(rental => {
    const departmentMatch = selectedDepartment === 'all' || rental.department === selectedDepartment;
    const searchMatch = searchTerm === '' || 
      rental.vehicle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      rental.assignedTo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      rental.purpose?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      rental.licensePlate?.toLowerCase().includes(searchTerm.toLowerCase());
    
    return departmentMatch && searchMatch;
  });

  // Calculate rental duration and cost
  const calculateRentalInfo = (rental) => {
    if (!rental.startDate || !rental.endDate || !rental.dailyRate) return { days: 'N/A', cost: 'N/A' };
    
    const start = new Date(rental.startDate);
    const end = new Date(rental.endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // Include start and end day
    
    const cost = diffDays * parseFloat(rental.dailyRate);
    
    return {
      days: diffDays,
      cost: cost.toFixed(2)
    };
  };

  if (loading) return <div className="loading">Loading production rentals...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="ad-page production-rentals-page">
      <h1>Production Rentals</h1>
      
      {toast && (
        <div className={`toast toast-${toast.type}`}>
          {toast.message}
          <button onClick={() => setToast(null)}>×</button>
        </div>
      )}
      
      <div className="page-content">
        <div className="filters-container">
          <div className="filter-group">
            <label htmlFor="departmentFilter">Department:</label>
            <select 
              id="departmentFilter" 
              value={selectedDepartment} 
              onChange={(e) => setSelectedDepartment(e.target.value)}
              className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
            >
              <option value="all">All Departments</option>
              {departments.filter(d => d !== 'All Departments').map(dept => (
                <option key={dept} value={dept}>{dept}</option>
              ))}
            </select>
          </div>
          
          <div className="filter-group">
            <label htmlFor="searchFilter">Search:</label>
            <input 
              id="searchFilter" 
              type="text" 
              placeholder="Search rentals..." 
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
            />
          </div>
        </div>
        
        <div className="add-rental-section">
          <h2>Add New Rental</h2>
          <form onSubmit={handleAddRental} className="rental-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="vehicle">Vehicle (Make/Model)</label>
                <input 
                  type="text" 
                  id="vehicle" 
                  name="vehicle" 
                  value={newRental.vehicle} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="vehicleType">Vehicle Type</label>
                <select 
                  id="vehicleType" 
                  name="vehicleType" 
                  value={newRental.vehicleType} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                >
                  <option value="">Select Type</option>
                  {vehicleTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="licensePlate">License Plate</label>
                <input 
                  type="text" 
                  id="licensePlate" 
                  name="licensePlate" 
                  value={newRental.licensePlate} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select 
                  id="state" 
                  name="state" 
                  value={newRental.state} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                >
                  <option value="">Select State</option>
                  {stateAbbreviations.map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label htmlFor="color">Color</label>
                <input 
                  type="text" 
                  id="color" 
                  name="color" 
                  value={newRental.color} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="department">Department</label>
                <select 
                  id="department" 
                  name="department" 
                  value={newRental.department} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                >
                  <option value="">Select Department</option>
                  {departments.filter(d => d !== 'All Departments').map(dept => (
                    <option key={dept} value={dept}>{dept}</option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label htmlFor="assignedTo">Assigned To</label>
                <input 
                  type="text" 
                  id="assignedTo" 
                  name="assignedTo" 
                  value={newRental.assignedTo} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="purpose">Purpose</label>
                <input 
                  type="text" 
                  id="purpose" 
                  name="purpose" 
                  value={newRental.purpose} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <input 
                  type="date" 
                  id="startDate" 
                  name="startDate" 
                  value={newRental.startDate} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <input 
                  type="date" 
                  id="endDate" 
                  name="endDate" 
                  value={newRental.endDate} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="dailyRate">Daily Rate ($)</label>
                <input 
                  type="number" 
                  id="dailyRate" 
                  name="dailyRate" 
                  value={newRental.dailyRate} 
                  onChange={handleInputChange}
                  step="0.01"
                  min="0"
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  required
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group full-width">
                <label htmlFor="notes">Notes</label>
                <textarea 
                  id="notes" 
                  name="notes" 
                  value={newRental.notes} 
                  onChange={handleInputChange}
                  className="form-control bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
                  rows="3"
                />
              </div>
            </div>
            
            <div className="form-actions">
              <button type="submit" className="btn-primary">
                Add Rental
              </button>
            </div>
          </form>
        </div>
        
        <div className="rentals-list-section">
          <h2>Current Rentals{currentProduction ? ` - ${currentProduction}` : ''}</h2>
          
          {filteredRentals.length === 0 ? (
            <div className="no-data">
              <p>No production rentals found for the selected criteria.</p>
            </div>
          ) : (
            <div className="rentals-table-container">
              <table className="rentals-table">
                <thead>
                  <tr>
                    <th>Vehicle</th>
                    <th>License Plate</th>
                    <th>Department</th>
                    <th>Assigned To</th>
                    <th>Rental Period</th>
                    <th>Cost</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRentals.map(rental => {
                    const { days, cost } = calculateRentalInfo(rental);
                    return (
                      <tr key={rental.id}>
                        <td>
                          <div className="vehicle-info">
                            <div className="vehicle-name">{rental.vehicle}</div>
                            <div className="vehicle-details">
                              {rental.vehicleType}{rental.color ? `, ${rental.color}` : ''}
                            </div>
                          </div>
                        </td>
                        <td>
                          {rental.licensePlate}
                          {rental.state && <span> ({rental.state})</span>}
                        </td>
                        <td>{rental.department}</td>
                        <td>
                          <div>{rental.assignedTo}</div>
                          <div className="rental-purpose">{rental.purpose}</div>
                        </td>
                        <td>
                          <div>Start: {rental.startDate}</div>
                          <div>End: {rental.endDate}</div>
                          <div>Duration: {days} days</div>
                        </td>
                        <td>
                          <div>${rental.dailyRate}/day</div>
                          <div className="rental-total">Total: ${cost}</div>
                        </td>
                        <td>
                          <button 
                            onClick={() => handleDeleteRental(rental.id)}
                            className="btn-danger btn-small"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductionRentals;
