import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from './contexts/AuthContext'; //Import AuthProvider and AuthContext
import { auth, db } from './firebase';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from './hooks/useAuth';
import WelcomePage from './pages/WelcomePage';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Productions from './pages/Productions';
import ProductionDetails from './pages/ProductionDetails';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Reports from './pages/Reports';
import CrewMembers from './pages/CrewMembers';
import Departments from './pages/Departments';
import Times from './pages/Times';
import Dispatch from './pages/Dispatch';
import Notifications from './pages/Notifications';
import Messages from './pages/Messages';
import Profile from './pages/Profile';
import Vehicles from './pages/crewVehicles';
import UserRoleManager from './components/UserRoleManager';
import TimeSheet from './components/TimeSheet';
import AddNotification from './components/AddNotification';
import AddVehicle from './components/AddVehicle';
import SuperAdminRoute from './components/SuperAdminRoute';
import VehiclesPage from './pages/VehiclesPage';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import './App.css';
import HamburgerMenu from './components/HamburgerMenu';
import ProfileCompletion from './pages/ProfileCompletion';
import MealReceipts from './pages/MealReceipts';
import ReceiptViewer from './components/ReceiptViewer';
import PCardReceipts from './pages/PCardReceipts';
import UnderConstruction from './pages/UnderConstruction';
import Budget from './pages/Budget';
import OfflineStatus from './components/OfflineStatus';
import Management from './pages/Management';
import CreateProduction from './pages/CreateProduction';
import { Toaster } from 'react-hot-toast';
import UserManagement from './components/UserManagement';
import { CallSheets, SceneScheduling, ProductionReports, WalkieManagement, CrewVehicles, CrewTimes, CallsheetBuilder, ProductionRentals } from './pages/ad-department';
import { RentedVehicles, ProductionManagement, LocationsManagement, ProductionBudgets, ProductionSchedule } from './pages/production-department';
import TestLinks from './pages/ad-department/TestLinks';
import ProtectedRoute from './components/ProtectedRoute';

function AppContent() {
  const { user, loading, signOut } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const location = useLocation();
  const navigate = useNavigate();
  const visibleLinks = ['productions', 'crew-members', 'vehicles', 'reports', 'messages'];
  const newNotificationsCount = 0;

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const publicRoutes = ['/', '/login', '/signup'];
  const shouldShowSidebar = user && !publicRoutes.includes(location.pathname);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="app-container">
      <OfflineStatus />
      {user && (
        <div className="navbar-container">
          <Navbar toggleSidebar={toggleSidebar} />
        </div>
      )}
      {user && shouldShowSidebar && window.innerWidth >= 768 && <Sidebar isOpen={isSidebarOpen} />}
      {user && shouldShowSidebar && window.innerWidth < 768 && (
        <HamburgerMenu
          isOpen={isSidebarOpen}
          visibleLinks={visibleLinks}
          newNotificationsCount={newNotificationsCount}
          toggleSidebar={toggleSidebar}
        />
      )}
      <div className={`main-content ${shouldShowSidebar ? 'sidebar-open' : ''}`}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/signup" element={<Signup />} />

          {/* Protected Routes */}
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/productions" element={<Productions />} />
          <Route path="/create-production" element={<CreateProduction />} />
          <Route path="/productions/:productionId" element={<ProductionDetails />} />
          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/crew-members" element={<CrewMembers />} />
          <Route path="/departments" element={<Departments />} />
          <Route path="/times" element={<Times />} />
          <Route path="/dispatch" element={<Dispatch />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/vehicles" element={<Vehicles />} />
          <Route path="/vehicles-page" element={<VehiclesPage />} />
         
          {user && user.role === 'admin' && (
            <Route path="/manage-roles" element={<UserRoleManager />} />
          )}
         
          <Route path="/timesheet" element={<TimeSheet />} />
          <Route path="/add-notification" element={<AddNotification />} />
          <Route path="/add-vehicle" element={<AddVehicle />} />
          <Route path="/complete-profile" element={<ProfileCompletion />} />
          <Route path="/meal-receipts" element={<MealReceipts />} />
          <Route path="/view-receipts" element={<ReceiptViewer />} />
          <Route path="/pcard-receipts" element={<PCardReceipts />} />
          <Route path="/under-construction" element={<UnderConstruction />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="/management" element={<Management />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/user-role-manager" element={<UserRoleManager />} />
          
          {/* AD Department Routes */}
          <Route path="/ad/call-sheets" element={<ProtectedRoute><CallSheets /></ProtectedRoute>} />
          <Route path="/ad/scene-scheduling" element={<ProtectedRoute><SceneScheduling /></ProtectedRoute>} />
          <Route path="/ad/production-reports" element={<ProtectedRoute><ProductionReports /></ProtectedRoute>} />
          <Route path="/ad/walkie-management" element={<ProtectedRoute><WalkieManagement /></ProtectedRoute>} />
          <Route path="/ad/crew-vehicles" element={<ProtectedRoute><CrewVehicles /></ProtectedRoute>} />
          <Route path="/ad/crew-times" element={<ProtectedRoute><CrewTimes /></ProtectedRoute>} />
          <Route path="/ad/callsheet-builder" element={<ProtectedRoute><CallsheetBuilder /></ProtectedRoute>} />
          <Route path="/ad/test-links" element={<ProtectedRoute><TestLinks /></ProtectedRoute>} />
          <Route path="/ad/production-rentals" element={<ProtectedRoute><ProductionRentals /></ProtectedRoute>} />
          <Route path="/ad/rented-vehicles" element={<ProtectedRoute><RentedVehicles /></ProtectedRoute>} />
          
          {/* Production Department Routes */}
          <Route path="/production/dashboard" element={<ProtectedRoute><ProductionManagement /></ProtectedRoute>} />
          <Route path="/production/rented-vehicles" element={<ProtectedRoute><RentedVehicles /></ProtectedRoute>} />
          <Route path="/production/locations" element={<ProtectedRoute><LocationsManagement /></ProtectedRoute>} />
          <Route path="/production/budget" element={<ProtectedRoute><ProductionBudgets /></ProtectedRoute>} />
          <Route path="/production/schedule" element={<ProtectedRoute><ProductionSchedule /></ProtectedRoute>} />
        </Routes>
      </div>
      {(!user || (user && !user.profileCompleted && user.loginCount === 0)) && <ProfileCompletion />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
        <Toaster position="bottom-right" />
      </AuthProvider>
    </Router>
  );
}

export default App;