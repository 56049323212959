import React, { useState, useEffect, useContext } from 'react';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getDocs, 
  getDoc, 
  doc, 
  serverTimestamp,
  orderBy,
  limit,
  Timestamp 
} from 'firebase/firestore';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../hooks/useAuth';
import { AuthContext } from '../contexts/AuthContext';
import './Dashboard.css';
import '../styles/SharedStyles.css';
import { Link } from 'react-router-dom';
import { updateSupervisorProduction } from '../utils/updateSupervisor';
import AdminRevenueDashboard from '../components/dashboard/AdminRevenueDashboard';
import ProductionSubscriptionDashboard from '../components/dashboard/ProductionSubscriptionDashboard';
import ProductionAdminDashboard from '../components/dashboard/ProductionAdminDashboard';
import '../styles/AdminRevenueDashboard.css';
import '../styles/ProductionSubscriptionDashboard.css';
import { ROLES } from '../utils/permissions';

function Dashboard() {
  const { user } = useAuth();
  const { currentUser, userRole, currentProduction } = useContext(AuthContext);
  const [activeProductions, setActiveProductions] = useState(0);
  const [totalCrewMembers, setTotalCrewMembers] = useState(0);
  const [upcomingShoots, setUpcomingShoots] = useState(0);
  const [productionStatus, setProductionStatus] = useState({
    preProduction: 0,
    inProduction: 0,
    postProduction: 0
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState('trial');
  const [trialDaysLeft, setTrialDaysLeft] = useState(30);
  const [recentActivities, setRecentActivities] = useState([]);
  const [crewMemberCount, setCrewMemberCount] = useState(0);
  const [departmentCrewMembers, setDepartmentCrewMembers] = useState([]);
  const [departmentSupervisors, setDepartmentSupervisors] = useState([]);
  const [pendingTimesheets, setPendingTimesheets] = useState(0);
  const [departmentVehicles, setDepartmentVehicles] = useState([]);
  const [departmentName, setDepartmentName] = useState('');
  const [productionName, setProductionName] = useState('No production selected');
  const [pendingTimeUsers, setPendingTimeUsers] = useState(new Set());
  const [crewMembers, setCrewMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedState, setSelectedState] = useState('');

  // State abbreviations array for USA states
  const stateAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  useEffect(() => {
    // Early validation to ensure user has proper production access
    if (!user) {
      console.log('No user data available');
      return;
    }

    if (!user.production) {
      console.log('User has no production assigned');
      // Reset all state to prevent data leakage
      setDepartmentCrewMembers([]);
      setCrewMemberCount(0);
      setPendingTimesheets(0);
      setPendingTimeUsers(new Set());
      setDepartmentVehicles([]);
      return;
    }

    console.log('User production context:', {
      production: user.production,
      department: user.department,
      role: user.role
    });
  }, [user]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch active productions
        const productionsQuery = query(collection(db, 'productions'), where('status', '==', 'active'));
        const productionsSnapshot = await getDocs(productionsQuery);
        setActiveProductions(productionsSnapshot.size);

        // Fetch total crew members
        const crewMembersSnapshot = await getDocs(collection(db, 'crewMembers'));
        setTotalCrewMembers(crewMembersSnapshot.size);

        // Fetch upcoming shoots
        const today = new Date();
        const shootsQuery = query(
          collection(db, 'shoots'),
          where('date', '>', today)
        );
        const shootsSnapshot = await getDocs(shootsQuery);
        setUpcomingShoots(shootsSnapshot.size);

        // Fetch production status
        const statusSnapshot = await getDoc(doc(db, 'statistics', 'productionStatus'));
        if (statusSnapshot.exists()) {
          setProductionStatus(statusSnapshot.data());
        }

        // Fetch recent activities
        const activitiesQuery = query(
          collection(db, 'activities'),
          where('timestamp', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
        );
        const activitiesSnapshot = await getDocs(activitiesQuery);
        const recentActivitiesList = activitiesSnapshot.docs.map(doc => doc.data().description);
        setRecentActivities(recentActivitiesList);

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    console.log('State changed:', {
      user: user?.uid,
      department: user?.department,
      production: user?.production,
      crewCount: departmentCrewMembers?.length,
      pendingTimes: pendingTimesheets
    });
  }, [user, departmentCrewMembers, pendingTimesheets]);

  useEffect(() => {
    const fetchDepartmentVehicles = async () => {
      if (!user?.department || !user?.production) {
        setDepartmentVehicles([]);
        return;
      }

      try {
        console.log('Fetching rented vehicles for Production department and production:', user.production);
        const vehiclesQuery = query(
          collection(db, 'rentedVehicles'),
          where('production', '==', user.production),
          where('department', '==', 'Production')
        );

        const vehiclesSnapshot = await getDocs(vehiclesQuery);
        const vehicles = vehiclesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Production department rented vehicles found:', {
          total: vehicles.length,
          vehicleDetails: vehicles
        });

        setDepartmentVehicles(vehicles);
      } catch (error) {
        console.error('Error fetching rented vehicles:', error);
        setDepartmentVehicles([]);
      }
    };

    fetchDepartmentVehicles();
  }, [user?.department, user?.production]);

  useEffect(() => {
    const fetchDepartmentName = async () => {
      if (!user?.department) return;

      try {
        // If department is an ID (starts with MPCz)
        if (user.department.startsWith('MPCz')) {
          const deptDoc = await getDoc(doc(db, 'departments', user.department));
          if (deptDoc.exists()) {
            setDepartmentName(deptDoc.data().name);
          }
        } else {
          setDepartmentName(user.department);
        }
      } catch (error) {
        console.error('Error fetching department name:', error);
      }
    };

    fetchDepartmentName();
  }, [user?.department]);

  useEffect(() => {
    const fetchProductionName = async () => {
      if (!user) {
        console.log('No user:', { user });
        setProductionName('No production selected');
        return;
      }

      if (!user.production) {
        console.log('No production for user:', { user });
        setProductionName('No production selected');
        return;
      }

      setProductionName(user.production);
      console.log('Set production name from user:', user.production);
    };

    fetchProductionName();
  }, [user]);

  useEffect(() => {
    console.log('Current state:', {
      productionName,
      departmentName,
      user: user?.production
    });
  }, [productionName, departmentName, user]);

  useEffect(() => {
    const fetchSupervisorData = async () => {
      if (!user?.department || !user?.production) {
        console.log('Missing user data');
        setDepartmentCrewMembers([]);
        setCrewMemberCount(0);
        setPendingTimesheets(0);
        return;
      }

      try {
        // First fetch crew members
        const crewQuery = query(
          collection(db, 'users'),
          where('department', '==', user.department),
          where('production', '==', user.production),
          where('role', 'in', ['Crew Member', 'crew_member', 'crewMember', 'Crew'])
        );

        const crewSnapshot = await getDocs(crewQuery);
        const crewMembers = crewSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(member => member.role !== 'Supervisor');

        console.log('Found crew members:', {
          total: crewMembers.length,
          production: user.production
        });

        setDepartmentCrewMembers(crewMembers);
        setCrewMemberCount(crewMembers.length);

        // Then check for pending timesheets
        if (crewMembers.length > 0) {
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          const startOfDay = new Date(yesterday);
          startOfDay.setHours(0, 0, 0, 0);
          const endOfDay = new Date(yesterday);
          endOfDay.setHours(23, 59, 59, 999);

          console.log('Checking times for:', yesterday.toDateString());

          const timeEntriesQuery = query(
            collection(db, 'timeEntries'),
            where('date', '>=', Timestamp.fromDate(startOfDay)),
            where('date', '<=', Timestamp.fromDate(endOfDay))
          );

          const timeSnapshot = await getDocs(timeEntriesQuery);
          const timeEntries = timeSnapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data()
            }))
            .filter(entry => entry.production === user.production);

          let pendingCount = 0;
          let pendingUsers = new Set();

          for (const member of crewMembers) {
            const hasValidEntry = timeEntries.some(entry => 
              entry.userId === member.id && 
              entry.startTime !== undefined && 
              entry.endTime !== undefined
            );

            if (!hasValidEntry) {
              pendingUsers.add(member.id);
              pendingCount++;
            }
          }

          console.log(`Found ${pendingCount} pending timesheets out of ${crewMembers.length} crew members`);
          setPendingTimesheets(pendingCount);
          setPendingTimeUsers(pendingUsers);
        }

      } catch (error) {
        console.error('Error in fetchSupervisorData:', error);
        // Keep existing crew count but mark all as pending
        setPendingTimesheets(departmentCrewMembers.length);
        setPendingTimeUsers(new Set(departmentCrewMembers.map(m => m.id)));
      }
    };

    fetchSupervisorData();
  }, [user?.department, user?.production]);

  useEffect(() => {
    const fetchSupervisors = async () => {
      if (!user?.department || !user?.production) {
        setDepartmentSupervisors([]);
        return;
      }

      try {
        const supervisorsQuery = query(
          collection(db, 'users'),
          where('production', '==', user.production),
          where('role', 'in', ['Supervisor', 'supervisor']),
          where('department', '==', user.department)
        );

        const supervisorsSnapshot = await getDocs(supervisorsQuery);
        const supervisors = supervisorsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Found department supervisors:', {
          total: supervisors.length,
          production: user.production
        });

        setDepartmentSupervisors(supervisors);
      } catch (error) {
        console.error('Error fetching supervisors:', error);
        setDepartmentSupervisors([]);
      }
    };

    fetchSupervisors();
  }, [user?.department, user?.production]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (!user?.production) return;

      try {
        const subsQuery = query(
          collection(db, 'subscriptions'),
          where('productionName', '==', user.production)
        );
        const subsQuerySnapshot = await getDocs(subsQuery);
        
        if (!subsQuerySnapshot.empty) {
          const data = subsQuerySnapshot.docs[0].data();
          setSubscriptionStatus(data.status || 'trial');
          if (data.trialEndDate) {
            const now = new Date();
            const diffTime = data.trialEndDate.toDate() - now;
            setTrialDaysLeft(Math.max(0, Math.ceil(diffTime / (1000 * 60 * 60 * 24))));
          }
        }
      } catch (error) {
        console.error('Error fetching subscription data:', error);
      }
    };

    fetchSubscriptionData();
  }, [user?.production]);

  useEffect(() => {
    console.log('Current state values:', {
      pendingTimesheets,
      crewMemberCount,
      departmentCrewMembers: departmentCrewMembers.length,
      production: user?.production,
      department: user?.department
    });
  }, [pendingTimesheets, crewMemberCount, departmentCrewMembers, user]);

  // Render supervisor dashboard
  const renderSupervisorDashboard = () => {
    return (
      <div className="dashboard-content">
        <header className="page-header">
          <div className="header-left">
            {subscriptionStatus === 'trial' && (
              <div className="trial-status">
                <div className="status">TRIAL</div>
                <div className="trial-days">Trial ends in {trialDaysLeft} days</div>
              </div>
            )}
          </div>
          <div className="header-center">
            <h1>Supervisor Dashboard</h1>
            <div className="production-name">{productionName}</div>
            <h3>{departmentName} Department Overview</h3>
          </div>
        </header>

        <div className="stats-grid">
          <div className="stat-card">
            <h3>Department Crew</h3>
            <p className="stat-number">{departmentCrewMembers.length}</p>
          </div>
          <div className="stat-card">
            <h3>Pending Times</h3>
            <p className="stat-number">{pendingTimesheets}</p>
          </div>
          <div className="stat-card">
            <h3>Rented Vehicles</h3>
            <p className="stat-number">{departmentVehicles.length}</p>
          </div>
        </div>

        <div className="subscription-status-section">
          <ProductionSubscriptionDashboard productionId={user?.production} compact={true} />
        </div>

        <div className="quick-actions-section">
          <h3>Quick Actions</h3>
          <div className="quick-actions-grid">
            <Link to="/dispatch" className="action-card">
              <div className="action-icon">
                <i className="fas fa-truck"></i>
                <i className="fas fa-clock"></i>
              </div>
              <span>Create Dispatch</span>
            </Link>
            <Link to="/reports" className="action-card">
              <i className="fas fa-chart-bar"></i>
              <span>View Reports</span>
            </Link>
            <Link to="/crew-members" className="action-card">
              <i className="fas fa-users"></i>
              <span>Manage Crew</span>
            </Link>
            <Link to="/vehicles-page" className="action-card">
              <i className="fas fa-car"></i>
              <span>Manage Vehicles</span>
            </Link>
            <Link to="/view-receipts" className="action-card">
              <i className="fas fa-receipt"></i>
              <span>View Receipts</span>
            </Link>
          </div>
        </div>

        <div className="crew-members-section">
          <h3>Department Crew Members</h3>
          <div className="crew-list">
            {departmentCrewMembers.map(member => (
              <div key={member.id} className="crew-item">
                <div className={`status-indicator ${pendingTimeUsers.has(member.id) ? 'warning' : 'good'}`}></div>
                <div className="member-info">
                  <h4>{member.name}</h4>
                  <p>{member.role}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="supervisors-section">
          <h3>Department Supervisors</h3>
          <div className="crew-list">
            {departmentSupervisors.map(supervisor => (
              <div key={supervisor.id} className="crew-item">
                <div className={`status-indicator ${pendingTimeUsers.has(supervisor.id) ? 'warning' : 'good'}`}></div>
                <div className="member-info">
                  <h4>{supervisor.name}</h4>
                  <p>{supervisor.role}</p>
                </div>
              </div>
            ))}
            {departmentSupervisors.length === 0 && (
              <p className="no-data">No supervisors found for this department</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      {(userRole === ROLES.ADMIN || user?.role === 'Admin' || user?.role === 'admin' || user?.role === 'ADMIN') ? (
        <AdminRevenueDashboard />
      ) : (userRole === ROLES.APP_ADMIN || user?.role === 'App Admin' || user?.role === 'app_admin' || user?.role === 'app admin') ? (
        <ProductionSubscriptionDashboard />
      ) : (userRole === ROLES.PRODUCTION_ADMIN || user?.role === 'Production Admin' || user?.role === 'production_admin' || user?.role === 'production admin') ? (
        <ProductionAdminDashboard />
      ) : (userRole === ROLES.SUPERVISOR || user?.role === 'Supervisor' || user?.role === 'supervisor') ? (
        renderSupervisorDashboard()
      ) : (
        // Regular dashboard content
        <div className="dashboard-content">
          <header className="page-header">
            <h1>Dashboard</h1>
            <p>Overview of your production activities</p>
          </header>
          <main>
            <div className="dashboard-grid">
              <div className="dashboard-card">
                <h3>Active Productions</h3>
                <p className="dashboard-number">{activeProductions}</p>
              </div>
              <div className="dashboard-card">
                <h3>Total Crew Members</h3>
                <p className="dashboard-number">{crewMemberCount}</p>
              </div>
              <div className="dashboard-card">
                <h3>Upcoming Shoots</h3>
                <p className="dashboard-number">{upcomingShoots}</p>
              </div>
              <div className="dashboard-card large">
                <h3>Production Status</h3>
                <div className="production-counts">
                  <div>
                    <p>Pre-production</p>
                    <p className="dashboard-number">{productionStatus.preProduction}</p>
                  </div>
                  <div>
                    <p>In Production</p>
                    <p className="dashboard-number">{productionStatus.inProduction}</p>
                  </div>
                  <div>
                    <p>Post-production</p>
                    <p className="dashboard-number">{productionStatus.postProduction}</p>
                  </div>
                </div>
              </div>
              <div className="dashboard-card large">
                <h3>Quick Actions</h3>
                <div className="quick-links-grid">
                  <Link to="/create-production" className="quick-link-card">
                    <i className="fas fa-plus-circle"></i>
                    <span>Create New Production</span>
                  </Link>
                  <Link to="/crew-members" className="quick-link-card">
                    <i className="fas fa-users"></i>
                    <span>Add Crew Member</span>
                  </Link>
                  <div className="state-selector">
                    <label htmlFor="stateSelect">Select State:</label>
                    <select
                      id="stateSelect"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      className="state-select"
                    >
                      <option value="">Select State</option>
                      {stateAbbreviations.map(state => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                  <Link to="/reports" className="quick-link-card">
                    <i className="fas fa-chart-bar"></i>
                    <span>View Reports</span>
                  </Link>
                  <Link to="/view-receipts" className="quick-link-card dashboard-action-btn">
                    <i className="fas fa-receipt"></i>
                    <span>View Receipts</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="dashboard-bottom">
              <div className="dashboard-recent">
                <h3>Recent Activities</h3>
                <ul>
                  {recentActivities.map((activity, index) => (
                    <li key={index}>{activity}</li>
                  ))}
                </ul>
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
