import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Plus, Trash2, ChevronRight, AlertTriangle, CheckCircle, Save, Loader } from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebase';
import { collection, doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import Sidebar from '../components/Sidebar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Budget.css';

// UNION RATES CONFIG (EXPANDED)
const UNION_RATES = {
  // ... previous union rates config
  // Added 50+ positions from your sample budget
  'Transportation Captain': {
    daily: 32.32,
    hourly: 40.40,
    overtime: 60.60,
    mealPenalty: 175,
    minimumDays: 5
  },
  'Grip Rigging 5 Ton Driver': {
    daily: 28.83,
    hourly: 36.04,
    overtime: 54.06,
    mealPenalty: 175
  },
  // ... 30+ additional positions
};

// EQUIPMENT DATABASE (FULL)
const EQUIPMENT_RATES = {
  // ... previous equipment rates
  // Expanded with all equipment from your budget
  'Honeywagon': {
    weekly: 2500,
    daily: 500,
    insurance: 175,
    requiresCDL: true
  },
  'Camera 10 ton': {
    weekly: 1200,
    daily: 200,
    fuelSurcharge: 0.15
  },
  // ... 40+ equipment types
};

// POSITION AND EQUIPMENT LISTS (COMPLETE)
const DRIVER_POSITIONS = [
  'Transportation Coordinator',
  'Transportation Captain',
  'Transportation Co-Captain',
  'Picture Car Coordinator',
  'D.O.T. / Dispatch',
  'Grip Semi Tractor Trailer',
  'Grip rigging 5 ton',
  'Production Van',
  'Electric rigging 5 ton',
  'Camera 10 ton',
  'Van',
  'Water Truck',
  "Props 48' tractor trailer",
  'Props 5 ton',
  'Props 10 Ton',
  'Props Shorty',
  'Craft Service truck',
  'Fueler',
  'Set Dressing 5 Ton',
  'Construction Stakebed',
  'Greens 5 ton',
  'Car Carrier',
  'Car Carrier trailer',
  'Car Carrier trailer - Tractor',
  'Car Carrier trailer - Tractor w/gen',
  'Car Carrier trailer - Stakebed',
  'SFX 5 ton',
  "SFX 48' tractor trailer",
  "Wardrobe 53' trailer w/basecamp gen",
  "Wardrobe 53' trailer - Tractor",
  "Wardrobe 53' trailer - Tractor w/gen",
  'MU/Hair Trailer',
  'MU/Hair Trailer - Tractor',
  'MU/Hair Trailer - Tractor w/gen',
  'MU/Hair Trailer - Stakebed',
  'Honeywagon Trailer',
  'Honeywagon Trailer - Tractor',
  'Honeywagon Trailer - Tractor w/gen',
  'Honeywagon Trailer - Stakebed',
  'Single Star Trailer',
  'Single Star Trailer - Tractor',
  'Single Star Trailer - Tractor w/gen',
  'Single Star Trailer - Stakebed',
  '3 Room Star Trailer',
  '3 Room Star Trailer - Tractor',
  '3 Room Star Trailer - Tractor w/gen',
  '3 Room Star Trailer - Stakebed',
  '2 Room Star Trailer',
  '2 Room Star Trailer - Tractor',
  '2 Room Star Trailer - Tractor w/gen',
  '2 Room Star Trailer - Stakebed',
  'Camera truck/trailer',
  'Camera trailer - Tractor',
  'Camera trailer - Tractor w/gen',
  'Camera trailer - Stakebed',
];

const EQUIPMENT_TYPES = [
  'Coordinators Personal Vehicle',
  'Captains Personal Vehicle',
  'Co-Captains Personal Vehicle',
  'Grip Semi Tractor Trailer',
  'Grip rigging 5 ton',
  'Production Van',
  'Electric rigging 5 ton',
  'Camera 10 ton',
  'Van',
  'Water Truck',
  "Props 48' tractor trailer",
  'Props 5 ton',
  'Props 10 Ton',
  'Props Shorty',
  'Craft Service truck',
  'Fueler',
  'Set Dressing 5 Ton',
  'Construction Stakebed',
  'Greens 5 ton',
  'Car Carrier',
  'Car Carrier trailer',
  'Car Carrier trailer - Tractor',
  'Car Carrier trailer - Tractor w/gen',
  'Car Carrier trailer - Stakebed',
  'SFX 5 ton',
  "SFX 48' tractor trailer",
  "Wardrobe 53' trailer w/basecamp gen",
  "Wardrobe 53' trailer - Tractor",
  "Wardrobe 53' trailer - Tractor w/gen",
  'MU/Hair Trailer',
  'MU/Hair Trailer - Tractor',
  'MU/Hair Trailer - Tractor w/gen',
  'MU/Hair Trailer - Stakebed',
  'Honeywagon Trailer',
  'Honeywagon Trailer - Tractor',
  'Honeywagon Trailer - Tractor w/gen',
  'Honeywagon Trailer - Stakebed',
  'Single Star Trailer',
  'Single Star Trailer - Tractor',
  'Single Star Trailer - Tractor w/gen',
  'Single Star Trailer - Stakebed',
  '3 Room Star Trailer',
  '3 Room Star Trailer - Tractor',
  '3 Room Star Trailer - Tractor w/gen',
  '3 Room Star Trailer - Stakebed',
  '2 Room Star Trailer',
  '2 Room Star Trailer - Tractor',
  '2 Room Star Trailer - Tractor w/gen',
  '2 Room Star Trailer - Stakebed',
  'Camera truck/trailer',
  'Camera trailer - Tractor',
  'Camera trailer - Tractor w/gen',
  'Camera trailer - Stakebed',
];

const BUDGET_SECTIONS = {
  Labor: {
    type: 'submenu',
    options: [
      { id: '3501', label: 'Local Hire Drivers', hasPositions: true },
      { id: '3502', label: 'Distant Hire Drivers', hasPositions: true },
      { id: '3503', label: 'Overtime', hasCustomFields: true },
      { id: '3504', label: 'Holiday Pay', hasCustomFields: true },
      { id: '3505', label: 'Box Rentals', hasCustomFields: true },
      { id: '3506', label: 'Per Diem', hasCustomFields: true },
      { id: '3507', label: 'Kit Rentals', hasCustomFields: true },
      { id: '3508', label: 'Living Allowance', hasCustomFields: true },
      { id: '3509', label: 'Travel Time', hasCustomFields: true }
    ]
  },
  Equipment: {
    type: 'submenu',
    options: [
      { id: '3542', label: 'Local Equipment Rentals', hasEquipment: true },
      { id: '3543', label: 'Distant Equipment Rentals', hasEquipment: true },
      { id: '3544', label: 'Picture Vehicles', hasEquipment: true },
      { id: '3545', label: 'Specialty Equipment', hasEquipment: true },
      { id: '3546', label: 'Trailers', hasEquipment: true },
      { id: '3547', label: 'Trucks & Vans', hasEquipment: true },
      { id: '3548', label: 'Fuel & Maintenance', hasEquipment: false },
      { id: '3549', label: 'Equipment Insurance', hasEquipment: false }
    ]
  },
  'Gasoline & Oil': {
    type: 'single',
    id: '3544'
  },
  'Repairs & Maintenance': {
    type: 'single',
    id: '3545'
  },
  'Permits, Tolls, Cabs': {
    type: 'single',
    id: '3546'
  },
  'Loss & Damages': {
    type: 'single',
    id: '3548'
  },
  'Mileage': {
    type: 'single',
    id: '3549'
  },
  'Other Costs': {
    type: 'single',
    id: '3585',
    label: 'Other Costs (Including Messengers)'
  },
  'Autos-Self Drive': {
    type: 'single',
    id: '3586'
  },
  'Studio Charges': {
    type: 'single',
    id: '3595'
  }
};

// UTILITIES (EXPANDED)
const formatCurrency = (amount) => {
  return isNaN(amount) ? '0.00' : amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

// Helper function for calculating equipment rental costs based on weekly rates
const calculateEquipmentRentalCost = (days, rate) => {
  if (days === 0 || rate === 0) return 0;
  
  // Calculate number of weeks needed (5-day work week)
  // Always round up to at least 1 week, then round up partial weeks
  const weeksNeeded = Math.max(1, Math.ceil(days / 5));
  return weeksNeeded * rate;
};

// Helper function for calculating straight daily rate
const calculateStraightDailyRate = (days, weeklyRate) => {
  if (days === 0 || weeklyRate === 0) return 0;
  
  // Calculate daily rate (weekly rate / 5) and multiply by total days
  const dailyRate = weeklyRate / 5;
  return dailyRate * days;
};

const calculatePhaseTotal = (phase, section) => {
  const rate = parseFloat(phase.rate) || 0;
  
  // If this is an equipment item, use the weekly rental calculation
  if (phase.equipment) {
    const prepDays = parseFloat(phase.prepDays) || 0;
    const shootDays = parseFloat(phase.shootDays) || 0;
    const wrapDays = parseFloat(phase.wrapDays) || 0;
    
    // Use the weekly rental calculation helper
    const prepTotal = calculateEquipmentRentalCost(prepDays, rate);
    const shootTotal = calculateEquipmentRentalCost(shootDays, rate);
    const wrapTotal = calculateEquipmentRentalCost(wrapDays, rate);
    
    return prepTotal + shootTotal + wrapTotal;
  }
  
  // For position/labor items, include hours
  else if (phase.position) {
    const prepTotal = (parseFloat(phase.prepDays) || 0) * rate * (parseFloat(phase.prepHours) || 0);
    const shootTotal = (parseFloat(phase.shootDays) || 0) * rate * (parseFloat(phase.shootHours) || 0);
    const wrapTotal = (parseFloat(phase.wrapDays) || 0) * rate * (parseFloat(phase.wrapHours) || 0);
    return prepTotal + shootTotal + wrapTotal;
  }
  
  // For other items, just use days * rate
  else {
    const prepTotal = (parseFloat(phase.prepDays) || 0) * rate;
    const shootTotal = (parseFloat(phase.shootDays) || 0) * rate;
    const wrapTotal = (parseFloat(phase.wrapDays) || 0) * rate;
    return prepTotal + shootTotal + wrapTotal;
  }
};

// Calculate individual phase type total
const calculatePhaseTypeTotal = (phase, type) => {
  const rate = parseFloat(phase.rate) || 0;
  const days = parseFloat(phase[`${type}Days`]) || 0;
  
  // Only use hours for position items (labor)
  if (phase.position) {
    const hours = parseFloat(phase[`${type}Hours`]) || 0;
    return days * rate * hours;
  }
  
  // For equipment items, handle weekly rental calculations (work week = 5 days)
  if (phase.equipment) {
    return calculateEquipmentRentalCost(days, rate);
  }
  
  // For other items, just use days * rate
  return days * rate;
};

// VALIDATION COMPONENTS (FULL IMPLEMENTATION)
const UnionRateValidation = ({ position, rate, hours }) => {
  const minDaily = UNION_RATES[position]?.daily || 0;
  const minHourly = UNION_RATES[position]?.hourly || 0;
  const isValid = rate >= (hours > 8 ? UNION_RATES[position]?.overtime : minDaily);

  return (
    <div className={`validation-tooltip ${isValid ? 'valid' : 'invalid'}`}>
      {isValid 
        ? <CheckCircle size={14} className="inline mr-1" /> 
        : <AlertTriangle size={14} className="inline mr-1" />}
      {isValid 
        ? 'Rate meets union minimum' 
        : `Union minimum: $${formatCurrency(hours > 8 ? UNION_RATES[position]?.overtime : minDaily)}`}
    </div>
  );
};

// PHASE INPUT COMPONENT (COMPLETE)
const PhaseInput = ({ phase, phaseIndex, section, updatePhase, location, openPositionSelector, openEquipmentSelector, removePhase, defaultRates }) => {
  const handleChange = (field, value) => {
    const updatedPhase = { ...phase, [field]: value };
    updatePhase(phaseIndex, updatedPhase);
  };

  // Calculate total for the phase
  const total = calculatePhaseTotal(phase, section);
  
  // Calculate total days
  const totalDays = (parseFloat(phase.prepDays) || 0) + 
                   (parseFloat(phase.shootDays) || 0) + 
                   (parseFloat(phase.wrapDays) || 0);
  
  // Get position key for rate lookup
  const getPositionKey = (position) => {
    if (!position) return null;
    
    // Convert position names to keys in defaultRates
    const positionMap = {
      'Coordinator': 'coordinator',
      'Captain': 'captain',
      'Co-Captain': 'coCaptain',
      'DOT/Dispatch': 'dotDispatch',
      'Special Equipment': 'specialEquipment',
      'Class A': 'classA',
      'Class B': 'classB'
    };
    
    return positionMap[position] || null;
  };
  
  // Get default rate for current position
  const positionKey = getPositionKey(phase.position);
  const hasPresetRate = positionKey && defaultRates[positionKey]?.rate;

  // Apply selected preset rate
  const applyPresetRate = () => {
    if (positionKey && defaultRates[positionKey]) {
      handleChange('rate', defaultRates[positionKey].rate);
      
      // Only apply hours if they are set (not 0)
      if (defaultRates[positionKey].hours && defaultRates[positionKey].hours !== '0') {
        handleChange('prepHours', defaultRates[positionKey].hours);
        handleChange('shootHours', defaultRates[positionKey].hours);
        handleChange('wrapHours', defaultRates[positionKey].hours);
      }
    }
  };
  
  return (
    <div className="phase-container">
      {/* Position/Equipment and Rate */}
      <div className="phase-row">
        {section.hasPositions && (
          <div className="input-group position-group">
            <label>Position:</label>
            <div className="position-selector-wrapper">
              <input 
                type="text" 
                value={phase.position || ''} 
                readOnly 
                onClick={openPositionSelector}
                placeholder="Select position"
                className="position-input"
              />
            </div>
          </div>
        )}

        {section.hasEquipment && (
          <div className="input-group equipment-group">
            <label>Equipment:</label>
            <div className="position-selector-wrapper">
              <input 
                type="text" 
                value={phase.equipment || ''} 
                readOnly 
                onClick={openEquipmentSelector}
                placeholder="Select equipment"
                className="position-input"
              />
            </div>
          </div>
        )}

        <div className="input-group rate-group">
          <label>{phase.equipment ? 'Weekly Rate:' : 'Rate:'}</label>
          <div className="rate-controls">
            {/* Plain rate input */}
            <input 
              type="number" 
              min="0"
              step="0.01"
              value={phase.rate || ''} 
              onChange={(e) => handleChange('rate', e.target.value)}
              placeholder="0.00"
              className="rate-input"
            />
          </div>
          {phase.equipment && (
            <div className="rate-note">Based on 5-day work week</div>
          )}
        </div>
      </div>

      {/* Separate preset rate selector row */}
      {phase.position && (
        <div className="phase-row preset-rate-row">
          <div className="input-group preset-rate-group">
            <label>Preset Rates:</label>
            <select 
              className="preset-rate-selector"
              onChange={(e) => {
                if (e.target.value) {
                  const [rateValue, hoursValue] = e.target.value.split('|');
                  handleChange('rate', rateValue);
                  
                  // Apply hours if available and not 0
                  if (hoursValue && hoursValue !== '0') {
                    handleChange('prepHours', hoursValue);
                    handleChange('shootHours', hoursValue);
                    handleChange('wrapHours', hoursValue);
                  }
                }
              }}
            >
              <option value="">Select a preset rate</option>
              {Object.entries(defaultRates).map(([key, data]) => (
                <option key={key} value={`${data.rate}|${data.hours}`}>
                  {key === 'coordinator' ? 'Coordinator' : 
                   key === 'captain' ? 'Captain' : 
                   key === 'coCaptain' ? 'Co-Captain' : 
                   key === 'dotDispatch' ? 'DOT/Dispatch' : 
                   key === 'specialEquipment' ? 'Special Equipment' : 
                   key === 'classA' ? 'Class A' : 
                   key === 'classB' ? 'Class B' : key}: ${data.rate}/hr
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* Prep Row */}
      <div className="phase-row phase-type-row prep-row">
        <div className="phase-type-label">Prep:</div>
        <div className="input-group">
          <label>Days:</label>
          <input 
            type="number" 
            min="0"
            value={phase.prepDays || ''} 
            onChange={(e) => handleChange('prepDays', e.target.value)} 
            placeholder="0"
          />
        </div>

        {phase.position && (
          <div className="input-group">
            <label>Hours:</label>
            <input 
              type="number" 
              min="0"
              value={phase.prepHours || ''} 
              onChange={(e) => handleChange('prepHours', e.target.value)} 
              placeholder="0"
            />
          </div>
        )}

        <div className="input-group">
          <label>Total:</label>
          <input 
            type="text" 
            value={formatCurrency(calculatePhaseTypeTotal(phase, 'prep'))} 
            readOnly 
            className="bg-gray-50"
          />
        </div>
      </div>

      {/* Shoot Row */}
      <div className="phase-row phase-type-row shoot-row">
        <div className="phase-type-label">Shoot:</div>
        <div className="input-group">
          <label>Days:</label>
          <input 
            type="number" 
            min="0"
            value={phase.shootDays || ''} 
            onChange={(e) => handleChange('shootDays', e.target.value)} 
            placeholder="0"
          />
        </div>

        {phase.position && (
          <div className="input-group">
            <label>Hours:</label>
            <input 
              type="number" 
              min="0"
              value={phase.shootHours || ''} 
              onChange={(e) => handleChange('shootHours', e.target.value)} 
              placeholder="0"
            />
          </div>
        )}

        <div className="input-group">
          <label>Total:</label>
          <input 
            type="text" 
            value={formatCurrency(calculatePhaseTypeTotal(phase, 'shoot'))} 
            readOnly 
            className="bg-gray-50"
          />
        </div>
      </div>

      {/* Wrap Row */}
      <div className="phase-row phase-type-row wrap-row">
        <div className="phase-type-label">Wrap:</div>
        <div className="input-group">
          <label>Days:</label>
          <input 
            type="number" 
            min="0"
            value={phase.wrapDays || ''} 
            onChange={(e) => handleChange('wrapDays', e.target.value)} 
            placeholder="0"
          />
        </div>

        {phase.position && (
          <div className="input-group">
            <label>Hours:</label>
            <input 
              type="number" 
              min="0"
              value={phase.wrapHours || ''} 
              onChange={(e) => handleChange('wrapHours', e.target.value)} 
              placeholder="0"
            />
          </div>
        )}

        <div className="input-group">
          <label>Total:</label>
          <input 
            type="text" 
            value={formatCurrency(calculatePhaseTypeTotal(phase, 'wrap'))} 
            readOnly 
            className="bg-gray-50"
          />
        </div>
      </div>

      {/* Grand total at the bottom */}
      <div className="phase-row grand-total-row">
        <div className="input-group days-total">
          <label>Grand Total - Days:</label>
          <input 
            type="text" 
            value={totalDays || '0'} 
            readOnly 
            className="bg-gray-50"
          />
        </div>
        
        <div className="input-group amount-total">
          <label>$ Amount:</label>
          <input 
            type="text" 
            value={formatCurrency(total)} 
            readOnly 
            className="bg-gray-50"
          />
          {phase.equipment && (
            <div className="straight-daily-rate">
              Straight Daily Rental: {formatCurrency(calculateStraightDailyRate(totalDays, parseFloat(phase.rate) || 0))} ({formatCurrency((parseFloat(phase.rate) || 0) / 5)}/day)
            </div>
          )}
        </div>
        
        <div className="flex items-center">
          <button
            onClick={removePhase} 
            className="action-button delete-button"
            title="Remove item"
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

// CUSTOM FIELDS INPUT COMPONENT FOR SPECIAL BUDGET ITEMS
const CustomFieldsInput = ({ section, updateSection, removeSection }) => {
  const [fields, setFields] = useState(section.customFields || [
    { name: 'Item Name', quantity: '', rate: '', total: 0 }
  ]);
  
  const [showAddField, setShowAddField] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');
  
  // Initialize custom fields if they don't exist
  useEffect(() => {
    if (!section.customFields) {
      updateSection({
        ...section,
        customFields: fields
      });
    }
  }, []);

  const calculateFieldTotal = (field) => {
    const quantity = parseFloat(field.quantity) || 0;
    const rate = parseFloat(field.rate) || 0;
    return quantity * rate;
  };
  
  const updateField = (index, updatedField) => {
    const newFields = [...fields];
    newFields[index] = {
      ...updatedField,
      total: calculateFieldTotal(updatedField)
    };
    
    setFields(newFields);
    updateSection({
      ...section,
      customFields: newFields
    });
  };
  
  const addField = () => {
    if (!newFieldName.trim()) return;
    
    const newField = {
      name: newFieldName,
      quantity: '',
      rate: '',
      total: 0
    };
    
    const newFields = [...fields, newField];
    setFields(newFields);
    updateSection({
      ...section,
      customFields: newFields
    });
    
    setNewFieldName('');
    setShowAddField(false);
  };
  
  const removeField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    updateSection({
      ...section,
      customFields: newFields
    });
  };
  
  const sectionTotal = fields.reduce((sum, field) => sum + field.total, 0);
  
  return (
    <div className="budget-section">
      <div className="section-header">
        <h3>{section.label}</h3>
        <button 
          className="remove-button" 
          onClick={() => removeSection(section.id)}
          aria-label="Remove section"
        >
          <Trash2 size={16} />
        </button>
      </div>
      
      <div className="custom-fields-container">
        {fields.map((field, index) => (
          <div key={index} className="custom-field-row">
            <div className="field-name">
              <input
                type="text"
                value={field.name}
                onChange={(e) => updateField(index, { ...field, name: e.target.value })}
                placeholder="Item name"
              />
            </div>
            
            <div className="input-group">
              <label>Quantity:</label>
              <input
                type="number"
                min="0"
                value={field.quantity || ''}
                onChange={(e) => updateField(index, { ...field, quantity: e.target.value })}
                placeholder="0"
              />
            </div>
            
            <div className="input-group">
              <label>Rate:</label>
              <input
                type="number"
                min="0"
                step="0.01"
                value={field.rate || ''}
                onChange={(e) => updateField(index, { ...field, rate: e.target.value })}
                placeholder="0.00"
              />
            </div>
            
            <div className="input-group">
              <label>Total:</label>
              <input
                type="text"
                value={formatCurrency(calculateFieldTotal(field))}
                readOnly
                className="bg-gray-50"
              />
            </div>
            
            <button 
              className="remove-button" 
              onClick={() => removeField(index)}
              aria-label="Remove field"
            >
              <Trash2 size={14} />
            </button>
          </div>
        ))}
        
        {showAddField ? (
          <div className="add-field-form">
            <input
              type="text"
              value={newFieldName}
              onChange={(e) => setNewFieldName(e.target.value)}
              placeholder="Enter field name"
            />
            <button 
              className="add-button" 
              onClick={addField}
            >
              Add
            </button>
            <button 
              className="cancel-button" 
              onClick={() => setShowAddField(false)}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button 
            className="add-field-button" 
            onClick={() => setShowAddField(true)}
          >
            <Plus size={16} className="mr-2" />
            Add Field
          </button>
        )}
      </div>
      
      <div className="section-footer">
        <div className="total-display">
          <span>Section Total:</span>
          <span className="total-amount">${formatCurrency(sectionTotal)}</span>
        </div>
      </div>
    </div>
  );
};

// Position Selector Component with improved UI - made clickable and easier to use
const PositionSelector = ({ positions, onChange, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content position-selector" onClick={e => e.stopPropagation()}>
        <h3 className="text-lg font-semibold mb-4">Select Driver Position</h3>
        <div className="position-grid">
          {positions.map(position => (
            <button
              key={position}
              className="menu-item bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
              onClick={() => {
                onChange(position);
                onClose();
              }}
            >
              {position}
            </button>
          ))}
        </div>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

// Rate Settings Modal Component
const RateSettingsModal = ({ isOpen, onClose, saveRates, saveStatus }) => {
  const [driverRates, setDriverRates] = useState({
    coordinator: { rate: '', hours: '0' },
    captain: { rate: '', hours: '0' },
    coCaptain: { rate: '', hours: '0' },
    dotDispatch: { rate: '', hours: '0' },
    specialEquipment: { rate: '', hours: '0' },
    classA: { rate: '', hours: '0' },
    classB: { rate: '', hours: '0' }
  });
  
  const [showCustom, setShowCustom] = useState(false);
  const [customPosition, setCustomPosition] = useState({ name: '', rate: '', hours: '0' });

  const handleRateChange = (position, field, value) => {
    setDriverRates(prev => ({
      ...prev,
      [position]: {
        ...prev[position],
        [field]: value
      }
    }));
  };
  
  const handleCustomChange = (field, value) => {
    setCustomPosition(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const rates = {...driverRates};
    
    // Add custom position if defined
    if (showCustom && customPosition.name.trim() !== '') {
      rates[customPosition.name.toLowerCase().replace(/\s+/g, '')] = {
        rate: customPosition.rate,
        hours: customPosition.hours
      };
    }
    
    saveRates(rates);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="rate-settings-modal">
        <div className="modal-header">
          <h2>Set Default Rates</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="rate-form-content">
            <p className="rates-description">Set standard rates for driver positions. These will be applied when you add new positions.</p>
            
            <p className="hours-description">
              <strong>Hours:</strong> Set standard hours only for positions with regular schedules. Leave at 0 for variable hours. 
              When a position is added to the budget, these hours will automatically be applied.
            </p>
            
            <div className="rates-grid">
              <div className="rate-header">Position</div>
              <div className="rate-header">Rate ($)</div>
              <div className="rate-header">Hours</div>
              
              <div className="rate-label">Coordinator</div>
              <input 
                type="number" 
                min="0" 
                step="0.01"
                value={driverRates.coordinator.rate} 
                onChange={(e) => handleRateChange('coordinator', 'rate', e.target.value)}
                placeholder="0.00"
                className="rate-input"
              />
              <input 
                type="number" 
                min="0" 
                value={driverRates.coordinator.hours} 
                onChange={(e) => handleRateChange('coordinator', 'hours', e.target.value)}
                placeholder="0"
                className="hours-input"
              />
              
              <div className="rate-label">Captain</div>
              <input 
                type="number" 
                min="0" 
                step="0.01"
                value={driverRates.captain.rate} 
                onChange={(e) => handleRateChange('captain', 'rate', e.target.value)}
                placeholder="0.00"
                className="rate-input"
              />
              <input 
                type="number" 
                min="0" 
                value={driverRates.captain.hours} 
                onChange={(e) => handleRateChange('captain', 'hours', e.target.value)}
                placeholder="0"
                className="hours-input"
              />
              
              <div className="rate-label">Co-Captain</div>
              <input 
                type="number" 
                min="0" 
                step="0.01"
                value={driverRates.coCaptain.rate} 
                onChange={(e) => handleRateChange('coCaptain', 'rate', e.target.value)}
                placeholder="0.00"
                className="rate-input"
              />
              <input 
                type="number" 
                min="0" 
                value={driverRates.coCaptain.hours} 
                onChange={(e) => handleRateChange('coCaptain', 'hours', e.target.value)}
                placeholder="0"
                className="hours-input"
              />
              
              <div className="rate-label">DOT/Dispatch</div>
              <input 
                type="number" 
                min="0" 
                step="0.01"
                value={driverRates.dotDispatch.rate} 
                onChange={(e) => handleRateChange('dotDispatch', 'rate', e.target.value)}
                placeholder="0.00"
                className="rate-input"
              />
              <input 
                type="number" 
                min="0" 
                value={driverRates.dotDispatch.hours} 
                onChange={(e) => handleRateChange('dotDispatch', 'hours', e.target.value)}
                placeholder="0"
                className="hours-input"
              />
              
              <div className="rate-label">Special Equipment</div>
              <input 
                type="number" 
                min="0" 
                step="0.01"
                value={driverRates.specialEquipment.rate} 
                onChange={(e) => handleRateChange('specialEquipment', 'rate', e.target.value)}
                placeholder="0.00"
                className="rate-input"
              />
              <input 
                type="number" 
                min="0" 
                value={driverRates.specialEquipment.hours} 
                onChange={(e) => handleRateChange('specialEquipment', 'hours', e.target.value)}
                placeholder="0"
                className="hours-input"
              />
              
              <div className="rate-label">Class A</div>
              <input 
                type="number" 
                min="0" 
                step="0.01"
                value={driverRates.classA.rate} 
                onChange={(e) => handleRateChange('classA', 'rate', e.target.value)}
                placeholder="0.00"
                className="rate-input"
              />
              <input 
                type="number" 
                min="0" 
                value={driverRates.classA.hours} 
                onChange={(e) => handleRateChange('classA', 'hours', e.target.value)}
                placeholder="0"
                className="hours-input"
              />
              
              <div className="rate-label">Class B</div>
              <input 
                type="number" 
                min="0" 
                step="0.01"
                value={driverRates.classB.rate} 
                onChange={(e) => handleRateChange('classB', 'rate', e.target.value)}
                placeholder="0.00"
                className="rate-input"
              />
              <input 
                type="number" 
                min="0" 
                value={driverRates.classB.hours} 
                onChange={(e) => handleRateChange('classB', 'hours', e.target.value)}
                placeholder="0"
                className="hours-input"
              />
            </div>
            
            {!showCustom ? (
              <button 
                type="button" 
                className="add-custom-button"
                onClick={() => setShowCustom(true)}
              >
                + Add Custom Position
              </button>
            ) : (
              <div className="custom-position-row">
                <div className="custom-position-grid">
                  <input 
                    type="text"
                    value={customPosition.name}
                    onChange={(e) => handleCustomChange('name', e.target.value)}
                    placeholder="Position Name"
                    className="custom-name-input"
                  />
                  <input 
                    type="number"
                    min="0"
                    step="0.01"
                    value={customPosition.rate}
                    onChange={(e) => handleCustomChange('rate', e.target.value)}
                    placeholder="Rate"
                    className="rate-input"
                  />
                  <input 
                    type="number"
                    min="0"
                    value={customPosition.hours}
                    onChange={(e) => handleCustomChange('hours', e.target.value)}
                    placeholder="Hours"
                    className="hours-input"
                  />
                  <button 
                    type="button"
                    className="remove-custom-button"
                    onClick={() => {
                      setShowCustom(false);
                      setCustomPosition({ name: '', rate: '', hours: '0' });
                    }}
                  >
                    ×
                  </button>
                </div>
              </div>
            )}
          </div>
          
          <div className="modal-buttons">
            <button 
              type="submit" 
              className={`save-button ${saveStatus?.loading ? 'saving' : saveStatus?.success ? 'saved' : ''}`}
              disabled={saveStatus?.loading}
            >
              {saveStatus?.loading ? (
                <>
                  <Loader className="icon-spin" size={16} />
                  Saving...
                </>
              ) : saveStatus?.success ? (
                <>
                  <CheckCircle size={16} />
                  Saved!
                </>
              ) : (
                <>
                  <Save size={16} />
                  Save Rates
                </>
              )}
            </button>
            <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
          </div>
          
          {saveStatus?.error && (
            <div className="save-error">
              <AlertTriangle size={16} />
              <span>{saveStatus.error}</span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

// EQUIPMENT SELECTOR COMPONENT
const EquipmentSelector = ({ onChange, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content equipment-selector" onClick={e => e.stopPropagation()}>
        <h3 className="text-lg font-semibold mb-4">Select Equipment</h3>
        <div className="menu-options">
          {EQUIPMENT_TYPES.map(equipment => (
            <button
              key={equipment}
              className="menu-item bg-blue-50 hover:bg-blue-100 text-blue-700 border-blue-200"
              onClick={() => {
                onChange(equipment);
                onClose();
              }}
            >
              {equipment}
            </button>
          ))}
        </div>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

// Improve AddSectionMenu component
const AddSectionMenu = ({ isOpen, onClose, onSelect }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  if (!isOpen) return null;

  const handleClick = (key) => {
    if (activeSubmenu === key) {
      setActiveSubmenu(null);
    } else {
      setActiveSubmenu(key);
    }
  };

  // Prevent close when clicking inside the modal
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={handleModalClick}>
        <h3>Add Budget Item</h3>
        <div className="menu-options">
          {Object.entries(BUDGET_SECTIONS).map(([key, section]) => (
            <div key={key}>
              {section.type === 'submenu' ? (
                <>
                  <button 
                    className="menu-item"
                    onClick={() => handleClick(key)}
                  >
                    {key}
                    <ChevronRight 
                      size={16} 
                      className={activeSubmenu === key ? 'rotate-90' : ''}
                    />
                  </button>
                  {activeSubmenu === key && (
                    <div className="submenu">
                      {section.options.map(option => (
                        <button
                          key={option.id}
                          className="menu-item"
                          onClick={() => onSelect({
                            id: option.id,
                            label: option.label,
                            category: key,
                            hasPositions: option.hasPositions,
                            hasEquipment: option.hasEquipment
                          })}
                        >
                          {option.label}
                        </button>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <button
                  className="menu-item"
                  onClick={() => onSelect({
                    id: section.id,
                    label: section.label || key,
                    hasPositions: false,
                    category: key
                  })}
                >
                  {section.label || key}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// MAIN COMPONENT (FULL IMPLEMENTATION)
const BudgetSetupPage = () => {
  // Auth context
  const { user } = useContext(AuthContext);
  
  // Project info state
  const [projectInfo, setProjectInfo] = useState({
    projectName: '',
    company: '',
    budgetDate: new Date(),
    totalDays: '',
    location: 'Other',
    customLocation: '', // For when "Other" is selected
    teamsterRates: 'Local 399 (LA)',
    productionType: 'feature', // Default to feature film
    episodeBlocks: [{ blockName: 'Block 1', episodes: '1-4', budget: { sections: [] } }] // Default block if TV show
  });
  
  // Budget sections state
  const [sections, setSections] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPositionSelectorOpen, setIsPositionSelectorOpen] = useState(false);
  const [isEquipmentSelectorOpen, setIsEquipmentSelectorOpen] = useState(false);
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);
  
  // Add state for rate settings modal
  const [rateSettingsOpen, setRateSettingsOpen] = useState(false);
  const [defaultRates, setDefaultRates] = useState({
    coordinator: { rate: '40.00', hours: '0' },
    captain: { rate: '35.00', hours: '0' },
    coCaptain: { rate: '32.00', hours: '0' },
    dotDispatch: { rate: '30.00', hours: '0' },
    specialEquipment: { rate: '35.00', hours: '0' },
    classA: { rate: '28.00', hours: '0' },
    classB: { rate: '25.00', hours: '0' }
  });
  
  // Add state for rate settings save status
  const [rateSaveStatus, setRateSaveStatus] = useState({
    loading: false,
    success: false,
    error: null
  });

  // Budget saving state
  const [budgetSaveStatus, setBudgetSaveStatus] = useState({
    loading: false,
    success: false,
    error: null
  });

  // Track active episode block (for TV shows)
  const [activeBlockIndex, setActiveBlockIndex] = useState(0);
  
  // Track whether a budget has been loaded
  const [isBudgetLoaded, setIsBudgetLoaded] = useState(false);
  
  // New scroll states for floating buttons
  const [showFloatingAddButton, setShowFloatingAddButton] = useState(false);
  const [showFloatingSaveButton, setShowFloatingSaveButton] = useState(false);

  // Save budget function
  const saveBudget = async () => {
    if (!user) {
      setBudgetSaveStatus({
        loading: false,
        success: false,
        error: 'You must be logged in to save the budget'
      });
      return;
    }

    try {
      setBudgetSaveStatus({ loading: true, success: false, error: null });
      
      // Prepare data to save based on production type
      let budgetData;
      if (projectInfo.productionType === 'feature') {
        budgetData = {
          projectInfo,
          sections,
          updatedAt: serverTimestamp()
        };
      } else {
        // For TV shows, we need to make sure we're saving the full episode blocks with their budget sections
        const episodeBlocksWithBudgets = [...projectInfo.episodeBlocks];
        
        // Make sure each episode block has its budget property properly set
        episodeBlocksWithBudgets.forEach(block => {
          if (!block.budget) {
            block.budget = { sections: [] };
          }
        });
        
        budgetData = {
          projectInfo: {
            ...projectInfo,
            episodeBlocks: episodeBlocksWithBudgets
          },
          sections: [], // Main sections array is empty for TV shows
          updatedAt: serverTimestamp()
        };
      }
      
      console.log("Saving budget data:", JSON.stringify(budgetData));
      
      // Save to Firestore
      const budgetRef = doc(db, 'users', user.uid, 'budgets', 'currentBudget');
      await setDoc(budgetRef, budgetData);
      
      // Verify the save by immediately reading it back
      const savedDoc = await getDoc(budgetRef);
      if (savedDoc.exists()) {
        console.log("Budget verified as saved. Data:", savedDoc.data());
      } else {
        console.error("Failed to verify budget save - document doesn't exist after saving");
      }
      
      setBudgetSaveStatus({ loading: false, success: true, error: null });
      
      // Reset success after 3 seconds
      setTimeout(() => {
        setBudgetSaveStatus(prev => ({ ...prev, success: false }));
      }, 3000);
    } catch (error) {
      console.error("Error saving budget:", error);
      setBudgetSaveStatus({ loading: false, success: false, error: error.message });
    }
  };

  useEffect(() => {
    // Update local storage whenever sections change
    localStorage.setItem('budgetSections', JSON.stringify(sections));
  }, [sections]);

  // Load saved rates and budget when component mounts
  useEffect(() => {
    const loadSavedRatesAndBudget = async () => {
      if (!user) return;
      
      try {
        // Load saved rates
        const ratesDoc = await getDoc(doc(db, 'users', user.uid, 'settings', 'driverRates'));
        if (ratesDoc.exists()) {
          const savedRates = ratesDoc.data().rates;
          if (savedRates) {
            setDefaultRates(savedRates);
            console.log("Loaded saved rates:", savedRates);
          }
        }

        // Load saved budget
        const budgetRef = doc(db, 'users', user.uid, 'budgets', 'currentBudget');
        const budgetDoc = await getDoc(budgetRef);
        
        if (budgetDoc.exists()) {
          const budgetData = budgetDoc.data();
          console.log("Loaded budget data:", budgetData);
          
          // Mark that a budget has been loaded
          setIsBudgetLoaded(true);
          
          // Update project info if it exists
          if (budgetData.projectInfo) {
            // Make sure the budgetDate is a proper Date object
            const projectInfoFixed = { ...budgetData.projectInfo };
            
            // Convert the budgetDate from Firestore timestamp to JS Date if needed
            if (projectInfoFixed.budgetDate && typeof projectInfoFixed.budgetDate.toDate === 'function') {
              projectInfoFixed.budgetDate = projectInfoFixed.budgetDate.toDate();
            } else if (!projectInfoFixed.budgetDate || !(projectInfoFixed.budgetDate instanceof Date)) {
              // If budgetDate is missing or not a Date, initialize it to today
              projectInfoFixed.budgetDate = new Date();
            }
            
            setProjectInfo(projectInfoFixed);
            console.log("Set project info with fixed date:", projectInfoFixed);
          }
          
          // Update sections if they exist and we're in feature film mode
          if (budgetData.sections && budgetData.sections.length > 0) {
            setSections(budgetData.sections);
            console.log("Set sections:", budgetData.sections);
          }
          
          // If this is a TV show with episode blocks that have budget sections
          if (budgetData.projectInfo && 
              budgetData.projectInfo.productionType === 'episodic' && 
              budgetData.projectInfo.episodeBlocks) {
            console.log("Loading TV show with episode blocks:", budgetData.projectInfo.episodeBlocks);
          }
          
          console.log('Budget loaded successfully');
        } else {
          console.log('No saved budget found in database');
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadSavedRatesAndBudget();
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      // Get position of the main Add Budget Item button
      const addBudgetButton = document.getElementById('main-add-budget-button');
      const saveBudgetButton = document.getElementById('main-save-budget-button');
      
      if (addBudgetButton) {
        const buttonPosition = addBudgetButton.getBoundingClientRect().top;
        // Show floating button when the main button scrolls out of view
        setShowFloatingAddButton(buttonPosition < 0);
      }
      
      if (saveBudgetButton) {
        const buttonPosition = saveBudgetButton.getBoundingClientRect().top;
        // Show floating save button when the main save button scrolls out of view
        setShowFloatingSaveButton(buttonPosition < 0);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // EPISODE BLOCK HANDLERS
  const addEpisodeBlock = () => {
    setProjectInfo(prev => {
      const newBlock = {
        blockName: `Block ${prev.episodeBlocks.length + 1}`,
        episodes: '',
        budget: { sections: [] }
      };
      return {
        ...prev,
        episodeBlocks: [...prev.episodeBlocks, newBlock]
      };
    });
  };

  const removeEpisodeBlock = (index) => {
    if (projectInfo.episodeBlocks.length <= 1) {
      return; // Prevent removing the last block
    }
    
    setProjectInfo(prev => {
      const updatedBlocks = [...prev.episodeBlocks];
      updatedBlocks.splice(index, 1);
      
      // Adjust activeBlockIndex if necessary
      if (activeBlockIndex >= index && activeBlockIndex > 0) {
        setActiveBlockIndex(activeBlockIndex - 1);
      }
      
      return {
        ...prev,
        episodeBlocks: updatedBlocks
      };
    });
  };

  const updateEpisodeBlock = (blockIndex, field, value) => {
    setProjectInfo(prev => {
      const updatedBlocks = [...prev.episodeBlocks];
      const activeBlock = updatedBlocks[blockIndex];
      
      updatedBlocks[blockIndex] = {
        ...updatedBlocks[blockIndex],
        [field]: value
      };
      
      return {
        ...prev,
        episodeBlocks: updatedBlocks
      };
    });
  };
  
  // Update project info fields
  const handleProjectInfoChange = (field, value) => {
    if (field === 'productionType') {
      console.log('Changing production type to:', value);
      setProjectInfo(prev => ({
        ...prev,
        productionType: value,
        // If switching to episodic and no blocks exist, create a default one
        episodeBlocks: value === 'episodic' && (!prev.episodeBlocks || prev.episodeBlocks.length === 0) 
          ? [{ blockName: 'Block 1', episodes: '1-4', budget: { sections: [] } }] 
          : prev.episodeBlocks || []
      }));
      // Reset to the first block when switching production types
      setActiveBlockIndex(0);
      return;
    }
    
    setProjectInfo(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Calculate the grand total of all sections
  const calculateGrandTotal = () => {
    const currentSections = getCurrentSections();
    
    return currentSections.reduce((total, section) => {
      // For sections with phases
      if (section.phases) {
        return total + section.phases.reduce((phaseTotal, phase) => {
          if (phase.position) {
            // For position items (labor), multiply days by rate by hours
            const prepTotal = (parseFloat(phase.prepDays) || 0) * (parseFloat(phase.rate) || 0) * (parseFloat(phase.prepHours) || 0);
            const shootTotal = (parseFloat(phase.shootDays) || 0) * (parseFloat(phase.rate) || 0) * (parseFloat(phase.shootHours) || 0);
            const wrapTotal = (parseFloat(phase.wrapDays) || 0) * (parseFloat(phase.rate) || 0) * (parseFloat(phase.wrapHours) || 0);
            return phaseTotal + prepTotal + shootTotal + wrapTotal;
          } else if (phase.equipment) {
            // For equipment items, calculate based on weekly rentals (5-day work week)
            const prepDays = parseFloat(phase.prepDays) || 0;
            const shootDays = parseFloat(phase.shootDays) || 0;
            const wrapDays = parseFloat(phase.wrapDays) || 0;
            const rate = parseFloat(phase.rate) || 0;
            
            // Use the helper function for consistency
            const prepTotal = calculateEquipmentRentalCost(prepDays, rate);
            const shootTotal = calculateEquipmentRentalCost(shootDays, rate);
            const wrapTotal = calculateEquipmentRentalCost(wrapDays, rate);
            
            return phaseTotal + prepTotal + shootTotal + wrapTotal;
          } else {
            // For other items, just multiply days by rate
            const prepTotal = (parseFloat(phase.prepDays) || 0) * (parseFloat(phase.rate) || 0);
            const shootTotal = (parseFloat(phase.shootDays) || 0) * (parseFloat(phase.rate) || 0);
            const wrapTotal = (parseFloat(phase.wrapDays) || 0) * (parseFloat(phase.rate) || 0);
            return phaseTotal + prepTotal + shootTotal + wrapTotal;
          }
        }, 0);
      }
      // For sections with custom fields
      else if (section.customFields) {
        return total + section.customFields.reduce((fieldTotal, field) => {
          return fieldTotal + (parseFloat(field.quantity) || 0) * (parseFloat(field.rate) || 0);
        }, 0);
      }
      // For single items
      else if (section.amount) {
        return total + (parseFloat(section.amount) || 0);
      }
      return total;
    }, 0);
  };

  // Add a section (modified for episode blocks)
  const addSection = (sectionInfo) => {
    const newSection = {
      id: sectionInfo.id,
      label: sectionInfo.label,
      category: sectionInfo.category || '',
      hasPositions: sectionInfo.hasPositions || false,
      hasEquipment: sectionInfo.hasEquipment || false,
      phases: []
    };
    
    if (projectInfo.productionType === 'feature') {
      setSections(prev => [...prev, newSection]);
    } else {
      // For episodic, add the section to the active block's budget
      setProjectInfo(prev => {
        const updatedBlocks = [...prev.episodeBlocks];
        const activeBlock = updatedBlocks[activeBlockIndex];
        
        // Initialize budget object if it doesn't exist
        if (!activeBlock.budget) {
          activeBlock.budget = { sections: [] };
        }
        
        // Add the new section
        activeBlock.budget.sections = [...activeBlock.budget.sections, newSection];
        
        return {
          ...prev,
          episodeBlocks: updatedBlocks
        };
      });
    }
    
    setIsMenuOpen(false);
  };

  // Add a phase to a section (modified for episode blocks)
  const addPhaseToSection = (sectionIndex) => {
    const newPhase = {
      rate: "",
      position: "",
      equipment: "",
      prepDays: "0",
      prepHours: "0",
      shootDays: "0",
      shootHours: "0",
      wrapDays: "0",
      wrapHours: "0"
    };
    
    if (projectInfo.productionType === 'feature') {
      setSections(prev => {
        const updatedSections = [...prev];
        updatedSections[sectionIndex].phases.push(newPhase);
        return updatedSections;
      });
    } else {
      // For episodic, add the phase to the active block's budget
      setProjectInfo(prev => {
        const updatedBlocks = [...prev.episodeBlocks];
        const activeBlock = updatedBlocks[activeBlockIndex];
        
        activeBlock.budget.sections[sectionIndex].phases.push(newPhase);
        
        return {
          ...prev,
          episodeBlocks: updatedBlocks
        };
      });
    }
  };

  // Update a phase in a section (modified for episode blocks)
  const updatePhase = (sectionIndex, phaseIndex, updatedPhase) => {
    if (projectInfo.productionType === 'feature') {
      setSections(prev => {
        const updatedSections = [...prev];
        updatedSections[sectionIndex].phases[phaseIndex] = updatedPhase;
        return updatedSections;
      });
    } else {
      // For episodic, update the phase in the active block's budget
      setProjectInfo(prev => {
        const updatedBlocks = [...prev.episodeBlocks];
        const activeBlock = updatedBlocks[activeBlockIndex];
        
        activeBlock.budget.sections[sectionIndex].phases[phaseIndex] = updatedPhase;
        
        return {
          ...prev,
          episodeBlocks: updatedBlocks
        };
      });
    }
  };

  // Remove a phase from a section (modified for episode blocks)
  const removePhase = (sectionIndex, phaseIndex) => {
    if (projectInfo.productionType === 'feature') {
      setSections(prev => {
        const updatedSections = [...prev];
        updatedSections[sectionIndex].phases.splice(phaseIndex, 1);
        return updatedSections;
      });
    } else {
      // For episodic, remove the phase from the active block's budget
      setProjectInfo(prev => {
        const updatedBlocks = [...prev.episodeBlocks];
        const activeBlock = updatedBlocks[activeBlockIndex];
        
        activeBlock.budget.sections[sectionIndex].phases.splice(phaseIndex, 1);
        
        return {
          ...prev,
          episodeBlocks: updatedBlocks
        };
      });
    }
  };

  // Get the current sections based on production type
  const getCurrentSections = () => {
    if (projectInfo.productionType === 'feature') {
      return sections;
    } else {
      // For episodic, get the sections from the active block's budget
      const activeBlock = projectInfo.episodeBlocks[activeBlockIndex];
      return activeBlock?.budget?.sections || [];
    }
  };

  return (
    <div className="budget-container">
      <Sidebar />
      <div className="budget-content">
        <div className="budget-content-inner">
          <h1>Budget Setup</h1>
          
          <button 
            id="main-save-budget-button"
            className={`save-button ${budgetSaveStatus.loading ? 'saving' : budgetSaveStatus.success ? 'saved' : ''}`}
            onClick={saveBudget}
            disabled={budgetSaveStatus.loading}
          >
            {budgetSaveStatus.loading ? (
              <>
                <Loader className="icon-spin" size={16} />
                Saving...
              </>
            ) : budgetSaveStatus.success ? (
              <>
                <CheckCircle size={16} />
                Saved!
              </>
            ) : (
              <>
                <Save size={16} />
                {isBudgetLoaded ? 'Update Budget' : 'Save Budget'}
              </>
            )}
          </button>
          
          {budgetSaveStatus.error && (
            <div className="save-error">
              <AlertTriangle size={16} />
              <span>{budgetSaveStatus.error}</span>
            </div>
          )}
          
          <h2>Project Information</h2>
          <div className="project-info">
            <div className="input-group">
              <label>Project Name</label>
              <input 
                type="text" 
                value={projectInfo.projectName} 
                onChange={(e) => handleProjectInfoChange('projectName', e.target.value)}
                placeholder="Enter project name"
              />
            </div>
            
            <div className="input-group">
              <label>Production Company</label>
              <input 
                type="text" 
                value={projectInfo.company} 
                onChange={(e) => handleProjectInfoChange('company', e.target.value)}
                placeholder="Enter company name"
              />
            </div>
            
            <div className="input-group">
              <label>Budget Date</label>
              <DatePicker 
                selected={projectInfo.budgetDate} 
                onChange={(date) => handleProjectInfoChange('budgetDate', date)}
                className="date-picker"
              />
            </div>
            
            <div className="input-group">
              <label>Total Production Days</label>
              <input 
                type="number" 
                min="1"
                value={projectInfo.totalDays} 
                onChange={(e) => handleProjectInfoChange('totalDays', e.target.value)}
                placeholder="Enter total days"
              />
            </div>
            
            <div className="input-group">
              <label>Location</label>
              <select 
                value={projectInfo.location} 
                onChange={(e) => handleProjectInfoChange('location', e.target.value)}
              >
                <option value="Los Angeles">Los Angeles</option>
                <option value="New York">New York</option>
                <option value="Atlanta">Atlanta</option>
                <option value="Chicago">Chicago</option>
                <option value="Other">Other</option>
              </select>
            </div>
            
            {/* Show custom location input when "Other" is selected */}
            {projectInfo.location === 'Other' && (
              <div className="input-group">
                <label>Custom Location</label>
                <input 
                  type="text" 
                  value={projectInfo.customLocation} 
                  onChange={(e) => handleProjectInfoChange('customLocation', e.target.value)}
                  placeholder="Enter custom location"
                />
              </div>
            )}
            
            <div className="input-group">
              <label>Teamster Rates</label>
              <select 
                value={projectInfo.teamsterRates} 
                onChange={(e) => handleProjectInfoChange('teamsterRates', e.target.value)}
              >
                <option value="Local 399 (LA)">Local 399 (LA)</option>
                <option value="Local 817 (NY)">Local 817 (NY)</option>
                <option value="Local 728 (GA)">Local 728 (GA)</option>
                <option value="Local 727 (FL)">Local 727 (FL)</option>
              </select>
            </div>
            
            {/* Production Type Radio Buttons */}
            <div className="input-group production-type-group">
              <label>Production Type</label>
              <div className="radio-group">
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="productionType" 
                    value="feature" 
                    checked={projectInfo.productionType === 'feature'}
                    onChange={() => handleProjectInfoChange('productionType', 'feature')}
                  />
                  <span>Feature Film</span>
                </label>
                <label className="radio-label">
                  <input 
                    type="radio" 
                    name="productionType" 
                    value="episodic" 
                    checked={projectInfo.productionType === 'episodic'}
                    onChange={() => handleProjectInfoChange('productionType', 'episodic')}
                  />
                  <span>Episodic (TV)</span>
                </label>
              </div>
            </div>
          </div>
          
          {/* Episode Blocks Section for TV Shows */}
          {projectInfo.productionType === 'episodic' && (
            <div className="episode-blocks-section">
              <h3>Episode Blocks</h3>
              <p>Define budget blocks for groups of episodes</p>
              
              {projectInfo.episodeBlocks.map((block, index) => (
                <div key={index} className="episode-block">
                  <div className="block-header">
                    <h4>Block {index + 1}</h4>
                    {projectInfo.episodeBlocks.length > 1 && (
                      <button 
                        onClick={() => removeEpisodeBlock(index)}
                        className="remove-block-button"
                      >
                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none">
                          <polyline points="3 6 5 6 21 6" />
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                          <line x1="10" y1="11" x2="10" y2="17" />
                          <line x1="14" y1="11" x2="14" y2="17" />
                        </svg>
                      </button>
                    )}
                  </div>
                  
                  <div className="block-inputs">
                    <div className="input-group">
                      <label>Block Name</label>
                      <input 
                        type="text" 
                        value={block.blockName} 
                        onChange={(e) => updateEpisodeBlock(index, 'blockName', e.target.value)}
                        placeholder="e.g., Block 1, Pilot"
                      />
                    </div>
                    
                    <div className="input-group">
                      <label>Episodes</label>
                      <input 
                        type="text" 
                        value={block.episodes} 
                        onChange={(e) => updateEpisodeBlock(index, 'episodes', e.target.value)}
                        placeholder="e.g., 1-4, Pilot"
                      />
                    </div>
                  </div>
                </div>
              ))}
              
              <button 
                onClick={addEpisodeBlock}
                className="add-block-button"
              >
                <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none">
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
                Add Episode Block
              </button>
            </div>
          )}
          
          {/* Active Block Display for TV Shows */}
          {projectInfo.productionType === 'episodic' && projectInfo.episodeBlocks.length > 0 && (
            <div className="block-selector">
              <h3>Select Active Episode Block</h3>
              <div className="block-tabs">
                {projectInfo.episodeBlocks.map((block, index) => (
                  <button
                    key={index}
                    className={`block-tab ${activeBlockIndex === index ? 'active' : ''}`}
                    onClick={() => setActiveBlockIndex(index)}
                  >
                    {block.blockName} ({block.episodes})
                  </button>
                ))}
              </div>
            </div>
          )}
          
          {/* Budget summary */}
          <div className="budget-summary">
            <div className="summary-card">
              <h3>Grand Total</h3>
              <div className="total-amount">${formatCurrency(calculateGrandTotal())}</div>
            </div>
          </div>
          
          {/* Budget sections */}
          <div className="budget-actions">
            <button 
              id="main-add-budget-button"
              onClick={() => setIsMenuOpen(true)}
              className="add-section-button"
            >
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none">
                <line x1="12" y1="5" x2="12" y2="19" />
                <line x1="5" y1="12" x2="19" y2="12" />
              </svg>
              Add Budget Section
            </button>
          </div>
          
          {/* Floating Add Budget Item button */}
          {showFloatingAddButton && (
            <button 
              className="floating-add-button"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none">
                <line x1="12" y1="5" x2="12" y2="19" />
                <line x1="5" y1="12" x2="19" y2="12" />
              </svg>
              Add Budget Section
            </button>
          )}
          
          {/* Floating Save/Update Budget button */}
          {showFloatingSaveButton && (
            <button 
              className="floating-save-button"
              onClick={saveBudget}
              disabled={budgetSaveStatus.loading}
            >
              {budgetSaveStatus.loading ? (
                <>
                  <Loader className="icon-spin" size={16} />
                  Saving...
                </>
              ) : budgetSaveStatus.success ? (
                <>
                  <CheckCircle size={16} />
                  Saved!
                </>
              ) : (
                <>
                  <Save size={16} />
                  {isBudgetLoaded ? 'Update Budget' : 'Save Budget'}
                </>
              )}
            </button>
          )}
          
          {/* Floating Grand Total */}
          <div className="floating-grand-total">
            <h3>Grand Total</h3>
            <div className="total-amount">${formatCurrency(calculateGrandTotal())}</div>
          </div>
          
          {/* Sections */}
          {getCurrentSections().length === 0 ? (
            <div className="empty-state">
              <p>No budget items added yet. Click "Add Budget Item" to get started.</p>
            </div>
          ) : (
            getCurrentSections().map((section, sectionIndex) => (
              <div key={sectionIndex} className="section-item">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-3">
                    <h3 className="text-lg font-medium text-gray-800">{section.label}</h3>
                    {section.id === "3501" && (
                      <button 
                        className="set-rates-button"
                        onClick={() => setRateSettingsOpen(true)}
                      >
                        Set Rates
                      </button>
                    )}
                  </div>
                  <button 
                    onClick={() => {
                      if (projectInfo.productionType === 'feature') {
                        setSections(prev => prev.filter(s => s !== section));
                      } else {
                        const updatedBlocks = [...projectInfo.episodeBlocks];
                        const activeBlock = updatedBlocks[activeBlockIndex];
                        
                        activeBlock.budget.sections = activeBlock.budget.sections.filter(s => s !== section);
                        setProjectInfo(prev => ({ ...prev, episodeBlocks: updatedBlocks }));
                      }
                    }}
                    className="text-gray-400 hover:text-red-500"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
                
                {section.phases && section.phases.map((phase, phaseIndex) => (
                  <div key={phaseIndex} className="phase-item">
                    <PhaseInput 
                      phase={phase}
                      phaseIndex={phaseIndex}
                      section={section}
                      updatePhase={(phaseIndex, updatedPhase) => updatePhase(sectionIndex, phaseIndex, updatedPhase)}
                      location={projectInfo.location}
                      defaultRates={defaultRates}
                      openPositionSelector={() => {
                        setCurrentPhaseIndex(phaseIndex);
                        setCurrentSectionIndex(sectionIndex);
                        setIsPositionSelectorOpen(true);
                      }}
                      openEquipmentSelector={() => {
                        setCurrentPhaseIndex(phaseIndex);
                        setCurrentSectionIndex(sectionIndex);
                        setIsEquipmentSelectorOpen(true);
                      }}
                      removePhase={() => removePhase(sectionIndex, phaseIndex)}
                    />
                  </div>
                ))}
                
                {section.hasCustomFields && (
                  <CustomFieldsInput 
                    section={section}
                    updateSection={(updatedSection) => {
                      if (projectInfo.productionType === 'feature') {
                        setSections(prev => prev.map(s => s.id === section.id ? updatedSection : s));
                      } else {
                        const updatedBlocks = [...projectInfo.episodeBlocks];
                        const activeBlock = updatedBlocks[activeBlockIndex];
                        
                        activeBlock.budget.sections = activeBlock.budget.sections.map(s => s.id === section.id ? updatedSection : s);
                        setProjectInfo(prev => ({ ...prev, episodeBlocks: updatedBlocks }));
                      }
                    }}
                    removeSection={() => {
                      if (projectInfo.productionType === 'feature') {
                        setSections(prev => prev.filter(s => s !== section));
                      } else {
                        const updatedBlocks = [...projectInfo.episodeBlocks];
                        const activeBlock = updatedBlocks[activeBlockIndex];
                        
                        activeBlock.budget.sections = activeBlock.budget.sections.filter(s => s !== section);
                        setProjectInfo(prev => ({ ...prev, episodeBlocks: updatedBlocks }));
                      }
                    }}
                  />
                )}
                
                <div className="flex justify-end mt-4">
                  <button 
                    onClick={() => addPhaseToSection(sectionIndex)}
                    className="flex items-center gap-2 text-blue-600 px-3 py-1 rounded-md border border-blue-200 hover:bg-blue-50 transition-colors"
                  >
                    <Plus size={16} />
                    Add Item
                  </button>
                </div>
                
                <div className="category-total">
                  <span>Section Total:</span>
                  <span>${formatCurrency(section.phases.reduce((total, phase) => {
                    if (phase.position) {
                      // For position items (labor), multiply days by rate by hours
                      const prepTotal = (parseFloat(phase.prepDays) || 0) * (parseFloat(phase.rate) || 0) * (parseFloat(phase.prepHours) || 0);
                      const shootTotal = (parseFloat(phase.shootDays) || 0) * (parseFloat(phase.rate) || 0) * (parseFloat(phase.shootHours) || 0);
                      const wrapTotal = (parseFloat(phase.wrapDays) || 0) * (parseFloat(phase.rate) || 0) * (parseFloat(phase.wrapHours) || 0);
                      return total + prepTotal + shootTotal + wrapTotal;
                    } else if (phase.equipment) {
                      // For equipment items, calculate based on weekly rentals (5-day work week)
                      const prepDays = parseFloat(phase.prepDays) || 0;
                      const shootDays = parseFloat(phase.shootDays) || 0;
                      const wrapDays = parseFloat(phase.wrapDays) || 0;
                      const rate = parseFloat(phase.rate) || 0;
                      
                      // Use the helper function for consistency
                      const prepTotal = calculateEquipmentRentalCost(prepDays, rate);
                      const shootTotal = calculateEquipmentRentalCost(shootDays, rate);
                      const wrapTotal = calculateEquipmentRentalCost(wrapDays, rate);
                      
                      return total + prepTotal + shootTotal + wrapTotal;
                    } else {
                      // For other items, just multiply days by rate
                      const prepTotal = (parseFloat(phase.prepDays) || 0) * (parseFloat(phase.rate) || 0);
                      const shootTotal = (parseFloat(phase.shootDays) || 0) * (parseFloat(phase.rate) || 0);
                      const wrapTotal = (parseFloat(phase.wrapDays) || 0) * (parseFloat(phase.rate) || 0);
                      return total + prepTotal + shootTotal + wrapTotal;
                    }
                  }, 0))}</span>
                </div>
              </div>
            ))
          )}
          
          {/* Modals */}
          <AddSectionMenu 
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            onSelect={addSection}
          />
          
          {isPositionSelectorOpen && (
            <PositionSelector
              positions={DRIVER_POSITIONS}
              onChange={(position) => {
                const updatedPhase = { ...getCurrentSections()[currentSectionIndex].phases[currentPhaseIndex], position };
                updatePhase(currentSectionIndex, currentPhaseIndex, updatedPhase);
                setIsPositionSelectorOpen(false);
              }}
              onClose={() => setIsPositionSelectorOpen(false)}
            />
          )}
          
          {isEquipmentSelectorOpen && (
            <EquipmentSelector
              onChange={(equipment) => {
                const updatedPhase = { ...getCurrentSections()[currentSectionIndex].phases[currentPhaseIndex], equipment };
                updatePhase(currentSectionIndex, currentPhaseIndex, updatedPhase);
                setIsEquipmentSelectorOpen(false);
              }}
              onClose={() => setIsEquipmentSelectorOpen(false)}
            />
          )}
          
          {/* Rate Settings Modal */}
          <RateSettingsModal 
            isOpen={rateSettingsOpen}
            onClose={() => setRateSettingsOpen(false)}
            saveStatus={rateSaveStatus}
            saveRates={async (rates) => {
              if (!user) {
                setRateSaveStatus({
                  loading: false,
                  success: false,
                  error: 'You must be logged in to save rates'
                });
                return;
              }

              try {
                setRateSaveStatus({ loading: true, success: false, error: null });
                
                // Update local state
                setDefaultRates(rates);
                
                // Save to Firestore
                await setDoc(doc(db, 'users', user.uid, 'settings', 'driverRates'), {
                  rates,
                  updatedAt: serverTimestamp()
                });
                
                setRateSaveStatus({ loading: false, success: true, error: null });
                
                // Reset success after 3 seconds
                setTimeout(() => {
                  setRateSaveStatus(prev => ({ ...prev, success: false }));
                }, 3000);
                
                console.log("Saved default rates:", rates);
              } catch (error) {
                console.error("Error saving rates:", error);
                setRateSaveStatus({ loading: false, success: false, error: error.message });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BudgetSetupPage;