import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db, auth } from '../../../firebase';
import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  addDoc,
  updateDoc,
  query,
  where,
  serverTimestamp,
  orderBy
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import VisualCallsheetHeader from './VisualCallsheetHeader';
import VisualSceneSchedule from './VisualSceneSchedule';
import VisualCrewCallTimes from './VisualCrewCallTimes';

const CallsheetContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${props => props.active ? '#4299e1' : 'transparent'};
  color: ${props => props.active ? 'white' : '#4a5568'};
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  
  &:hover {
    background-color: ${props => props.active ? '#4299e1' : '#e2e8f0'};
  }
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: ${props => props.primary ? '#4299e1' : '#e2e8f0'};
  color: ${props => props.primary ? 'white' : '#4a5568'};
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  
  &:hover {
    background-color: ${props => props.primary ? '#3182ce' : '#cbd5e0'};
  }
  
  &:last-child {
    margin-right: 0;
  }
`;

const SuccessMessage = styled.div`
  padding: 10px;
  background-color: #c6f6d5;
  border: 1px solid #9ae6b4;
  color: #276749;
  border-radius: 4px;
  margin-bottom: 20px;
  display: ${props => props.show ? 'block' : 'none'};
`;

const VisualCallsheetBuilder = () => {
  console.log('RENDERING: Visual Callsheet Builder Component');
  
  // User and production state
  const [currentUser, setCurrentUser] = useState(null);
  const [userProduction, setUserProduction] = useState(null);
  
  // UI state
  const [activeTab, setActiveTab] = useState('frontPage');
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  
  // Callsheet data state
  const [callsheetId, setCallsheetId] = useState(null);
  const [header, setHeader] = useState({
    production: '',
    day: '1',
    date: new Date().toISOString().split('T')[0],
    version: '1',
    mainCallTime: '07:00',
    breakfast: '06:30',
    lunch: '13:00',
    director: '',
    firstAD: '',
    secondAD: '',
    dop: '',
    producer: '',
    productionManager: '',
    sunriseTime: '',
    sunsetTime: '',
    crewParking: '',
    nearestHospital: '',
    safetyNotes: '',
    weatherInfo: ''
  });
  
  const [scenes, setScenes] = useState([]);
  const [crewCallTimes, setCrewCallTimes] = useState([]);
  
  // Reference data
  const [departments, setDepartments] = useState([]);
  const [allCrewMembers, setAllCrewMembers] = useState([]);
  
  // Fetch user and production information
  useEffect(() => {
    const fetchUserAndProduction = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          setCurrentUser(user);
          
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserProduction(userData.production);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    
    fetchUserAndProduction();
  }, []);
  
  // Fetch departments and crew members when production is available
  useEffect(() => {
    if (userProduction) {
      fetchDepartments();
      fetchCrewMembers();
      setLoading(false);
    }
  }, [userProduction]);
  
  // Fetch departments
  const fetchDepartments = async () => {
    try {
      const departmentsQuery = query(collection(db, 'departments'));
      const departmentSnapshot = await getDocs(departmentsQuery);
      
      const departmentData = departmentSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setDepartments(departmentData);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };
  
  // Fetch crew members for the current production
  const fetchCrewMembers = async () => {
    try {
      if (!userProduction) return;
      
      const crewQuery = query(
        collection(db, 'users'),
        where('production', '==', userProduction)
      );
      
      const crewSnapshot = await getDocs(crewQuery);
      
      const crewData = crewSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name || data.firstName + ' ' + data.lastName || 'Unknown',
          department: data.department || '',
          role: data.role || '',
          email: data.email || '',
        };
      });
      
      setAllCrewMembers(crewData);
    } catch (error) {
      console.error('Error fetching crew members:', error);
    }
  };
  
  // Header management
  const updateHeaderField = (field, value) => {
    setHeader(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  // Scene management
  const addScene = () => {
    const newScene = {
      id: uuidv4(),
      sceneNumber: '',
      setLocation: '',
      description: '',
      cast: '',
      pages: '',
      startTime: '',
      notes: ''
    };
    
    setScenes(prev => [...prev, newScene]);
  };
  
  const updateScene = (sceneId, field, value) => {
    setScenes(prev => 
      prev.map(scene => 
        scene.id === sceneId 
          ? { ...scene, [field]: value } 
          : scene
      )
    );
  };
  
  const removeScene = (sceneId) => {
    setScenes(prev => prev.filter(scene => scene.id !== sceneId));
  };
  
  const reorderScenes = (oldIndex, newIndex) => {
    setScenes(prev => {
      const updatedScenes = [...prev];
      const [movedScene] = updatedScenes.splice(oldIndex, 1);
      updatedScenes.splice(newIndex, 0, movedScene);
      return updatedScenes;
    });
  };
  
  // Crew call times management
  const addCrewMember = (crewId) => {
    // Check if crew member is already added
    const existingCrew = crewCallTimes.find(crew => crew.id === crewId);
    if (existingCrew) return;
    
    const crewMember = allCrewMembers.find(cm => cm.id === crewId);
    if (!crewMember) return;
    
    const newCrewCallTime = {
      id: crewId,
      name: crewMember.name,
      department: crewMember.department,
      role: crewMember.role,
      callTime: header.mainCallTime || '07:00', // Default to main call time
      location: 'Set',
      notes: ''
    };
    
    setCrewCallTimes(prev => [...prev, newCrewCallTime]);
  };
  
  const updateCrewCallTime = (crewId, field, value) => {
    setCrewCallTimes(prev => 
      prev.map(crew => 
        crew.id === crewId 
          ? { ...crew, [field]: value } 
          : crew
      )
    );
  };
  
  const removeCrewMember = (crewId) => {
    setCrewCallTimes(prev => prev.filter(crew => crew.id !== crewId));
  };
  
  const reorderCrewMembers = (oldIndex, newIndex) => {
    setCrewCallTimes(prev => {
      const updatedCrewCallTimes = [...prev];
      const [movedCrew] = updatedCrewCallTimes.splice(oldIndex, 1);
      updatedCrewCallTimes.splice(newIndex, 0, movedCrew);
      return updatedCrewCallTimes;
    });
  };
  
  // Generate list of available crew members (those not already added)
  const getAvailableCrewMembers = () => {
    return allCrewMembers.filter(crew => 
      !crewCallTimes.some(addedCrew => addedCrew.id === crew.id)
    );
  };
  
  // Save callsheet to Firestore
  const saveCallsheet = async () => {
    try {
      setSaving(true);
      
      const callsheetData = {
        production: userProduction,
        header,
        scenes,
        crewCallTimes,
        createdBy: currentUser.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      if (callsheetId) {
        // Update existing callsheet
        await updateDoc(doc(db, 'callsheets', callsheetId), callsheetData);
      } else {
        // Create new callsheet
        const newCallsheetRef = await addDoc(collection(db, 'callsheets'), callsheetData);
        setCallsheetId(newCallsheetRef.id);
      }
      
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (error) {
      console.error('Error saving callsheet:', error);
      alert('Error saving callsheet: ' + error.message);
    } finally {
      setSaving(false);
    }
  };
  
  // Load an existing callsheet (placeholder for future implementation)
  const loadCallsheet = async (id) => {
    try {
      setLoading(true);
      
      const callsheetDoc = await getDoc(doc(db, 'callsheets', id));
      if (callsheetDoc.exists()) {
        const data = callsheetDoc.data();
        
        setCallsheetId(id);
        setHeader(data.header);
        setScenes(data.scenes);
        setCrewCallTimes(data.crewCallTimes);
      }
    } catch (error) {
      console.error('Error loading callsheet:', error);
      alert('Error loading callsheet: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  // Export callsheet to PDF
  const exportToPDF = () => {
    // Create new PDF document
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    });
    
    // Set title and metadata
    doc.setProperties({
      title: `${header.production} - Call Sheet Day ${header.day}`,
      subject: 'Production Call Sheet',
      author: currentUser?.displayName || 'Film Production App',
      creator: 'Film Production App'
    });
    
    // Add header logo/branding
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    
    // Title and Day/Date
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text(`${header.production}`, pageWidth / 2, 15, { align: 'center' });
    
    doc.setFontSize(16);
    doc.text(`CALL SHEET`, pageWidth / 2, 22, { align: 'center' });
    
    doc.setFontSize(12);
    doc.text(`DAY ${header.day} | ${new Date(header.date).toLocaleDateString()} | VERSION ${header.version}`, 
      pageWidth / 2, 29, { align: 'center' });
    
    // Key information section
    doc.setFontSize(10);
    doc.setDrawColor(0);
    doc.setFillColor(240, 240, 240);
    
    // Create main info grid
    const startY = 35;
    const colWidth = pageWidth / 4;
    const rowHeight = 30;
    
    // Draw section 1 - Crew Call
    doc.setFillColor(230, 240, 255); // Light blue for primary sections
    doc.rect(10, startY, colWidth - 2, rowHeight, 'F');
    doc.setFillColor(255, 255, 255);
    
    doc.setFont('helvetica', 'bold');
    doc.text("CREW CALL", 12, startY + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(`MAIN UNIT: ${header.mainCallTime || 'N/A'}`, 12, startY + 11);
    doc.text(`BREAKFAST: ${header.breakfast || 'N/A'}`, 12, startY + 17);
    doc.text(`LUNCH: ${header.lunch || 'N/A'}`, 12, startY + 23);
    
    // Draw section 2 - Key Personnel
    doc.rect(10 + colWidth, startY, colWidth - 2, rowHeight, 'F');
    
    doc.setFont('helvetica', 'bold');
    doc.text("KEY PERSONNEL", 12 + colWidth, startY + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(`DIRECTOR: ${header.director || 'N/A'}`, 12 + colWidth, startY + 11);
    doc.text(`1ST AD: ${header.firstAD || 'N/A'}`, 12 + colWidth, startY + 17);
    doc.text(`DOP: ${header.dop || 'N/A'}`, 12 + colWidth, startY + 23);
    
    // Draw section 3 - Producers
    doc.rect(10 + colWidth * 2, startY, colWidth - 2, rowHeight, 'F');
    
    doc.setFont('helvetica', 'bold');
    doc.text("PRODUCERS", 12 + colWidth * 2, startY + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(`PRODUCER: ${header.producer || 'N/A'}`, 12 + colWidth * 2, startY + 11);
    doc.text(`PM: ${header.productionManager || 'N/A'}`, 12 + colWidth * 2, startY + 17);
    
    // Draw section 4 - Timing & Location
    doc.rect(10 + colWidth * 3, startY, colWidth - 10, rowHeight, 'F');
    
    doc.setFont('helvetica', 'bold');
    doc.text("TIMING & LOCATION", 12 + colWidth * 3, startY + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(`SUNRISE: ${header.sunriseTime || 'N/A'}`, 12 + colWidth * 3, startY + 11);
    doc.text(`SUNSET: ${header.sunsetTime || 'N/A'}`, 12 + colWidth * 3, startY + 17);
    
    // Draw section 5 - Safety
    const row2Y = startY + rowHeight + 2;
    doc.rect(10, row2Y, colWidth * 2 - 2, rowHeight - 10, 'F');
    
    doc.setFont('helvetica', 'bold');
    doc.text("SAFETY", 12, row2Y + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(`NEAREST HOSPITAL: ${header.nearestHospital || 'N/A'}`, 12, row2Y + 11);
    doc.text(`NOTES: ${header.safetyNotes || 'N/A'}`, 12, row2Y + 17);
    
    // Draw section 6 - Weather
    doc.rect(10 + colWidth * 2, row2Y, colWidth * 2 - 10, rowHeight - 10, 'F');
    
    doc.setFont('helvetica', 'bold');
    doc.text("WEATHER", 12 + colWidth * 2, row2Y + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(`FORECAST: ${header.weatherInfo || 'N/A'}`, 12 + colWidth * 2, row2Y + 11);
    
    // Scene schedule
    const scheduleY = row2Y + rowHeight;
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text("SCENE SCHEDULE", 10, scheduleY);
    
    // Generate scene table data
    const sceneColumns = [
      { header: 'SCENE #', dataKey: 'sceneNumber' },
      { header: 'SET/LOCATION', dataKey: 'setLocation' },
      { header: 'DESCRIPTION', dataKey: 'description' },
      { header: 'CAST', dataKey: 'cast' },
      { header: 'PAGES', dataKey: 'pages' },
      { header: 'START', dataKey: 'startTime' },
      { header: 'NOTES', dataKey: 'notes' }
    ];
    
    const sceneData = scenes.map(scene => ({
      sceneNumber: scene.sceneNumber || '',
      setLocation: scene.setLocation || '',
      description: scene.description || '',
      cast: scene.cast || '',
      pages: scene.pages || '',
      startTime: scene.startTime || '',
      notes: scene.notes || ''
    }));
    
    // Add scene table
    if (sceneData.length > 0) {
      doc.autoTable({
        startY: scheduleY + 5,
        head: [sceneColumns.map(col => col.header)],
        body: sceneData.map(row => sceneColumns.map(col => row[col.dataKey])),
        theme: 'grid',
        headStyles: {
          fillColor: [220, 230, 240],
          textColor: [0, 0, 0],
          fontStyle: 'bold'
        },
        columnStyles: {
          0: { cellWidth: 15 }, // SCENE #
          1: { cellWidth: 30 }, // SET/LOCATION
          2: { cellWidth: 40 }, // DESCRIPTION
          3: { cellWidth: 25 }, // CAST
          4: { cellWidth: 15 }, // PAGES
          5: { cellWidth: 20 }, // START TIME
          6: { cellWidth: 30 }  // NOTES
        }
      });
    } else {
      doc.setFontSize(11);
      doc.setFont('helvetica', 'italic');
      doc.text("No scenes scheduled", 10, scheduleY + 15);
    }
    
    // Check if we need to add a new page for crew call times
    const tableEndY = doc.autoTable.previous.finalY || scheduleY + 20;
    if (tableEndY > pageHeight - 40) {
      doc.addPage();
    } else {
      // Add some space after the scene table
      const crewY = tableEndY + 15;
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text("CREW CALL TIMES", 10, crewY);
      
      // Group crew by department
      const crewByDept = {};
      crewCallTimes.forEach(crew => {
        if (!crewByDept[crew.department]) {
          crewByDept[crew.department] = [];
        }
        crewByDept[crew.department].push(crew);
      });
      
      const crewColumns = [
        { header: 'NAME', dataKey: 'name' },
        { header: 'POSITION', dataKey: 'role' },
        { header: 'CALL TIME', dataKey: 'callTime' },
        { header: 'LOCATION', dataKey: 'location' },
        { header: 'NOTES', dataKey: 'notes' }
      ];
      
      let yPosition = crewY + 5;
      
      // Add department tables
      Object.keys(crewByDept).forEach(deptId => {
        // Find department name
        const department = departments.find(d => d.id === deptId);
        const deptName = department ? department.name : 'Unknown Department';
        
        // Department header
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(deptName, 10, yPosition);
        
        // Department crew members
        const deptCrewData = crewByDept[deptId].map(crew => ({
          name: crew.name || '',
          role: crew.role || '',
          callTime: crew.callTime || '',
          location: crew.location || '',
          notes: crew.notes || ''
        }));
        
        // Add crew table for this department
        doc.autoTable({
          startY: yPosition + 2,
          head: [crewColumns.map(col => col.header)],
          body: deptCrewData.map(row => crewColumns.map(col => row[col.dataKey])),
          theme: 'grid',
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [0, 0, 0],
            fontStyle: 'bold'
          },
          styles: {
            fontSize: 9
          },
          columnStyles: {
            0: { cellWidth: 40 }, // NAME
            1: { cellWidth: 40 }, // POSITION
            2: { cellWidth: 20 }, // CALL TIME
            3: { cellWidth: 30 }, // LOCATION
            4: { cellWidth: 40 }  // NOTES
          }
        });
        
        yPosition = doc.autoTable.previous.finalY + 10;
        
        // Add new page if needed
        if (yPosition > pageHeight - 20 && Object.keys(crewByDept).indexOf(deptId) < Object.keys(crewByDept).length - 1) {
          doc.addPage();
          yPosition = 20;
        }
      });
      
      // If no crew members
      if (Object.keys(crewByDept).length === 0) {
        doc.setFontSize(11);
        doc.setFont('helvetica', 'italic');
        doc.text("No crew members assigned", 10, crewY + 15);
      }
    }
    
    // Add workflow note
    doc.setFontSize(9);
    doc.setFont('helvetica', 'italic');
    const noteY = doc.internal.pageSize.getHeight() - 10;
    doc.text("*This callsheet is part of the integrated workflow system linking callsheets, crew times tracking, and production reports.", 
      10, noteY);
    
    // Save the PDF
    doc.save(`${header.production || 'Production'}_CallSheet_Day${header.day || '1'}.pdf`);
  };

  // Return a loading state if data is still being fetched
  if (loading) {
    return <div>Loading callsheet builder...</div>;
  }
  
  return (
    <CallsheetContainer>
      <h1>Visual Callsheet Builder</h1>
      
      <SuccessMessage show={saveSuccess}>
        Callsheet saved successfully!
      </SuccessMessage>
      
      <ActionBar>
        <div>
          <Button primary onClick={saveCallsheet} disabled={saving}>
            {saving ? 'Saving...' : 'Save Callsheet'}
          </Button>
          <Button onClick={exportToPDF}>
            Export PDF
          </Button>
        </div>
      </ActionBar>
      
      <TabContainer>
        <Tab 
          active={activeTab === 'frontPage'} 
          onClick={() => setActiveTab('frontPage')}
        >
          Front Page
        </Tab>
        <Tab 
          active={activeTab === 'backPage'} 
          onClick={() => setActiveTab('backPage')}
        >
          Back Page (Crew Call Times)
        </Tab>
      </TabContainer>
      
      {activeTab === 'frontPage' ? (
        <>
          <VisualCallsheetHeader 
            headerData={header} 
            updateHeaderField={updateHeaderField} 
          />
          <VisualSceneSchedule 
            scenes={scenes}
            addScene={addScene}
            updateScene={updateScene}
            removeScene={removeScene}
            reorderScenes={reorderScenes}
          />
        </>
      ) : (
        <VisualCrewCallTimes 
          crewCallTimes={crewCallTimes}
          departments={departments}
          crewMembers={allCrewMembers}
          addCrewMember={addCrewMember}
          updateCrewCallTime={updateCrewCallTime}
          removeCrewMember={removeCrewMember}
          reorderCrewMembers={reorderCrewMembers}
          availableCrewMembers={getAvailableCrewMembers()}
        />
      )}
    </CallsheetContainer>
  );
};

export default VisualCallsheetBuilder;
