import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, where, orderBy, doc, updateDoc, deleteDoc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import Sidebar from '../../components/Sidebar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/SharedStyles.css';
import './ADStyles.css';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const ProductionReports = () => {
  const { user } = useAuth();
  const { hasAccess, productionAccess, departmentAccess } = useAccessControl();
  
  // State for production report form
  const [reportDate, setReportDate] = useState(new Date());
  const [dayOfProduction, setDayOfProduction] = useState('');
  const [weatherConditions, setWeatherConditions] = useState('');
  const [crewCallTime, setCrewCallTime] = useState('');
  const [firstShotTime, setFirstShotTime] = useState('');
  const [lunchStart, setLunchStart] = useState('');
  const [lunchEnd, setLunchEnd] = useState('');
  const [wrapTime, setWrapTime] = useState('');
  const [scenes, setScenes] = useState([]);
  const [selectedScenes, setSelectedScenes] = useState([]);
  const [pagesCompleted, setPagesCompleted] = useState('');
  const [notes, setNotes] = useState('');
  
  // State for storing and displaying reports
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  
  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // Fetch data on component mount
  useEffect(() => {
    if (hasAccess && departmentAccess === 'Assistant Directors') {
      fetchReports();
      fetchScenes();
    }
  }, [hasAccess, departmentAccess, productionAccess]);
  
  // Fetch production reports
  const fetchReports = async () => {
    try {
      setLoading(true);
      
      const q = query(
        collection(db, 'productionReports'),
        where('production', '==', productionAccess),
        orderBy('reportDate', 'desc')
      );
      
      const snapshot = await getDocs(q);
      const reportsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        reportDate: doc.data().reportDate?.toDate()
      }));
      
      setReports(reportsData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setError('Failed to load reports. Please try again.');
      setLoading(false);
    }
  };
  
  // Fetch scenes for selection
  const fetchScenes = async () => {
    try {
      const q = query(
        collection(db, 'scenes'),
        where('production', '==', productionAccess)
      );
      
      const snapshot = await getDocs(q);
      const scenesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setScenes(scenesData);
    } catch (error) {
      console.error('Error fetching scenes:', error);
    }
  };
  
  // Toggle scene selection
  const toggleSceneSelection = (sceneId) => {
    setSelectedScenes(prevSelected => {
      if (prevSelected.includes(sceneId)) {
        return prevSelected.filter(id => id !== sceneId);
      } else {
        return [...prevSelected, sceneId];
      }
    });
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      
      // Get detailed scene data for selected scenes
      const scenesData = scenes.filter(scene => selectedScenes.includes(scene.id))
        .map(scene => ({
          id: scene.id,
          sceneNumber: scene.sceneNumber,
          description: scene.description,
          pages: scene.pages,
          location: scene.location,
          setting: scene.setting,
          timeOfDay: scene.timeOfDay
        }));
      
      const reportData = {
        production: productionAccess,
        reportDate: Timestamp.fromDate(reportDate),
        dayOfProduction,
        weatherConditions,
        crewCallTime,
        firstShotTime,
        lunchStart,
        lunchEnd,
        wrapTime,
        scenes: scenesData,
        pagesCompleted,
        notes,
        createdBy: user.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      };
      
      if (isEditMode && selectedReport) {
        // Update existing report
        await updateDoc(doc(db, 'productionReports', selectedReport.id), reportData);
        setSuccess('Production report updated successfully');
      } else {
        // Create new report
        await addDoc(collection(db, 'productionReports'), reportData);
        setSuccess('Production report created successfully');
      }
      
      // Reset form and fetch updated reports
      resetForm();
      fetchReports();
      
    } catch (error) {
      console.error('Error saving production report:', error);
      setError('Failed to save production report. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Reset the form
  const resetForm = () => {
    setReportDate(new Date());
    setDayOfProduction('');
    setWeatherConditions('');
    setCrewCallTime('');
    setFirstShotTime('');
    setLunchStart('');
    setLunchEnd('');
    setWrapTime('');
    setSelectedScenes([]);
    setPagesCompleted('');
    setNotes('');
    setIsCreating(false);
    setIsEditMode(false);
    setSelectedReport(null);
    setError(null);
    
    // Clear success message after 3 seconds
    setTimeout(() => {
      setSuccess(null);
    }, 3000);
  };
  
  // Load a report for editing
  const handleEditReport = (report) => {
    setReportDate(report.reportDate);
    setDayOfProduction(report.dayOfProduction);
    setWeatherConditions(report.weatherConditions);
    setCrewCallTime(report.crewCallTime);
    setFirstShotTime(report.firstShotTime);
    setLunchStart(report.lunchStart);
    setLunchEnd(report.lunchEnd);
    setWrapTime(report.wrapTime);
    setSelectedScenes(report.scenes.map(scene => scene.id));
    setPagesCompleted(report.pagesCompleted);
    setNotes(report.notes);
    setSelectedReport(report);
    setIsEditMode(true);
    setIsCreating(true);
  };
  
  // Delete a report
  const handleDeleteReport = async (id) => {
    if (window.confirm('Are you sure you want to delete this production report?')) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, 'productionReports', id));
        setSuccess('Production report deleted successfully');
        fetchReports();
      } catch (error) {
        console.error('Error deleting production report:', error);
        setError('Failed to delete production report. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Export report as PDF
  const exportToPDF = (report) => {
    const doc = new jsPDF();
    
    // Add title
    doc.setFontSize(18);
    doc.text(`Production Report - ${format(report.reportDate, 'MMMM d, yyyy')}`, 14, 22);
    
    // Add production details
    doc.setFontSize(12);
    doc.text(`Production: ${productionAccess}`, 14, 32);
    doc.text(`Day of Production: ${report.dayOfProduction}`, 14, 42);
    doc.text(`Weather: ${report.weatherConditions}`, 14, 52);
    
    // Add times
    doc.text(`Crew Call: ${report.crewCallTime}`, 14, 62);
    doc.text(`First Shot: ${report.firstShotTime}`, 110, 62);
    doc.text(`Lunch: ${report.lunchStart} - ${report.lunchEnd}`, 14, 72);
    doc.text(`Wrap: ${report.wrapTime}`, 110, 72);
    
    // Add scenes shot
    doc.setFontSize(14);
    doc.text('Scenes Completed', 14, 92);
    
    const scenesData = report.scenes.map(scene => [
      scene.sceneNumber,
      `${scene.setting} / ${scene.timeOfDay}`,
      scene.location,
      scene.pages
    ]);
    
    doc.autoTable({
      startY: 95,
      head: [['Scene #', 'Setting', 'Location', 'Pages']],
      body: scenesData,
      theme: 'grid',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [66, 153, 225] }
    });
    
    // Add pages completed and notes
    const afterTableY = doc.autoTable.previous.finalY + 15;
    doc.text(`Pages Completed: ${report.pagesCompleted}`, 14, afterTableY);
    
    if (report.notes) {
      doc.text('Notes:', 14, afterTableY + 10);
      doc.setFontSize(12);
      doc.text(report.notes, 14, afterTableY + 20);
    }
    
    // Save PDF
    const fileName = `production_report_${productionAccess}_${format(report.reportDate, 'yyyy-MM-dd')}.pdf`;
    doc.save(fileName);
  };
  
  // Export report as Excel
  const exportToExcel = (report) => {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    
    // Format scene data
    const scenesData = report.scenes.map(scene => ({
      'Scene #': scene.sceneNumber,
      'Setting': `${scene.setting} / ${scene.timeOfDay}`,
      'Location': scene.location,
      'Description': scene.description,
      'Pages': scene.pages
    }));
    
    // Create header info sheet
    const headerData = [
      { 'Production Report': 'Values' },
      { 'Production Report': `Production: ${productionAccess}` },
      { 'Production Report': `Date: ${format(report.reportDate, 'MMMM d, yyyy')}` },
      { 'Production Report': `Day of Production: ${report.dayOfProduction}` },
      { 'Production Report': `Weather: ${report.weatherConditions}` },
      { 'Production Report': '' },
      { 'Production Report': 'Times:' },
      { 'Production Report': `Crew Call: ${report.crewCallTime}` },
      { 'Production Report': `First Shot: ${report.firstShotTime}` },
      { 'Production Report': `Lunch: ${report.lunchStart} - ${report.lunchEnd}` },
      { 'Production Report': `Wrap: ${report.wrapTime}` },
      { 'Production Report': '' },
      { 'Production Report': `Pages Completed: ${report.pagesCompleted}` },
      { 'Production Report': '' },
      { 'Production Report': 'Notes:' },
      { 'Production Report': report.notes || 'No notes' }
    ];
    
    // Add sheets to workbook
    const wsHeader = XLSX.utils.json_to_sheet(headerData);
    const wsScenes = XLSX.utils.json_to_sheet(scenesData);
    
    XLSX.utils.book_append_sheet(wb, wsHeader, 'Report Info');
    XLSX.utils.book_append_sheet(wb, wsScenes, 'Scenes');
    
    // Save Excel file
    const fileName = `production_report_${productionAccess}_${format(report.reportDate, 'yyyy-MM-dd')}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };
  
  return (
    <div className="page-container">
      <Sidebar />
      <div className="content">
        <h1 className="page-title">Production Reports</h1>
        
        {/* Error and success messages */}
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        
        {!isCreating ? (
          <div className="reports-list">
            <div className="header-actions">
              <h2>Production Reports</h2>
              <button 
                className="btn-primary"
                onClick={() => setIsCreating(true)}
              >
                Create New Report
              </button>
            </div>
            
            {loading ? (
              <p>Loading production reports...</p>
            ) : reports.length === 0 ? (
              <p>No production reports found. Create your first one!</p>
            ) : (
              <div className="table-container">
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Call Time</th>
                      <th>Wrap Time</th>
                      <th>Pages</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports.map(report => (
                      <tr key={report.id}>
                        <td>{format(report.reportDate, 'MMM d, yyyy')}</td>
                        <td>{report.dayOfProduction}</td>
                        <td>{report.crewCallTime}</td>
                        <td>{report.wrapTime}</td>
                        <td>{report.pagesCompleted}</td>
                        <td className="actions">
                          <button 
                            className="btn-small"
                            onClick={() => handleEditReport(report)}
                          >
                            Edit
                          </button>
                          <button 
                            className="btn-small"
                            onClick={() => exportToPDF(report)}
                          >
                            PDF
                          </button>
                          <button 
                            className="btn-small"
                            onClick={() => exportToExcel(report)}
                          >
                            Excel
                          </button>
                          <button 
                            className="btn-small delete"
                            onClick={() => handleDeleteReport(report.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ) : (
          <div className="report-form">
            <div className="header-actions">
              <h2>{isEditMode ? 'Edit Production Report' : 'Create New Production Report'}</h2>
              <button 
                className="btn-secondary"
                onClick={resetForm}
              >
                Cancel
              </button>
            </div>
            
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label>Report Date:</label>
                  <DatePicker
                    selected={reportDate}
                    onChange={date => setReportDate(date)}
                    className="form-control"
                    dateFormat="MMMM d, yyyy"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Day of Production:</label>
                  <input
                    type="text"
                    value={dayOfProduction}
                    onChange={e => setDayOfProduction(e.target.value)}
                    className="form-control"
                    placeholder="e.g. Day 1 of 20"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Weather Conditions:</label>
                  <input
                    type="text"
                    value={weatherConditions}
                    onChange={e => setWeatherConditions(e.target.value)}
                    className="form-control"
                    placeholder="e.g. Sunny, 75°F"
                  />
                </div>
              </div>
              
              <h3 className="section-title">Production Times</h3>
              <div className="form-row">
                <div className="form-group">
                  <label>Crew Call Time:</label>
                  <input
                    type="time"
                    value={crewCallTime}
                    onChange={e => setCrewCallTime(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>First Shot:</label>
                  <input
                    type="time"
                    value={firstShotTime}
                    onChange={e => setFirstShotTime(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Lunch Start:</label>
                  <input
                    type="time"
                    value={lunchStart}
                    onChange={e => setLunchStart(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Lunch End:</label>
                  <input
                    type="time"
                    value={lunchEnd}
                    onChange={e => setLunchEnd(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Wrap Time:</label>
                  <input
                    type="time"
                    value={wrapTime}
                    onChange={e => setWrapTime(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              
              <h3 className="section-title">Scenes Completed</h3>
              
              <div className="scene-selection">
                {scenes.length === 0 ? (
                  <p>No scenes available. Add scenes in the Scene Scheduling section first.</p>
                ) : (
                  <div className="scene-checkboxes">
                    {scenes.map(scene => (
                      <div key={scene.id} className="scene-checkbox">
                        <input
                          type="checkbox"
                          id={`scene-${scene.id}`}
                          checked={selectedScenes.includes(scene.id)}
                          onChange={() => toggleSceneSelection(scene.id)}
                          className="form-control-checkbox"
                        />
                        <label htmlFor={`scene-${scene.id}`} className="checkbox-label">
                          {scene.sceneNumber} - {scene.setting}/{scene.timeOfDay} - {scene.location.substring(0, 30)}
                          {scene.location.length > 30 ? '...' : ''}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div className="form-group">
                <label>Pages Completed:</label>
                <input
                  type="text"
                  value={pagesCompleted}
                  onChange={e => setPagesCompleted(e.target.value)}
                  className="form-control"
                  placeholder="e.g. 4 2/8"
                  required
                />
              </div>
              
              <div className="form-group">
                <label>Notes:</label>
                <textarea
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                  className="form-control"
                  rows="4"
                  placeholder="Production notes, issues, or highlights from the day"
                ></textarea>
              </div>
              
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading}
                >
                  {loading ? 'Saving...' : isEditMode ? 'Update Report' : 'Create Report'}
                </button>
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={resetForm}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductionReports;
