import React, { useState, useEffect, useContext, useRef } from 'react';
import { collection, addDoc, getDocs, query, where, Timestamp, updateDoc, doc, orderBy, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Times.css';
import '../styles/SharedStyles.css';
import { AuthContext } from '../contexts/AuthContext';
import Toast from '../components/Toast';
import { useAccessControl } from '../hooks/useAccessControl';

function Times() {
  const { user, loading } = useContext(AuthContext);
  const { hasAccess, isLoading: accessLoading, error: accessError, productionAccess, departmentAccess } = useAccessControl();
  const [timeEntries, setTimeEntries] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(getWeekDates());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [timeType, setTimeType] = useState('call');
  const [calculationType, setCalculationType] = useState('-30');
  const [selectedHour, setSelectedHour] = useState('');
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const [activeTab, setActiveTab] = useState('add'); // For mobile view: 'add' or 'view'
  const [isMobileView, setIsMobileView] = useState(false);
  const addTimeEntryRef = useRef(null);

  // Check if we're on mobile view
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    
    // Initial check
    checkIsMobile();
    
    // Add event listener for window resize
    window.addEventListener('resize', checkIsMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  useEffect(() => {
    if (user && hasAccess) {
      fetchTimeEntries(selectedDate);
    }
  }, [user, selectedDate, hasAccess]);

  function getWeekDates(date = new Date()) {
    // Get local date components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const dayOfWeek = date.getDay();

    // Create start date at noon UTC
    const start = new Date(Date.UTC(
      year,
      month,
      day - dayOfWeek,
      12, 0, 0
    ));

    return Array(7).fill().map((_, i) => {
      return new Date(Date.UTC(
        start.getUTCFullYear(),
        start.getUTCMonth(),
        start.getUTCDate() + i,
        12, 0, 0
      ));
    });
  }

  const fetchTimeEntries = async (date = selectedDate) => {
    try {
      console.log("Fetching time entries for user:", user.uid);
      
      // Create UTC dates at noon for consistency with Dispatch.js
      const startOfWeek = new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate() - date.getUTCDay(),
        12, 0, 0
      ));
      
      const endOfWeek = new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate() + (6 - date.getUTCDay()),
        12, 0, 0
      ));

      console.log("Date range:", startOfWeek, endOfWeek);

      const timeEntriesRef = collection(db, 'timeEntries');
      const q = query(
        timeEntriesRef,
        where('userId', '==', user.uid),
        where('date', '>=', Timestamp.fromDate(startOfWeek)),
        where('date', '<=', Timestamp.fromDate(endOfWeek)),
        orderBy('date', 'asc')
      );

      console.log("Query:", q);

      const querySnapshot = await getDocs(q);
      console.log("Query snapshot size:", querySnapshot.size);

      const entries = querySnapshot.docs.map(doc => {
        const data = doc.data();
        console.log("Time entry data:", data);
        return {
          id: doc.id,
          ...data,
          date: data.date.toDate(),
          startTime: data.startTime || null,
          endTime: data.endTime || null
        };
      });

      console.log("Processed time entries:", entries);
      setTimeEntries(entries);
    } catch (error) {
      console.error("Detailed error fetching time entries:", error);
      setError(`Failed to fetch time entries: ${error.message}`);
    }
  };

  const handlePreviousWeek = () => {
    const currentDate = new Date(Date.UTC(
      selectedDate.getUTCFullYear(),
      selectedDate.getUTCMonth(),
      selectedDate.getUTCDate() - 7,
      12, 0, 0
    ));
    setSelectedDate(currentDate);
    setSelectedWeek(getWeekDates(currentDate));
    fetchTimeEntries(currentDate);
  };

  const handleNextWeek = () => {
    const currentDate = new Date(Date.UTC(
      selectedDate.getUTCFullYear(),
      selectedDate.getUTCMonth(),
      selectedDate.getUTCDate() + 7,
      12, 0, 0
    ));
    setSelectedDate(currentDate);
    setSelectedWeek(getWeekDates(currentDate));
    fetchTimeEntries(currentDate);
  };

  const handleToday = () => {
    const today = new Date();
    const currentDate = new Date(Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      12, 0, 0
    ));
    setSelectedDate(currentDate);
    setSelectedWeek(getWeekDates(currentDate));
    fetchTimeEntries(currentDate);
  };

  const calculateHoursWorked = (startTime, endTime, type = calculationType) => {
    if (startTime === null || endTime === null) return 0;
    let diff = endTime - startTime;
    if (type === '-30') {
      diff = Math.max(0, diff - 0.5); // Subtract 30 minutes
    }
    return diff;
  };

  const calculateWeekTotal = (type = calculationType) => {
    return timeEntries.reduce((total, entry) => {
      return total + calculateHoursWorked(entry.startTime, entry.endTime, type);
    }, 0).toFixed(2);
  };

  const handleDateChange = (date) => {
    // Get local date components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    
    // Create UTC date at noon
    const utcDate = new Date(Date.UTC(year, month, day, 12, 0, 0));
    setSelectedDate(utcDate);
    setSelectedWeek(getWeekDates(utcDate));
    fetchTimeEntries(utcDate);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(time);
      }
    }
    return options;
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleTimeTypeChange = (e) => {
    setTimeType(e.target.value);
  };

  const handleCalculationTypeChange = (e) => {
    setCalculationType(e.target.value);
  };

  const handleHourChange = (e) => {
    const value = e.target.value;
    // Allow empty string, whole numbers, and .5 increments between 0 and 48
    if (value === '' || (/^\d+(\.\d*)?$/.test(value) && parseFloat(value) >= 0 && parseFloat(value) <= 48)) {
      setSelectedHour(value);
    }
  };

  const convertHourToTime = (hour) => {
    const wholeHour = Math.floor(hour);
    const minutes = hour % 1 === 0.5 ? '30' : '00';
    const adjustedHour = wholeHour > 24 ? wholeHour - 24 : wholeHour;
    return `${adjustedHour.toString().padStart(2, '0')}:${minutes}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!hasAccess || !selectedDate || selectedHour === '') {
      setToast({ message: 'Please select both date and hour.', type: 'error' });
      return;
    }

    console.log('Times.js - Submit button clicked');
    console.log('Times.js - Selected Date:', selectedDate);
    console.log('Times.js - Selected Hour:', selectedHour);

    try {
      const hour = parseFloat(selectedHour);

      // Get local date components from selectedDate
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      const day = selectedDate.getDate();

      // Create entry date at noon UTC
      const entryDate = new Date(Date.UTC(
        year,
        month,
        day,
        12, 0, 0
      ));
      
      console.log('Times.js - Entry Date:', entryDate);

      const timeEntryData = {
        userId: user.uid,
        date: Timestamp.fromDate(entryDate),
        [timeType === 'call' ? 'startTime' : 'endTime']: hour,
        log: {
          selectedDate: entryDate.toISOString().split('T')[0],
          clientTime: new Date().toISOString()
        }
      };

      console.log('Times.js - Time Entry Data:', timeEntryData);

      // Check if an entry already exists for this date
      const existingEntryQuery = query(
        collection(db, 'timeEntries'),
        where('userId', '==', user.uid),
        where('date', '==', Timestamp.fromDate(entryDate))
      );
      
      const existingEntrySnapshot = await getDocs(existingEntryQuery);

      if (!existingEntrySnapshot.empty) {
        // Update existing entry
        const existingEntryDoc = existingEntrySnapshot.docs[0];
        await updateDoc(doc(db, 'timeEntries', existingEntryDoc.id), timeEntryData);
        
        // Update local state
        const updatedEntries = timeEntries.map(entry => {
          if (entry.id === existingEntryDoc.id) {
            return {
              ...entry,
              ...timeEntryData,
              date: entryDate
            };
          }
          return entry;
        });
        setTimeEntries(updatedEntries);
      } else {
        // Add new entry
        const docRef = await addDoc(collection(db, 'timeEntries'), timeEntryData);
        setTimeEntries([...timeEntries, {
          id: docRef.id,
          ...timeEntryData,
          date: entryDate
        }]);
      }

      console.log('Times.js - Time entry updated successfully');
      setToast({ message: 'Time entry updated successfully!', type: 'success' });
      setSelectedHour('');
      await fetchTimeEntries(selectedDate);
    } catch (error) {
      console.error('Times.js - Error updating time entry:', error);
      setToast({ message: `Failed to update time entry: ${error.message}`, type: 'error' });
    }
  };

  const handleEditDay = (date) => {
    // Set the selected date to the clicked day
    setSelectedDate(date);
    
    // In mobile view, switch to the add tab
    if (isMobileView) {
      setActiveTab('add');
    }
    
    // Scroll to the add time entry form
    if (addTimeEntryRef.current) {
      addTimeEntryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    
    // Clear any previous time entry values
    setSelectedTime('');
    setSelectedHour('');
    setTimeType('call');
    
    // Show a toast message with instructions
    setToast({ message: 'Select call or wrap time for this date', type: 'info' });
  };

  // Format time for display
  const formatTimeDisplay = (hours) => {
    if (hours === null || hours === undefined) return '-';
    const hrs = Math.floor(hours);
    const mins = Math.round((hours - hrs) * 60);
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  };

  // Calculate daily hours for a specific date
  const calculateDailyHours = (date) => {
    const dayEntries = timeEntries.filter(entry => 
      entry.date.getDate() === date.getDate() &&
      entry.date.getMonth() === date.getMonth() &&
      entry.date.getFullYear() === date.getFullYear()
    );
    
    const callEntry = dayEntries.find(entry => entry.timeType === 'call' || entry.startTime !== null);
    const wrapEntry = dayEntries.find(entry => entry.timeType === 'wrap' || entry.endTime !== null);
    
    if (callEntry && wrapEntry && callEntry.startTime !== null && wrapEntry.endTime !== null) {
      return calculateHoursWorked(callEntry.startTime, wrapEntry.endTime).toFixed(2);
    }
    
    return '0.00';
  };

  if (accessLoading) {
    return <div>Loading access permissions...</div>;
  }

  if (accessError) {
    return <div>Error: {accessError}</div>;
  }

  if (!hasAccess) {
    return <div>You don't have permission to view this page.</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to view this page.</div>;
  }

  // Mobile Add Entry Form
  const MobileAddEntryForm = () => {
    const callWrapItems = [
      { id: 'call', icon: '⏰', label: 'Call', time: timeType === 'call' ? selectedTime : '-' },
      { id: 'wrap', icon: '✏️', label: 'Wrap', time: timeType === 'wrap' ? selectedTime : '-' }
    ];
    
    const calculationItems = [
      { id: 'standard', label: 'Standard' },
      { id: '-30', label: '-30 Minutes' }
    ];

    return (
      <div className="mobile-form-container">
        <div className="mobile-form-header">
          <p className="mobile-form-title">Add Time Entry</p>
        </div>

        <div className="mobile-form-card">
          <div className="mobile-form-group">
            <label htmlFor="date" className="mobile-label">
              Date
            </label>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              className="mobile-input"
              dateFormat="MM/dd/yyyy"
              id="date"
            />
          </div>

          <div className="mobile-form-group">
            <label htmlFor="time" className="mobile-label">
              Time
            </label>
            <input
              id="time"
              type="time"
              value={selectedTime}
              onChange={handleTimeChange}
              className="mobile-input mobile-time-input"
              required
            />
          </div>

          <div className="mobile-form-group">
            <label className="mobile-label">Time Type</label>
            <div className="mobile-button-grid">
              {callWrapItems.map(item => (
                <button
                  key={item.id}
                  className={`mobile-option-button ${timeType === item.id ? 'active' : ''}`}
                  onClick={() => setTimeType(item.id)}
                >
                  <span className="mobile-button-icon">{item.icon}</span>
                  <span className="mobile-button-label">{item.label}</span>
                  <span className="mobile-button-time">{item.time}</span>
                </button>
              ))}
            </div>
          </div>
          
          <div className="mobile-form-group">
            <label className="mobile-label">Calculation Type</label>
            <div className="mobile-button-grid">
              {calculationItems.map(item => (
                <button
                  key={item.id}
                  className={`mobile-option-button ${calculationType === item.id ? 'active' : ''}`}
                  onClick={() => setCalculationType(item.id)}
                >
                  <span className="mobile-button-label">{item.label}</span>
                </button>
              ))}
            </div>
          </div>

          <div className="mobile-time-summary">
            <div className="mobile-time-row">
              <span className="mobile-time-label">Call Time:</span>
              <span className="mobile-time-value">{timeType === 'call' ? selectedTime : '-'}</span>
            </div>
            <div className="mobile-time-row">
              <span className="mobile-time-label">Wrap Time:</span>
              <span className="mobile-time-value">{timeType === 'wrap' ? selectedTime : '-'}</span>
            </div>
          </div>

          <button 
            className="mobile-submit-button"
            onClick={handleSubmit}
          >
            Submit Time Entry
          </button>
        </div>
        
        {/* Compact Weekly Timesheet */}
        <div className="mobile-mini-weekly">
          <div className="mobile-mini-weekly-header">
            <div className="mobile-mini-weekly-title">
              Week of {formatWeeklyDateRange(selectedWeek)}
            </div>
            <div className="mobile-mini-weekly-controls">
              <button className="mobile-mini-weekly-button" onClick={() => navigateWeek(-1)}>
                &#8249;
              </button>
              <button className="mobile-mini-weekly-button" onClick={() => setSelectedDate(new Date())}>
                Today
              </button>
              <button className="mobile-mini-weekly-button" onClick={() => navigateWeek(1)}>
                &#8250;
              </button>
            </div>
          </div>

          <div className="mobile-mini-weekly-table-container">
            <table className="mobile-mini-weekly-table">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Call</th>
                  <th>Wrap</th>
                  <th>Hours</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedWeek.map((date, index) => {
                  const dayEntries = timeEntries.filter(entry => 
                    entry.date.getDate() === date.getDate() &&
                    entry.date.getMonth() === date.getMonth() &&
                    entry.date.getFullYear() === date.getFullYear()
                  );
                  
                  const callEntry = dayEntries.find(entry => entry.timeType === 'call');
                  const wrapEntry = dayEntries.find(entry => entry.timeType === 'wrap');
                  const hours = calculateDailyHours(date);

                  return (
                    <tr key={index} className={index % 2 === 1 ? "mobile-alt-row" : ""}>
                      <td>
                        <div className="mobile-day-cell">
                          <span>{date.toLocaleDateString('en-US', { weekday: 'short' })}</span>
                          <div className="mobile-date-display">
                            <div className="mobile-date-number">{date.getDate()}</div>
                            <div className="mobile-date-month">{date.toLocaleDateString('en-US', { month: 'short' })}</div>
                          </div>
                        </div>
                      </td>
                      <td>{callEntry ? formatTimeDisplay(callEntry.startTime) : '-'}</td>
                      <td>{wrapEntry ? formatTimeDisplay(wrapEntry.endTime) : '-'}</td>
                      <td>{hours}</td>
                      <td>
                        <button 
                          className="mobile-mini-edit-button"
                          onClick={() => handleEditDay(date)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5" className="mobile-weekly-total">
                    Weekly Total: {calculateWeekTotal()} hours
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  };

  // Mobile Weekly View
  const MobileWeeklyView = () => {
    return (
      <div className="mobile-weekly-container">
        <div className="mobile-weekly-header">
          <h2 className="mobile-weekly-title">Week of {formatWeeklyDateRange(selectedWeek)}</h2>
        </div>

        <div className="mobile-weekly-controls">
          <button 
            className="mobile-weekly-button"
            onClick={() => navigateWeek(-1)}
          >
            Previous Week
          </button>
          <button 
            className="mobile-weekly-button"
            onClick={() => setSelectedDate(new Date())}
          >
            Today
          </button>
          <button 
            className="mobile-weekly-button"
            onClick={() => navigateWeek(1)}
          >
            Next Week
          </button>
        </div>

        <div className="mobile-weekly-days">
          {selectedWeek.map((date, index) => {
            const dayEntries = timeEntries.filter(entry => 
              entry.date.getDate() === date.getDate() &&
              entry.date.getMonth() === date.getMonth() &&
              entry.date.getFullYear() === date.getFullYear()
            );
            
            const callEntry = dayEntries.find(entry => entry.timeType === 'call');
            const wrapEntry = dayEntries.find(entry => entry.timeType === 'wrap');
            const hours = calculateDailyHours(date);

            return (
              <div key={index} className={`mobile-day-card ${index % 2 === 1 ? "alt-bg" : ""}`}>
                <div className="mobile-day-header">
                  <div className="mobile-day-info">
                    <div className="mobile-day-name">{date.toLocaleDateString('en-US', { weekday: 'short' })}</div>
                    <div className="mobile-date-info">
                      <div className="mobile-date-number">{date.getDate()}</div>
                      <div className="mobile-date-month">{date.toLocaleDateString('en-US', { month: 'short' })}</div>
                    </div>
                  </div>
                  <div className="mobile-day-hours">{hours}</div>
                </div>

                <div className="mobile-day-details">
                  <div className="mobile-time-detail">
                    <span className="mobile-time-label">Call:</span>
                    <span className="mobile-time-value">{callEntry ? formatTimeDisplay(callEntry.startTime) : '-'}</span>
                  </div>
                  <div className="mobile-time-detail">
                    <span className="mobile-time-label">Wrap:</span>
                    <span className="mobile-time-value">{wrapEntry ? formatTimeDisplay(wrapEntry.endTime) : '-'}</span>
                  </div>
                  <button 
                    className="mobile-edit-day-button"
                    onClick={() => handleEditDay(date)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mobile-grand-total">
          <div className="mobile-grand-total-label">Weekly Total: {calculateWeekTotal()} hours</div>
        </div>
      </div>
    );
  };

  // Helper for formatting the week date range
  const formatWeeklyDateRange = (weekDates) => {
    if (!weekDates || weekDates.length === 0) return '';
    
    const startDate = weekDates[0];
    const endDate = weekDates[weekDates.length - 1];
    
    const startFormatted = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const endFormatted = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    
    return `${startFormatted} - ${endFormatted}`;
  };

  // Week navigation
  const navigateWeek = (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + (7 * direction));
    setSelectedDate(newDate);
    setSelectedWeek(getWeekDates(newDate));
  };

  return (
    <div className="times-container">
      <Sidebar />
      <div className="times-content">
        <header className="page-header">
          <h1>Times</h1>
        </header>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
        
        {isMobileView ? (
          <div className="mobile-times-view">
            <div className="mobile-content">
              {activeTab === 'add' ? (
                <MobileAddEntryForm />
              ) : (
                <MobileWeeklyView />
              )}
            </div>
            
            <div className="mobile-navigation">
              <button 
                className={`mobile-nav-button ${activeTab === 'add' ? 'active' : ''}`}
                onClick={() => setActiveTab('add')}
              >
                <span className="mobile-nav-icon">+</span>
                <span>Add Entry</span>
              </button>
              <button 
                className={`mobile-nav-button ${activeTab === 'view' ? 'active' : ''}`}
                onClick={() => setActiveTab('view')}
              >
                <span className="mobile-nav-icon">📅</span>
                <span>Weekly View</span>
              </button>
            </div>
          </div>
        ) : (
          <div>
            {/* Improved Single Time Entry Form */}
            <div className="improved-time-entry-card" ref={addTimeEntryRef}>
              <h2>Add Time Entry</h2>
              <form onSubmit={handleSubmit} className="improved-time-form">
                <div className="improved-form-row">
                  <div className="improved-form-column">
                    <div className="improved-form-group">
                      <label>Date <span className="required">*</span></label>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        className="date-input-single"
                        dateFormat="MM/dd/yyyy"
                      />
                    </div>
                  
                    <div className="improved-form-group">
                      <label>Time <span className="required">*</span></label>
                      <input
                        type="time"
                        value={selectedTime}
                        onChange={handleTimeChange}
                        className="improved-input"
                        required
                      />
                    </div>
                  </div>
                
                  <div className="improved-form-column">
                    <div className="improved-form-group">
                      <label>Call Type</label>
                      <div className="improved-radio-group">
                        <label className={`improved-radio-button ${timeType === 'call' ? 'selected' : ''}`}>
                          <input
                            type="radio"
                            name="timeType"
                            value="call"
                            checked={timeType === 'call'}
                            onChange={handleTimeTypeChange}
                          />
                          Call
                        </label>
                        <label className={`improved-radio-button ${timeType === 'wrap' ? 'selected' : ''}`}>
                          <input
                            type="radio"
                            name="timeType"
                            value="wrap"
                            checked={timeType === 'wrap'}
                            onChange={handleTimeTypeChange}
                          />
                          Wrap
                        </label>
                      </div>
                    </div>
                    
                    <div className="improved-form-group">
                      <label>Time Adjustment</label>
                      <div className="improved-radio-group">
                        <label className={`improved-radio-button ${calculationType === 'standard' ? 'selected' : ''}`}>
                          <input
                            type="radio"
                            name="calculationType"
                            value="standard"
                            checked={calculationType === 'standard'}
                            onChange={handleCalculationTypeChange}
                          />
                          Standard
                        </label>
                        <label className={`improved-radio-button ${calculationType === '-30' ? 'selected' : ''}`}>
                          <input
                            type="radio"
                            name="calculationType"
                            value="-30"
                            checked={calculationType === '-30'}
                            onChange={handleCalculationTypeChange}
                          />
                          -30 Minutes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="improved-submit-row">
                  <button type="submit" className="improved-submit-button">
                    Submit Time Entry
                  </button>
                </div>
              </form>
            </div>
            
            <div className="weekly-calendar">
              <div className="calendar-navigation">
                <h2>{`Week of ${selectedWeek[0]?.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${selectedWeek[6]?.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`}</h2>
                <div className="nav-buttons">
                  <button onClick={handlePreviousWeek}>Previous Week</button>
                  <button onClick={handleToday}>Today</button>
                  <button onClick={handleNextWeek}>Next Week</button>
                </div>
              </div>
              
              <div className="calendar-table">
                <div className="calendar-header">
                  <div>Day</div>
                  <div>Date</div>
                  <div>Call</div>
                  <div>Wrap</div>
                  <div>Hours</div>
                  <div>Actions</div>
                </div>
                
                <div className="calendar-body">
                  {selectedWeek.map((date, index) => {
                    // Find entries for this date
                    const dayEntries = timeEntries.filter(entry => 
                      entry.date.getUTCDate() === date.getUTCDate() &&
                      entry.date.getUTCMonth() === date.getUTCMonth() &&
                      entry.date.getUTCFullYear() === date.getUTCFullYear()
                    );
                    
                    // Get call and wrap times if they exist
                    const callEntry = dayEntries.find(entry => entry.startTime !== null && entry.startTime !== undefined);
                    const wrapEntry = dayEntries.find(entry => entry.endTime !== null && entry.endTime !== undefined);
                    
                    // Format time for display
                    const formatTimeDisplay = (hours) => {
                      if (hours === null || hours === undefined) return '-';
                      const hrs = Math.floor(hours);
                      const mins = Math.round((hours - hrs) * 60);
                      return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
                    };
                    
                    // Calculate hours worked
                    let hoursWorked = 0;
                    if (callEntry && wrapEntry) {
                      hoursWorked = calculateHoursWorked(callEntry.startTime, wrapEntry.endTime);
                    }
                    
                    return (
                      <div className="calendar-row" key={index}>
                        <div className="calendar-cell">
                          {date.toLocaleDateString('en-US', { weekday: 'short' })}
                        </div>
                        <div className="calendar-cell date-cell">
                          <h3>{date.getUTCDate()}</h3>
                          <p>{date.toLocaleDateString('en-US', { month: 'short' })}</p>
                        </div>
                        <div className="calendar-cell">
                          {callEntry ? formatTimeDisplay(callEntry.startTime) : '-'}
                        </div>
                        <div className="calendar-cell">
                          {wrapEntry ? formatTimeDisplay(wrapEntry.endTime) : '-'}
                        </div>
                        <div className="calendar-cell">
                          {hoursWorked.toFixed(2)}
                        </div>
                        <div className="calendar-cell">
                          <button onClick={() => handleEditDay(date)}>
                            Edit
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              
              <div className="weekly-summary">
                <h3>Weekly Total: {calculateWeekTotal()} hours</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Times;