import React from 'react';
import { Link } from 'react-router-dom';

const TestLinks = () => {
  console.log('RENDERING: TestLinks component');
  
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Test Navigation Links</h1>
      <p>Click the links below to test different routes:</p>
      
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '20px' }}>
        <Link 
          to="/ad/call-sheets" 
          style={{ padding: '10px', background: '#f0f0f0', borderRadius: '5px', textDecoration: 'none', color: '#333' }}
        >
          Go to Call Sheets (Original)
        </Link>
        
        <Link 
          to="/ad/callsheet-builder" 
          style={{ padding: '10px', background: '#e0ffe0', borderRadius: '5px', textDecoration: 'none', color: '#333' }}
        >
          Go to Visual Callsheet Builder (New)
        </Link>
      </div>
      
      <div style={{ marginTop: '30px', padding: '15px', background: '#efefef', borderRadius: '5px' }}>
        <h3>Debugging Info:</h3>
        <p>Check your browser console for component rendering logs.</p>
        <p>Current Path: {window.location.pathname}</p>
      </div>
    </div>
  );
};

export default TestLinks;
