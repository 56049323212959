// Export all components from the AD department
import CallSheets from './CallSheets';
import SceneScheduling from './SceneScheduling';
import ProductionReports from './ProductionReports';
import WalkieManagement from './WalkieManagement';
import CrewVehicles from './CrewVehicles';
import RentedVehicles from './RentedVehicles';
import CrewTimes from './CrewTimes';
import CallsheetBuilder from './CallsheetBuilder';
import ProductionRentals from './ProductionRentals';

console.log('AD Department index.js - Importing CallsheetBuilder:', CallsheetBuilder);

export {
  CallSheets,
  SceneScheduling,
  ProductionReports,
  WalkieManagement,
  CrewVehicles,
  RentedVehicles,
  CrewTimes,
  CallsheetBuilder,
  ProductionRentals
};
