import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useAuth } from '../hooks/useAuth';
import { useAccessControl } from '../hooks/useAccessControl';
import { ROLES, PERMISSIONS, ROLE_PERMISSIONS } from '../constants/roles';
import './Sidebar.css';
import './ResponsiveSidebar.css';
import logo from '../Assets/Slate-Logo.png';
import profilePlaceholder from '../Assets/scph/Profile placeholder.png';
import { collection, query, where, getDocs, onSnapshot, Timestamp, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

function Sidebar({ isOpen }) {
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [error, setError] = useState(null);
  const { user, setUser } = useAuth();
  const { departmentAccess } = useAccessControl();
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState({
    timeEntries: 0,
    runs: 0,
    messages: 0
  });
  const [isADDropdownOpen, setIsADDropdownOpen] = useState(false);

  useEffect(() => {
    if (!user) return;

    const setupNotificationsQuery = () => {
      try {
        // Query for time entries
        const timeEntriesQuery = query(
          collection(db, 'timeEntries'),
          where('userId', '==', user.uid),
          orderBy('date', 'desc')
        );

        // Query for dispatched runs
        const runsQuery = query(
          collection(db, 'dispatchedRuns'),
          where('assignedTo', '==', user.uid),
          orderBy('date', 'desc')
        );

        // Query for conversations
        const conversationsQuery = query(
          collection(db, 'conversations'),
          where('participants', 'array-contains', user.uid)
        );

        // Setup listener for time entries
        const timeEntriesUnsubscribe = onSnapshot(timeEntriesQuery, (snapshot) => {
          try {
            const unreadTimeEntries = snapshot.docs.filter(doc => !doc.data().acknowledged).length;
            setUnreadCount(prev => ({ ...prev, timeEntries: unreadTimeEntries }));
          } catch (error) {
            console.error('Error processing time entries:', error);
          }
        });

        // Setup listener for runs
        const runsUnsubscribe = onSnapshot(runsQuery, (snapshot) => {
          try {
            const unreadRuns = snapshot.docs.filter(run => 
              !run.data().acknowledged && run.data().status !== 'Acknowledged'
            ).length;
            setUnreadCount(prev => ({ ...prev, runs: unreadRuns }));
          } catch (error) {
            console.error('Error processing runs:', error);
          }
        });

        // Setup listener for conversations and messages
        const conversationsUnsubscribe = onSnapshot(conversationsQuery, async (snapshot) => {
          try {
            const allConversations = snapshot.docs.map(doc => doc.id);
            
            if (allConversations.length === 0) {
              setUnreadCount(prev => ({ ...prev, messages: 0 }));
              return;
            }

            // Query for unread messages
            const messagesQuery = query(
              collection(db, 'messages'),
              where('conversationId', 'in', allConversations),
              where('read', '==', false),
              where('senderId', '!=', user.uid)  // Don't count our own messages
            );

            console.log('Setting up messages listener in sidebar');
            
            // Use onSnapshot instead of getDocs for real-time updates
            const messagesUnsubscribe = onSnapshot(messagesQuery, (messagesSnapshot) => {
              const unreadMessages = messagesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
              }));

              console.log('Unread messages updated in sidebar:', {
                total: unreadMessages.length,
                messages: unreadMessages.map(msg => ({
                  id: msg.id,
                  conversationId: msg.conversationId,
                  senderId: msg.senderId,
                  read: msg.read,
                  type: msg.type
                }))
              });

              setUnreadCount(prev => ({ ...prev, messages: unreadMessages.length }));
            });

            // Store the unsubscribe function
            return () => {
              messagesUnsubscribe();
            };

          } catch (error) {
            console.error('Error processing messages:', error);
          }
        });

        return () => {
          timeEntriesUnsubscribe();
          runsUnsubscribe();
          conversationsUnsubscribe();
        };
      } catch (error) {
        console.error('Error setting up notification queries:', error);
        return () => {};
      }
    };

    const unsubscribeNotifications = setupNotificationsQuery();
    return () => {
      if (unsubscribeNotifications) {
        unsubscribeNotifications();
      }
    };
  }, [user]);

  // Calculate total unread notifications (excluding messages)
  const totalUnreadNotifications = unreadCount.timeEntries + unreadCount.runs;
  
  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
      setError("Failed to log out. Please try again.");
    }
  };

  // Toggle AD dropdown menu
  const toggleADDropdown = () => {
    setIsADDropdownOpen(!isADDropdownOpen);
  };

  if (!isOpen) return null;

  if (!user) {
    return null; // or return a loading indicator
  }

  const isAdmin = user && ['admin', 'productionAdmin', 'superAdmin', 'production_admin', 'app_admin'].includes(user.role);
  const isSupervisor = user && (user.role === 'supervisor' || user.role === 'Supervisor');
  
  // Debug department access
  console.log('Current user department access:', departmentAccess);
  console.log('User role:', user.role);
  console.log('User department from user object:', user.department);
  
  // Calculate total unread notifications
  console.log('Total unread notifications:', totalUnreadNotifications);
  
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img src={logo} alt="Slate Fusion Logo" className="sidebar-logo" />
        <h1 className="sidebar-title">Slate Fusion</h1>
      </div>
      <ul className="sidebar-menu">
        <li><Link to="/welcome">👋 Welcome</Link></li>
        <li><Link to="/home">🏠 Home</Link></li>
        <li><Link to="/times">⏱️ Times</Link></li>
        <li><Link to="/messages">
          ✉️ Messages
          {unreadCount.messages > 0 && (
            <span className="notification-count" title={`${unreadCount.messages} unread messages`}>
              {unreadCount.messages}
            </span>
          )}
        </Link></li>
        <li>
          <Link to="/notifications">
            🔔 Notifications
            {totalUnreadNotifications > 0 && (
              <span className="notification-count" title={`${totalUnreadNotifications} total notifications`}>
                {totalUnreadNotifications}
              </span>
            )}
          </Link>
        </li>
        
        {/* AD Department Links - Show to any user with AD department access */}
        {departmentAccess === 'LaRHrQc5fLzcnScd9mIz' && (
          <>
            <li><Link to="/ad/call-sheets">📋 Call Sheets</Link></li>
            <li><Link to="/ad/scene-scheduling">📅 Scene Scheduling</Link></li>
            <li><Link to="/ad/production-reports">📝 Production Reports</Link></li>
            <li><Link to="/ad/walkie-management">📻 Walkie Management</Link></li>
            <li><Link to="/ad/crew-vehicles">🚗 Crew Vehicles</Link></li>
            <li><Link to="/ad/crew-times">⏱️ Crew Times</Link></li>
            {/* Add additional sidebar items specific to AD department */}
            <li><Link to="/dashboard">🎛️ Dashboard</Link></li>
            <li><Link to="/reports">📊 Reports</Link></li>
            <li><Link to="/crew-members">👥 Crew Members</Link></li>
            <li><Link to="/departments">🎭 Departments</Link></li>
            <li><Link to="/budget">🧮 Budget</Link></li>
          </>
        )}
        
        {/* Admin and Supervisor Links - don't show to AD department users */}
        {(isAdmin || isSupervisor) && departmentAccess !== 'LaRHrQc5fLzcnScd9mIz' && (
          <>
            <li><Link to="/dashboard">🎛️ Dashboard</Link></li>
            {isAdmin && <li><Link to="/productions">🎬 Productions</Link></li>}
            <li><Link to="/dispatch">📟 Dispatch</Link></li>
            <li><Link to="/reports">📊 Reports</Link></li>
            <li><Link to="/crew-members">👥 Crew Members</Link></li>
            <li><Link to="/departments">🎭 Departments</Link></li>
            <li><Link to="/vehicles-page">🚛 Production Vehicles</Link></li>
            <li><Link to="/budget">🧮 Budget</Link></li>
          </>
        )}
        
        {isAdmin && (
          <>
            <li>
              <Link to="/management">
                <i className="fas fa-cogs"></i>
                <span>Management</span>
              </Link>
            </li>
            <li>
              <Link to="/user-management">User Management</Link>
            </li>
            <li>
              <Link to="/user-role-manager">User Role Manager</Link>
            </li>
          </>
        )}
      </ul>
      <div className="sidebar-bottom">
        <ul className="sidebar-menu">
          <li><Link to="/profile">🪪 Profile</Link></li>
          <li><Link to="/settings">⚙️ Settings</Link></li>
        </ul>
      </div>
      <div className="sidebar-footer">
        <div className="user-info">
          <div className="user-info-header">
            <img 
              src={user.photoURL || profilePlaceholder} 
              alt="Profile" 
              className="user-avatar"
            />
            <div className="user-details">
              <div className="user-role">{user.role}</div>
              <div className="user-email">{user.email}</div>
            </div>
          </div>
        </div>
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

const fetchNewNotificationsCount = async () => {
  // Implement this function to fetch new notifications count
};

export default Sidebar;
