import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, doc, getDoc, query, where, Timestamp, addDoc, updateDoc, orderBy } from 'firebase/firestore';
import { useAuth } from '../../hooks/useAuth';
import { useAccessControl } from '../../hooks/useAccessControl';
import './ADStyles.css';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const CrewTimes = () => {
  const [timeEntries, setTimeEntries] = useState([]);
  const [crew, setCrew] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [productions, setProductions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [selectedProduction, setSelectedProduction] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [userDepartment, setUserDepartment] = useState(null);
  const [userProduction, setUserProduction] = useState(null);
  const [isAdDepartment, setIsAdDepartment] = useState(false);
  const [editableEntry, setEditableEntry] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [modifiedEntries, setModifiedEntries] = useState({});
  const [toastMessage, setToastMessage] = useState(null);
  const [selectedDepartmentForAdd, setSelectedDepartmentForAdd] = useState('');
  const [departmentMap, setDepartmentMap] = useState({});
  const { user } = useAuth();
  const { productionAccess } = useAccessControl();

  // Determine user's department, production, and access level
  useEffect(() => {
    const determineUserAccess = async () => {
      if (!user) return;
      
      try {
        // Get the user's document to determine their department and production
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserDepartment(userData.department || null);
          setUserProduction(userData.production || null);
          
          // Check if user is in AD department
          setIsAdDepartment(userData.department === 'ad');
          
          // If user is not in AD department, automatically set selectedDepartment to their department
          if (userData.department && userData.department !== 'ad') {
            setSelectedDepartment(userData.department);
          }
          
          // Set the user's production as the selected production
          if (userData.production) {
            setSelectedProduction(userData.production);
          }
          
          console.log(`User department: ${userData.department}, AD Department: ${userData.department === 'ad'}, Production: ${userData.production}`);
        } else {
          console.log("User document not found");
        }
      } catch (err) {
        console.error("Error determining user access:", err);
      }
    };
    
    determineUserAccess();
  }, [user]);

  // Use effect for data fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        console.log('User department:', userDepartment);
        console.log('AD Department:', isAdDepartment);
        console.log('User production:', userProduction);

        // Fetch departments
        const departmentsQuery = query(collection(db, 'departments'));
        const departmentsSnapshot = await getDocs(departmentsQuery);
        const departmentsData = departmentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        console.log('All departments:', departmentsData.map(d => `${d.id}: ${d.name || 'unnamed'}`));
        setDepartments(departmentsData);
        
        // Create a map of department IDs to names
        const deptMap = {};
        departmentsData.forEach(dept => {
          deptMap[dept.id] = dept.name;
        });
        setDepartmentMap(deptMap);
        
        // Fetch productions
        const productionsQuery = query(collection(db, 'productions'));
        const productionsSnapshot = await getDocs(productionsQuery);
        const productionsData = productionsSnapshot.docs.map(doc => doc.data().name);
        setProductions(productionsData);
        
        // Fetch crew members from users collection
        await fetchCrewMembers();
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again.');
        setLoading(false);
      }
    };

    fetchData();
  }, [userDepartment, isAdDepartment, userProduction]);

  // Function to fetch crew members from users collection
  const fetchCrewMembers = async () => {
    console.log('Starting fetchCrewMembers');
    try {
      const usersCollection = collection(db, 'users');
      let usersQuery;

      console.log('Current user:', user);
      console.log('User role:', user?.role);

      // Get all users regardless of role for now, we'll filter later
      usersQuery = usersCollection;

      const querySnapshot = await getDocs(usersQuery);
      console.log('Query snapshot size:', querySnapshot.size);
      
      const crewMemberList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        // Log each crew member's name fields for debugging
        console.log('Crew member data:', 
          `ID: ${doc.id}, ` +
          `Name: ${data.name || 'N/A'}, ` + 
          `fullName: ${data.fullName || 'N/A'}, ` + 
          `Department: ${data.department || 'N/A'}, ` +
          `Production: ${data.production || 'N/A'}`
        );
        return {
          id: doc.id,
          ...data
        };
      });

      console.log('Processed crew member list:', crewMemberList.length);
      setCrew(crewMemberList);
      
      // Filter crew based on department and production
      filterCrewMembers(crewMemberList);
    } catch (error) {
      console.error("Error fetching crew members: ", error);
      throw error;
    }
  };

  // Function to filter crew members based on selected department and production
  const filterCrewMembers = (crewList = crew) => {
    console.log('Filtering crew members with:', {
      selectedDepartment,
      selectedProduction,
      crewList: crewList.length
    });
    
    if (!Array.isArray(crewList)) {
      console.warn('Crew list is not an array:', crewList);
      return;
    }
    
    let filteredCrew = [...crewList];
    
    // Filter by production
    if (selectedProduction) {
      filteredCrew = filteredCrew.filter(member => {
        return member && member.production === selectedProduction;
      });
      console.log('After production filter:', filteredCrew.length);
    }
    
    // Filter by department
    if (selectedDepartment && selectedDepartment !== 'all') {
      filteredCrew = filteredCrew.filter(member => {
        return member && member.department === selectedDepartment;
      });
      console.log('After department filter:', filteredCrew.length);
    }
    
    console.log(`Filtered to ${filteredCrew.length} crew members`);
    
    // Create time entries for filtered crew
    const entries = filteredCrew.map(createTimeEntry);
    setTimeEntries(entries);
  };

  // Re-filter crew when selection changes
  useEffect(() => {
    if (!loading && crew.length > 0) {
      filterCrewMembers();
    }
  }, [selectedDepartment, selectedProduction, crew, loading]);

  // Function to create a time entry for a crew member
  const createTimeEntry = (crewMember) => {
    // Parse the selected date
    let dateObj;
    try {
      dateObj = new Date(selectedDate);
      if (isNaN(dateObj.getTime())) {
        dateObj = new Date();
      }
    } catch (error) {
      dateObj = new Date();
    }
    
    // Create a basic time entry
    return {
      id: `time-${crewMember.id}-${selectedDate}`,
      userId: crewMember.id,
      department: crewMember.department || 'unknown',
      date: dateObj,
      checkIn: null,  // No default call time
      checkOut: null, // No default wrap time
      notes: '',
      production: productionAccess || 'demo',
      mealPenalty: false
    };
  };

  // Simplified useEffect that just creates time entries
  useEffect(() => {
    if (crew.length > 0) {
      filterCrewMembers();
    }
  }, [selectedDate, crew, selectedDepartment]);

  // Get filtered time entries - greatly simplified
  const getFilteredTimeEntries = () => {
    if (!timeEntries || timeEntries.length === 0) {
      return [];
    }
    
    // If a department is selected, filter by it
    if (selectedDepartment && selectedDepartment !== 'all') {
      return timeEntries.filter(entry => entry.department === selectedDepartment);
    }
    
    // Otherwise, return all time entries
    return timeEntries;
  };

  const getCrewMemberName = (crewId) => {
    const crewMember = crew.find(c => c.id === crewId);
    
    if (crewMember) {
      // For debugging, log the crew member object to see what properties it has
      console.log('Crew member found:', crewId, crewMember);
      
      // Check for various name field options in order of preference
      if (crewMember.name) {
        return crewMember.name;
      } else if (crewMember.fullName) {
        return crewMember.fullName;
      } else if (crewMember.displayName) {
        return crewMember.displayName;
      } else if (crewMember.firstName && crewMember.lastName) {
        return `${crewMember.firstName} ${crewMember.lastName}`;
      } else if (crewMember.email) {
        // Fall back to email if no name is available
        return crewMember.email.split('@')[0];
      }
    }
    
    // If no crew member found with this ID, log the missing ID
    console.log('Could not find crew member with ID:', crewId);
    console.log('Available crew member IDs:', crew.map(c => c.id));
    
    return 'Unknown Crew Member';
  };

  const getDepartmentName = (deptId) => {
    const department = departments.find(d => d.id === deptId);
    return department ? department.name : 'Unknown Department';
  };

  // Format timestamp as 24-hour military time
  const formatTime = (timestamp) => {
    if (!timestamp) return '—'; // Em dash for missing times
    
    try {
      // Convert Firestore timestamp to Date if needed
      const timeDate = typeof timestamp.toDate === 'function' ? timestamp.toDate() : timestamp;
      
      // Format to 24-hour time
      return timeDate.toLocaleTimeString('en-US', { 
        hour: '2-digit', 
        minute: '2-digit',
        hour12: false
      });
    } catch (error) {
      console.error('Error formatting time:', error, timestamp);
      return '—'; // Em dash for invalid times
    }
  };

  // Format film time to 24-hour format handling times over 24 hours
  const formatFilmTime = (timestamp, baseDate) => {
    if (!timestamp) return 'N/A';
    
    try {
      const checkOutDate = typeof timestamp.toDate === 'function' 
        ? timestamp.toDate() 
        : new Date(timestamp);
      
      let baseDateTime = null;
      
      // If baseDate is provided, use it
      if (baseDate) {
        const baseDateObj = typeof baseDate.toDate === 'function'
          ? baseDate.toDate()
          : new Date(baseDate);
        
        // Set baseDateTime to the date part of baseDate
        baseDateTime = new Date(baseDateObj);
        baseDateTime.setHours(0, 0, 0, 0);
      } else {
        // If no base date, use the date part of timestamp
        baseDateTime = new Date(checkOutDate);
        baseDateTime.setHours(0, 0, 0, 0);
      }
      
      // Calculate total hours from base date
      const diffMs = checkOutDate.getTime() - baseDateTime.getTime();
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const minutes = checkOutDate.getMinutes();
      
      // Format string with hours and minutes
      return `${diffHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    } catch (error) {
      console.error('Error formatting film time:', error);
      return 'Invalid Time';
    }
  };

  // Convert film time format to 24-hour military time
  const formatFilmTimeToMilitary = (filmTime) => {
    if (!filmTime || filmTime === 'N/A' || filmTime === 'Invalid Time') {
      return filmTime;
    }
    
    try {
      const [hoursStr, minutesStr] = filmTime.split(':');
      let hours = parseInt(hoursStr, 10);
      
      if (isNaN(hours)) {
        return filmTime;
      }
      
      // Handle hours over 24 - but keep the value greater than 24 for film time
      return `${hours.toString().padStart(2, '0')}:${minutesStr.padStart(2, '0')}`;
    } catch (error) {
      console.error('Error formatting film time to military:', error);
      return filmTime;
    }
  };

  // Parse time input from various formats to Date object
  const parseTimeInput = (timeString, baseDate) => {
    if (!timeString || timeString === 'N/A') return null;
    
    try {
      // Default to today if no baseDate
      const baseDateTime = baseDate ? new Date(baseDate) : new Date();
      baseDateTime.setHours(0, 0, 0, 0);
      
      // Check if the input is in film time format (e.g., "27:30")
      if (/^\d{1,2}:\d{2}$/.test(timeString)) {
        const [hoursStr, minutesStr] = timeString.split(':');
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        
        if (!isNaN(hours) && !isNaN(minutes)) {
          // Create a new date from baseDateTime
          const resultDate = new Date(baseDateTime);
          
          // Add hours and minutes
          resultDate.setHours(hours);
          resultDate.setMinutes(minutes);
          
          return resultDate;
        }
      }
      
      // If it's in 24-hour format (16:30)
      if (/^\d{1,2}:\d{2}$/.test(timeString)) {
        const [hoursStr, minutesStr] = timeString.split(':');
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        
        if (!isNaN(hours) && !isNaN(minutes) && hours < 24) {
          // Create a new date from baseDateTime
          const resultDate = new Date(baseDateTime);
          
          // Set hours and minutes
          resultDate.setHours(hours);
          resultDate.setMinutes(minutes);
          
          return resultDate;
        }
      }
      
      // If all else fails, try standard date parsing
      const parsedDate = new Date(timeString);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
      
      // If nothing works, return null
      return null;
    } catch (error) {
      console.error('Error parsing time input:', error);
      return null;
    }
  };

  // Calculate hours between two timestamps
  const calculateHours = (checkIn, checkOut) => {
    if (!checkIn || !checkOut) return 'N/A';
    
    try {
      // Handle both Firestore Timestamp objects and regular Date objects
      const startDate = typeof checkIn.toDate === 'function' 
        ? checkIn.toDate() 
        : new Date(checkIn);
      
      const endDate = typeof checkOut.toDate === 'function' 
        ? checkOut.toDate() 
        : new Date(checkOut);
      
      const diffMs = endDate - startDate;
      const diffHrs = diffMs / (1000 * 60 * 60);
      
      return diffHrs.toFixed(2);
    } catch (err) {
      console.error('Error calculating hours:', err);
      return 'N/A';
    }
  };

  const handleEditToggle = (entryId) => {
    const entryToEdit = timeEntries.find(entry => entry.id === entryId);
    if (entryToEdit) {
      setEditableEntry(entryId);
      setEditedValues({
        userId: entryToEdit.userId,
        department: entryToEdit.department,
        checkIn: formatTime(entryToEdit.checkIn),
        checkOut: entryToEdit.checkOut ? formatTime(entryToEdit.checkOut) : '',
        notes: entryToEdit.notes || '',
        mealPenalty: entryToEdit.mealPenalty || false
      });
    }
  };

  const handleInputChange = (field, value, entryId = null) => {
    if (entryId) {
      // For group editing mode - track changes by entry ID
      setModifiedEntries({
        ...modifiedEntries,
        [entryId]: {
          ...modifiedEntries[entryId],
          [field]: value
        }
      });
    } else {
      // For single entry editing mode
      setEditedValues({
        ...editedValues,
        [field]: value
      });
    }
  };

  const toggleMealPenalty = (entryId) => {
    const entry = timeEntries.find(e => e.id === entryId);
    const updatedValue = !entry.mealPenalty;
    
    if (editableEntry === entryId) {
      // In single edit mode
      setEditedValues({
        ...editedValues,
        mealPenalty: updatedValue
      });
    } else {
      // In group edit mode
      setModifiedEntries({
        ...modifiedEntries,
        [entryId]: {
          ...modifiedEntries[entryId],
          mealPenalty: updatedValue
        }
      });
    }
  };

  const cancelEdit = () => {
    setEditableEntry(null);
    setEditedValues({});
  };

  const saveChanges = async () => {
    try {
      if (!editableEntry) return;
      
      const entryRef = doc(db, 'timeEntries', editableEntry);
      
      // Parse time strings to timestamps
      const parsedCheckIn = parseTimeInput(editedValues.checkIn);
      const parsedCheckOut = editedValues.checkOut ? parseTimeInput(editedValues.checkOut) : null;
      
      if (!parsedCheckIn) {
        alert('Invalid call time format. Please use format like "09:30"');
        return;
      }
      
      if (editedValues.checkOut && !parsedCheckOut) {
        alert('Invalid wrap time format. Please use format like "17:30"');
        return;
      }
      
      const updatedEntry = {
        checkIn: parsedCheckIn,
        notes: editedValues.notes || '',
        mealPenalty: editedValues.mealPenalty || false
      };
      
      // Only include checkOut if it has a value
      if (parsedCheckOut) {
        updatedEntry.checkOut = parsedCheckOut;
      }
      
      await updateDoc(entryRef, updatedEntry);
      
      // Update the local state
      setTimeEntries(timeEntries.map(entry => 
        entry.id === editableEntry 
          ? { ...entry, ...updatedEntry, id: editableEntry } 
          : entry
      ));
      
      // Reset edit state
      setEditableEntry(null);
      setEditedValues({});
      
    } catch (error) {
      console.error("Error saving changes:", error);
      alert(`Error saving changes: ${error.message}`);
    }
  };

  const saveAllChanges = async () => {
    try {
      const entryIds = Object.keys(modifiedEntries);
      if (entryIds.length === 0) {
        alert('No changes to save.');
        return;
      }
      
      // Process each modified entry
      const promises = entryIds.map(async (entryId) => {
        const changes = modifiedEntries[entryId];
        if (!changes) return null;
        
        const entryRef = doc(db, 'timeEntries', entryId);
        const updatedEntry = {};
        
        // Parse time values if present
        if (changes.checkIn) {
          const parsedCheckIn = parseTimeInput(changes.checkIn);
          if (!parsedCheckIn) {
            throw new Error(`Invalid call time format for entry ${getCrewMemberName(timeEntries.find(e => e.id === entryId)?.userId)}`);
          }
          updatedEntry.checkIn = parsedCheckIn;
        }
        
        if (changes.checkOut) {
          const parsedCheckOut = parseTimeInput(changes.checkOut);
          if (!parsedCheckOut) {
            throw new Error(`Invalid wrap time format for entry ${getCrewMemberName(timeEntries.find(e => e.id === entryId)?.userId)}`);
          }
          updatedEntry.checkOut = parsedCheckOut;
        }
        
        // Add other modified fields
        if (changes.notes !== undefined) {
          updatedEntry.notes = changes.notes;
        }
        
        if (changes.mealPenalty !== undefined) {
          updatedEntry.mealPenalty = changes.mealPenalty;
        }
        
        // Only update if there are changes
        if (Object.keys(updatedEntry).length > 0) {
          await updateDoc(entryRef, updatedEntry);
          return { id: entryId, ...updatedEntry };
        }
        return null;
      });
      
      const results = await Promise.all(promises);
      
      // Update local state with all the changes
      const updatedEntries = timeEntries.map(entry => {
        const result = results.find(r => r && r.id === entry.id);
        return result ? { ...entry, ...result } : entry;
      });
      
      setTimeEntries(updatedEntries);
      setModifiedEntries({}); // Clear the modified entries
      
      alert('All changes saved successfully.');
    } catch (error) {
      console.error("Error saving multiple changes:", error);
      alert(`Error saving changes: ${error.message}`);
    }
  };

  const handleWrapOut = async (entryId) => {
    try {
      // Get the current time
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const currentTime = `${hours}:${minutes}`;
      
      // Ask for wrap time, default to current time
      const wrapTimeInput = window.prompt('Enter wrap time (24-hour format, e.g., 17:30):', currentTime);
      
      if (wrapTimeInput === null) {
        // User canceled
        return;
      }
      
      // Parse the wrap time
      const parsedWrapTime = parseTimeInput(wrapTimeInput, selectedDate);
      
      if (!parsedWrapTime) {
        alert('Invalid wrap time format. Please use format like "17:30"');
        return;
      }
      
      // Update the entry in Firestore
      const entryRef = doc(db, 'timeEntries', entryId);
      await updateDoc(entryRef, {
        checkOut: Timestamp.fromDate(parsedWrapTime)
      });
      
      // Refresh the data
      filterCrewMembers();
      
      // Show feedback
      setToastMessage('Crew member wrapped out successfully');
    } catch (error) {
      console.error('Error wrapping out:', error);
      setError('Failed to wrap out crew member. Please try again.');
    }
  };

  const addTimeEntry = async () => {
    try {
      if (!selectedDepartmentForAdd) {
        alert('Please select a department.');
        return;
      }
      
      // Get the current date in the format YYYY-MM-DD
      const today = new Date().toISOString().split('T')[0];
      
      // Create a new unique ID for the entry
      const newEntryId = `new-${Date.now()}`;
      
      // Create a new entry with empty fields
      const newEntry = {
        department: selectedDepartmentForAdd,
        date: new Date(today),
        production: productionAccess || 'demo',
        notes: '',
        mealPenalty: false
      };
      
      // Add to Firestore collection
      addDoc(collection(db, 'timeEntries'), newEntry);
      
      // Refresh data and reset selections
      filterCrewMembers();
      setSelectedDepartmentForAdd('');
      
      // Show feedback
      setToastMessage('Time entry added successfully');
    } catch (error) {
      console.error('Error adding time entry:', error);
      alert(`Error adding time entry: ${error.message}`);
    }
  };

  const exportToPDF = () => {
    try {
      const doc = new jsPDF();
      
      // Add title
      doc.setFontSize(16);
      doc.text(`Crew Times - ${selectedDate}`, 14, 15);
      
      // Create table data
      const tableColumn = ["Crew Member", "Department", "Call Time", "Wrap", "Hours", "Notes", "Meal Penalty"];
      const tableRows = timeEntries.map(entry => [
        getCrewMemberName(entry.userId),
        getDepartmentName(entry.department),
        formatTime(entry.checkIn),
        entry.checkOut ? formatTime(entry.checkOut) : '—',
        calculateHours(entry.checkIn, entry.checkOut),
        entry.notes || '—',
        entry.mealPenalty ? 'Yes' : 'No'
      ]);
      
      // Generate table
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 25,
        styles: { fontSize: 10, cellPadding: 3 },
        headStyles: { fillColor: [66, 153, 225] }
      });
      
      // Save the PDF
      doc.save(`CrewTimes_${selectedDate}.pdf`);
      
    } catch (error) {
      console.error("Error exporting to PDF:", error);
      alert(`Error exporting to PDF: ${error.message}`);
    }
  };

  const exportToExcel = () => {
    try {
      // Create worksheet data
      const worksheet = XLSX.utils.aoa_to_sheet([
        ["Crew Times - " + selectedDate],
        [""],
        ["Crew Member", "Department", "Call Time", "Wrap", "Hours", "Notes", "Meal Penalty"]
      ]);
      
      // Add data rows
      timeEntries.forEach((entry, index) => {
        XLSX.utils.sheet_add_aoa(worksheet, [[
          getCrewMemberName(entry.userId),
          getDepartmentName(entry.department),
          formatTime(entry.checkIn),
          entry.checkOut ? formatTime(entry.checkOut) : '—',
          calculateHours(entry.checkIn, entry.checkOut),
          entry.notes || '—',
          entry.mealPenalty ? 'Yes' : 'No'
        ]], { origin: { r: 3 + index, c: 0 } });
      });
      
      // Create workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Crew Times");
      
      // Save as excel file
      XLSX.writeFile(workbook, `CrewTimes_${selectedDate}.xlsx`);
      
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      alert(`Error exporting to Excel: ${error.message}`);
    }
  };

  const handleCellClick = (entryId, field, currentValue) => {
    // Don't allow editing of non-editable fields
    if (field === 'userId' || field === 'department') {
      return;
    }
    
    // For time fields that may be in film format, we need to handle this specially
    if (field === 'checkOut') {
      // Here we get the entry so we can get the base date
      const entry = timeEntries.find(e => e.id === entryId);
      
      // Check if this is a wrap time that extends into the next day
      if (entry && entry.checkOut) {
        // Convert to film time for editing if it's a wrap time that crosses midnight
        const checkOutDate = typeof entry.checkOut.toDate === 'function' 
          ? entry.checkOut.toDate() 
          : new Date(entry.checkOut);
        
        const checkInDate = typeof entry.checkIn.toDate === 'function'
          ? entry.checkIn.toDate()
          : new Date(entry.checkIn);
          
        // If checkout is on a different day or earlier in the same day (which means it wrapped to next day)
        if (checkOutDate.getDate() !== checkInDate.getDate() || 
            (checkOutDate.getHours() < checkInDate.getHours() && 
             checkOutDate.getDate() === checkInDate.getDate())) {
          // Use film time format for editing
          const filmTime = formatFilmTime(entry.checkOut, entry.date);
          setModifiedEntries({
            ...modifiedEntries,
            [entryId]: {
              ...modifiedEntries[entryId],
              [field]: filmTime
            }
          });
          return;
        }
      }
    }
    
    // For normal fields, just set the value directly
    setModifiedEntries({
      ...modifiedEntries,
      [entryId]: {
        ...modifiedEntries[entryId],
        [field]: currentValue
      }
    });
  };

  const renderTimeEntryRow = (entry) => {
    const isEditing = editableEntry === entry.id;
    const modifiedData = modifiedEntries[entry.id] || {};
    const crewMemberName = getCrewMemberName(entry.userId);
    const deptName = getDepartmentName(entry.department);
    const hasCheckOut = entry.checkOut !== undefined && entry.checkOut !== null;
    const status = hasCheckOut ? 'Completed' : 'In Progress';
    const rowStyle = status === 'Completed' 
      ? { backgroundColor: '#f2f2f2', borderColor: '#718096' } 
      : { backgroundColor: '#f0f8ff', borderColor: '#4299e1' };
    
    // Get 24-hour military time format for display
    const displayCallTime = formatTime(entry.checkIn);
    
    // For wrap time, convert to film time first, then to military display
    let displayWrapTime = 'N/A';
    if (entry.checkOut) {
      const checkOutDate = typeof entry.checkOut.toDate === 'function' 
        ? entry.checkOut.toDate() 
        : new Date(entry.checkOut);
        
      const hours = checkOutDate.getHours().toString().padStart(2, '0');
      const minutes = checkOutDate.getMinutes().toString().padStart(2, '0');
      
      // Format directly in 24-hour time
      displayWrapTime = `${hours}:${minutes}`;
    }
    
    return (
      <tr key={entry.id} style={{ ...rowStyle, borderBottom: '1px solid', borderColor: rowStyle.borderColor }}>
        <td style={{ padding: '8px' }}>
          {crewMemberName}
        </td>
        <td style={{ padding: '8px' }}>
          {deptName}
        </td>
        <td style={{ padding: '8px' }}>
          {isEditing ? (
            <input
              type="text"
              value={editedValues.checkIn}
              onChange={(e) => handleInputChange('checkIn', e.target.value)}
              placeholder="e.g. 09:30"
              style={{ width: '100%', padding: '4px', borderRadius: '4px', borderColor: '#4299e1' }}
            />
          ) : (
            <input
              type="text"
              defaultValue={displayCallTime}
              onChange={(e) => handleInputChange('checkIn', e.target.value, entry.id)}
              placeholder="e.g. 09:30"
              style={{ width: '100%', padding: '4px', borderRadius: '4px', borderColor: modifiedEntries[entry.id]?.checkIn ? '#68d391' : '#e2e8f0' }}
            />
          )}
        </td>
        <td style={{ padding: '8px' }}>
          {isEditing ? (
            <input
              type="text"
              value={editedValues.checkOut || ''}
              onChange={(e) => handleInputChange('checkOut', e.target.value)}
              placeholder="e.g. 17:30"
              style={{ width: '100%', padding: '4px', borderRadius: '4px', borderColor: '#4299e1' }}
            />
          ) : (
            <input
              type="text"
              defaultValue={hasCheckOut ? displayWrapTime : ''}
              onChange={(e) => handleInputChange('checkOut', e.target.value, entry.id)}
              placeholder="e.g. 17:30"
              style={{ width: '100%', padding: '4px', borderRadius: '4px', borderColor: modifiedEntries[entry.id]?.checkOut ? '#68d391' : '#e2e8f0' }}
            />
          )}
        </td>
        <td style={{ padding: '8px' }}>{calculateHours(entry.checkIn, entry.checkOut)}</td>
        <td style={{ padding: '8px' }}>
          {isEditing ? (
            <input
              type="text"
              value={editedValues.notes || ''}
              onChange={(e) => handleInputChange('notes', e.target.value)}
              placeholder="Add notes"
              style={{ width: '100%', padding: '4px', borderRadius: '4px', borderColor: '#4299e1' }}
            />
          ) : (
            <input
              type="text"
              defaultValue={entry.notes || ''}
              onChange={(e) => handleInputChange('notes', e.target.value, entry.id)}
              placeholder="Add notes"
              style={{ width: '100%', padding: '4px', borderRadius: '4px', borderColor: modifiedEntries[entry.id]?.notes ? '#68d391' : '#e2e8f0' }}
            />
          )}
        </td>
        <td style={{ padding: '8px', textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={isEditing ? editedValues.mealPenalty || false : (modifiedEntries[entry.id]?.mealPenalty !== undefined ? modifiedEntries[entry.id].mealPenalty : entry.mealPenalty || false)}
            onChange={() => toggleMealPenalty(entry.id)}
            style={{ 
              width: '18px', 
              height: '18px',
              accentColor: '#4299e1'
            }}
          />
        </td>
        <td style={{ padding: '8px' }}>
          <span style={{ 
            padding: '4px 8px', 
            borderRadius: '4px', 
            backgroundColor: status === 'Completed' ? '#f2f2f2' : '#f0f8ff',
            color: status === 'Completed' ? '#718096' : '#4299e1',
            border: '1px solid',
            borderColor: status === 'Completed' ? '#718096' : '#4299e1',
            fontSize: '12px',
            fontWeight: '500'
          }}>
            {status}
          </span>
        </td>
      </tr>
    );
  };

  const formatDateForInput = (date) => {
    // Case 1: If date is undefined or null, return empty string
    if (!date) {
      return '';
    }
    
    // Case 2: If date is already a string in YYYY-MM-DD format, use it directly
    if (typeof date === 'string') {
      // If it's already in correct format, return it
      if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return date;
      }
      
      // Try to parse the string to a date object
      try {
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
          return parsedDate.toISOString().split('T')[0];
        }
      } catch (e) {
        console.error('Error parsing date string:', e);
      }
    }
    
    // Case 3: If date is a Date object, format it
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    }
    
    // Default: If all else fails, return today's date
    console.warn('Invalid date format, returning today\'s date');
    return new Date().toISOString().split('T')[0];
  };

  const convertTo24Hour = (timeString) => {
    if (!timeString || timeString === 'N/A' || timeString === 'Invalid Time') {
      return timeString;
    }

    // If already in 24-hour format (e.g., "13:30"), return as is
    if (/^\d{1,2}:\d{2}$/.test(timeString) && !timeString.includes('AM') && !timeString.includes('PM')) {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }

    // If in 12-hour format (e.g., "1:30 PM"), convert to 24-hour
    const match = timeString.match(/(\d+):(\d+)\s*(AM|PM)?/i);
    if (match) {
      let [_, hours, minutes, period] = match;
      hours = parseInt(hours, 10);
      
      if (period && period.toUpperCase() === 'PM' && hours < 12) {
        hours += 12;
      } else if (period && period.toUpperCase() === 'AM' && hours === 12) {
        hours = 0;
      }
      
      return `${hours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }

    return timeString;
  };

  useEffect(() => {
    // Force update all time displays when component mounts
    const forceTimeFormatUpdate = () => {
      const timeInputs = document.querySelectorAll('input[type="text"]');
      timeInputs.forEach(input => {
        if (input.placeholder && input.placeholder.includes('e.g')) {
          // Fix placeholders
          input.placeholder = input.placeholder.replace(/e\.g\. \d+:\d+ [AP]M/i, 'e.g. 17:30');
        }
        
        // Fix values with AM/PM
        if (input.value && (input.value.includes('AM') || input.value.includes('PM'))) {
          const match = input.value.match(/(\d+):(\d+)\s*([AP]M)/i);
          if (match) {
            let [_, hours, minutes, period] = match;
            hours = parseInt(hours, 10);
            
            if (period.toUpperCase() === 'PM' && hours < 12) {
              hours += 12;
            } else if (period.toUpperCase() === 'AM' && hours === 12) {
              hours = 0;
            }
            
            input.value = `${hours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')}`;
          }
        }
      });
    };
    
    // Run immediately
    forceTimeFormatUpdate();
    
    // And set up an interval to keep checking
    const intervalId = setInterval(forceTimeFormatUpdate, 500);
    
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!Array.isArray(timeEntries)) {
      return;
    }

    // Apply 24-hour format to all input fields displaying times
    const timeInputs = document.querySelectorAll('input[type="text"]');
    timeInputs.forEach(input => {
      const value = input.value;
      if (value && (value.includes(':') || value.includes('AM') || value.includes('PM'))) {
        input.value = convertTo24Hour(value);
      }
    });
  }, [timeEntries]);

  useEffect(() => {
    // This will force a re-render when time entries change
    const timer = setTimeout(() => {
      setRenderKey(prev => prev + 1);
    }, 200);
    
    return () => clearTimeout(timer);
  }, [timeEntries]);

  const [renderKey, setRenderKey] = useState(0);

  if (loading) return <div className="loading">Loading crew times...</div>;

  return (
    <div className="ad-page crew-times-page" key={renderKey}>
      <h1>Crew Times</h1>
      
      <div className="access-info" style={{ 
        marginBottom: '20px', 
        padding: '10px', 
        backgroundColor: '#f0f8ff', 
        borderRadius: '5px', 
        borderLeft: '4px solid #4299e1' 
      }}>
        {isAdDepartment ? (
          <p>You have access to all departments' time data.</p>
        ) : (
          <p>You have access to your department's time data only.</p>
        )}
      </div>
      
      <div className="export-buttons" style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
        <button 
          onClick={exportToPDF}
          style={{
            padding: '8px 12px',
            backgroundColor: '#4299e1',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Export to PDF
        </button>
        <button 
          onClick={exportToExcel}
          style={{
            padding: '8px 12px',
            backgroundColor: '#4299e1',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Export to Excel
        </button>
        {Object.keys(modifiedEntries).length > 0 && (
          <button 
            onClick={saveAllChanges}
            style={{
              padding: '8px 12px',
              backgroundColor: '#68d391',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Save All Changes ({Object.keys(modifiedEntries).length})
          </button>
        )}
        <button 
          onClick={addTimeEntry}
          style={{
            padding: '8px 12px',
            backgroundColor: '#4299e1',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Add Time Entry
        </button>
      </div>
      
      <div className="filters-container">
        <div className="filter-group">
          <label>Date:</label>
          <input
            type="date" 
            value={formatDateForInput(selectedDate)}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="date-picker"
            style={{ backgroundColor: '#f0f8ff', borderColor: '#4299e1', padding: '5px', borderRadius: '4px' }}
          />
        </div>
        
        {isAdDepartment && (
          <div className="filter-group">
            <label>Department:</label>
            <select
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              className="department-select"
              style={{ backgroundColor: '#f0f8ff', borderColor: '#4299e1', padding: '5px', borderRadius: '4px' }}
            >
              <option value="all">All Departments</option>
              {departments.map(dept => (
                <option key={dept.id} value={dept.id}>
                  {dept.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="filter-group">
          <label>Department:</label>
          <select
            value={selectedDepartmentForAdd}
            onChange={(e) => setSelectedDepartmentForAdd(e.target.value)}
            className="department-select"
            style={{ backgroundColor: '#f0f8ff', borderColor: '#4299e1', padding: '5px', borderRadius: '4px' }}
          >
            <option value="">Select Department</option>
            {departments.map(dept => (
              <option key={dept.id} value={dept.id}>
                {dept.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <div className="times-table-container">
        {getFilteredTimeEntries().length === 0 ? (
          <div className="no-data" style={{ padding: '20px', backgroundColor: '#f0f8ff', borderRadius: '5px', borderColor: '#4299e1', borderWidth: '1px', borderStyle: 'solid' }}>
            <p>No crew members found for this department. Possible reasons:</p>
            <ul>
              <li>No crew members are assigned to this department</li>
              <li>The selected department may not exist</li>
              <li>There might be an issue with the crew member data</li>
            </ul>
            <p>Try selecting a different department.</p>
          </div>
        ) : (
          <table className="times-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f0f8ff', borderBottom: '2px solid #4299e1' }}>
                <th style={{ padding: '10px', textAlign: 'left' }}>Crew Member</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Department</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Call Time</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Wrap Time</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Hours</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Notes</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Meal Penalty</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {getFilteredTimeEntries().map(entry => renderTimeEntryRow(entry))}
            </tbody>
          </table>
        )}
      </div>
      
      <div className="times-summary" style={{ marginTop: '20px' }}>
        <h3>Daily Summary</h3>
        <div className="summary-stats" style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
          <div className="stat-card" style={{ flex: '1', minWidth: '200px', padding: '15px', backgroundColor: '#f0f8ff', borderRadius: '5px', borderColor: '#4299e1', borderWidth: '1px', borderStyle: 'solid' }}>
            <h4>Total Crew Members</h4>
            <span className="stat-value" style={{ fontSize: '24px', fontWeight: 'bold', color: '#4299e1' }}>
              {new Set(getFilteredTimeEntries().map(e => e.userId)).size}
            </span>
          </div>
          
          <div className="stat-card" style={{ flex: '1', minWidth: '200px', padding: '15px', backgroundColor: '#f0f8ff', borderRadius: '5px', borderColor: '#4299e1', borderWidth: '1px', borderStyle: 'solid' }}>
            <h4>Total Hours</h4>
            <span className="stat-value" style={{ fontSize: '24px', fontWeight: 'bold', color: '#4299e1' }}>
              {getFilteredTimeEntries()
                .reduce((total, entry) => {
                  const hours = calculateHours(entry.checkIn, entry.checkOut);
                  return total + (hours === 'N/A' ? 0 : parseFloat(hours));
                }, 0)
                .toFixed(2)}
            </span>
          </div>
          
          <div className="stat-card" style={{ flex: '1', minWidth: '200px', padding: '15px', backgroundColor: '#f0f8ff', borderRadius: '5px', borderColor: '#4299e1', borderWidth: '1px', borderStyle: 'solid' }}>
            <h4>Average Hours</h4>
            <span className="stat-value" style={{ fontSize: '24px', fontWeight: 'bold', color: '#4299e1' }}>
              {getFilteredTimeEntries().length === 0
                ? '0.00'
                : (
                    getFilteredTimeEntries().reduce((total, entry) => {
                      const hours = calculateHours(entry.checkIn, entry.checkOut);
                      return total + (hours === 'N/A' ? 0 : parseFloat(hours));
                    }, 0) / getFilteredTimeEntries().length
                  ).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
      
      {toastMessage && (
        <div className="toast-message" style={{ 
          position: 'fixed', 
          bottom: '20px', 
          right: '20px', 
          padding: '10px', 
          backgroundColor: '#68d391', 
          color: 'white', 
          borderRadius: '5px', 
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' 
        }}>
          {toastMessage}
        </div>
      )}
    </div>
  );
};

export default CrewTimes;
